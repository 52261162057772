import { FC, MouseEvent } from 'react';
import cn from 'classnames';

interface ISubNavItemProps {
	subPath: string;
	subTitle: string;
	disabled?: boolean;
	breadcrumbPath: string;
	handleSubNavItemClick: (subPath: string) => void;
}

export const SubNavItem: FC<ISubNavItemProps> = ({
	subPath,
	subTitle,
	disabled,
	breadcrumbPath,
	handleSubNavItemClick,
}) => {
	const handleClick = (e: MouseEvent<HTMLLIElement>) => {
		e.stopPropagation();

		if (disabled) return;

		handleSubNavItemClick(subPath);
	};

	const active = breadcrumbPath === subPath;

	return (
		<li
			key={subTitle}
			onClick={handleClick}
			className={cn('subnav-item', {
				active,
				disabled,
			})}
		>
			<span className="subnav-item-title">{subTitle}</span>
		</li>
	);
};
