import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import { FC } from 'react';

import { IInviteUserBody } from 'api/models/requests/account/inviteUserBody';
import { UserRoles } from 'api/models/requests/account/userRoles';

import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

import { getUserRolesSelectOptions } from 'constants/account/selectOptions/getUserRolesSelectOptions';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { currentUserSelector } from 'store/accounts';
import { ISelectOption } from 'types/ui/select';

export const CreateNewUserForm: FC = () => {
	const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
		useFormikContext<IInviteUserBody>();

	const currentUser = useAppSelector(currentUserSelector);

	const handleRoleChange = (option: SingleValue<ISelectOption<UserRoles>>) => {
		if (!option) return;

		void setFieldValue('role', option.value);
	};

	const userRolesSelectOptions = currentUser
		? getUserRolesSelectOptions(currentUser.role, true)
		: [];

	return (
		<div className="modal-body accing-modal">
			<div className="accing-invite-modal">
				<InputGrid
					id="firstName"
					required={false}
					label="First Name"
					placeholder="First Name"
					value={values.firstName}
					error={errors.firstName}
					touched={touched.firstName}
					handleChange={handleChange}
				/>
				<InputGrid
					id="lastName"
					required={false}
					label="Last Name"
					placeholder="Last Name"
					value={values.lastName}
					error={errors.lastName}
					touched={touched.lastName}
					handleChange={handleChange}
				/>
				<div className="accing-modal-input">
					<InputGrid
						id="email"
						label="Email"
						required={false}
						value={values.email}
						error={errors.email}
						touched={touched.email}
						placeholder="Email Address"
						handleChange={handleChange}
					/>
				</div>
				<div className="accing-modal-input">
					<SelectComponent
						label="Role"
						value={values.role}
						error={errors.role}
						touched={touched.role}
						onChange={handleRoleChange}
						selectOptions={userRolesSelectOptions}
					/>
				</div>
			</div>
			<Button
				value="Send Invite"
				onClick={() => handleSubmit()}
				className="btn-primary btn-accing-invite"
			/>
		</div>
	);
};
