import { SingleValue } from 'react-select';
import { Form, useFormikContext } from 'formik';
import { FC } from 'react';

import { priceListFulfillmentSelectOptions } from 'constants/priceLists/selectOptions/priceListFulfillmentSelectOptions';
import { PriceListFulfillment } from 'constants/priceLists/priceListFulfillment';

import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

import { ISelectOption } from 'types/ui/select';

import { ICreatePriceListProps, ICreatePriceListValues } from '..';

export const NewPriceListForm: FC<Partial<ICreatePriceListProps>> = ({
	isCopy,
}) => {
	const { values, errors, touched, handleChange, setFieldValue } =
		useFormikContext<ICreatePriceListValues>();

	const handleChangeFulfillment = (
		option: SingleValue<ISelectOption<PriceListFulfillment>>
	) => {
		if (!option) return;

		void setFieldValue('fulfillment', option.value);
	};

	const priceListNameLabel = isCopy ? 'New Price List Name' : 'Price List Name';

	return (
		<Form>
			<div className="price-modal price-modal-large">
				<InputGrid
					id="name"
					className="price"
					error={errors.name}
					value={values.name}
					touched={touched.name}
					label={priceListNameLabel}
					handleChange={handleChange}
					placeholder="Price List Name"
				/>
				<div className="input-form-price">
					<SelectComponent
						required
						id="fulfillment"
						disabled={isCopy}
						className="label-grid"
						label="Order Fulfillment"
						value={values.fulfillment}
						error={errors.fulfillment}
						touched={touched.fulfillment}
						onChange={handleChangeFulfillment}
						selectPlaceholder="Select Order Fulfillment"
						selectOptions={priceListFulfillmentSelectOptions}
					/>
				</div>
			</div>
			<Button
				type="submit"
				value="Create New Price List"
				className="org-modal-btn btn-primary"
			/>
		</Form>
	);
};
