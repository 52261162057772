import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';
import { FC, ReactNode } from 'react';
import { utc } from 'moment';

import { ToggleSection } from 'components/ToggleSection';
import { Table } from 'components/Table';

import { useToggleSection } from 'hooks/useToggleSection';
import {
	ICampaignStatistic,
	IMarketingBroadcast,
} from 'api/models/responses/projects/marketing';
import { ROUTES } from 'constants/ROUTES';

interface ICampaignProps
	extends Pick<ICampaignStatistic, 'broadcasts' | 'campaignTarget'> {
	header: ReactNode;
}

const sentWhenTriggered = 'Sent When Triggered';

export const Campaign: FC<ICampaignProps> = ({
	header,
	broadcasts,
	campaignTarget,
}) => {
	const { isOpen, handleToggle } = useToggleSection(false);

	const columns: Column<IMarketingBroadcast>[] = [
		{
			Header: 'Broadcast',
			accessor: 'broadcast',
			Cell: ({ row }: CellProps<IMarketingBroadcast>) => {
				const { broadcast, broadcastKey, date } = row.original;

				const path = `${ROUTES.CAMPAIGNS.BROADCASTS}/${broadcastKey}`;
				const disabledColumn = !date;

				return (
					<Link to={path} title={broadcast} className="card-table-link">
						{broadcast}
					</Link>
				);
			},
		},
		{
			Header: 'Triggered',
			accessor: 'triggered',
			Cell: ({ row }: CellProps<IMarketingBroadcast>) => {
				const { date, triggered } = row.original;

				const disabledColumn = !date;

				return <>{triggered}</>;
			},
		},
		{
			Header: 'Date',
			accessor: 'date',
			Cell: ({ row }: CellProps<IMarketingBroadcast>) => {
				const { date } = row.original;

				const disabledColumn = !date;

				if (disabledColumn) {
					return <>{sentWhenTriggered}</>;
				}

				const parsedDate = utc(date).format('MMM DD, YYYY');

				return <>{parsedDate}</>;
			},
		},
		{
			Header: 'Start Sending After',
			accessor: 'startSendingAfter',
			Cell: ({ row }: CellProps<IMarketingBroadcast>) => {
				const { date, startSendingAfter } = row.original;

				const disabledColumn = !date;

				return <>{startSendingAfter}</>;
			},
		},
		{
			id: 'status',
			Header: 'Status',
			Cell: ({ row }: CellProps<IMarketingBroadcast>) => {
				const { date, sent, scheduled } = row.original;

				const disabledColumn = !date;

				const sentString = sent ? `, ${sent} sent` : '';

				return <>{`${scheduled} scheduled${sentString}`}</>;
			},
		},
	];

	const sectionTitle = `${campaignTarget} Campaign`;

	return (
		<ToggleSection
			isOpen={isOpen}
			header={header}
			title={sectionTitle}
			handleToggle={handleToggle}
			className="marketing-section"
		>
			<div className="marketing-table">
				<Table
					columns={columns}
					data={broadcasts}
					className="org-table project-details-table"
				/>
			</div>
		</ToggleSection>
	);
};
