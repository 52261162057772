import { object, Schema, string } from 'yup';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';

import { IChangeEmailBody } from 'api/models/requests/account/changeEmailBody';

export const changeEmailValidationSchema = object().shape<
	Partial<Record<keyof IChangeEmailBody, Schema>>
>({
	verificationCode: string().required(requiredFieldMessage),
});
