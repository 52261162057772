import { PersistGate } from 'redux-persist/integration/react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { Notification } from 'components/Notification';
import { oidcConfig } from 'config/authConfig';
import { store, persistor } from 'store';

import { App } from './App';

import './styles/main.scss';
import { AuthProvider } from 'react-oidc-context';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<Provider store={store}>
		<PersistGate loading={false} persistor={persistor}>
			<AuthProvider {...oidcConfig}>
				<App />
				<Notification />
			</AuthProvider>
		</PersistGate>
	</Provider>
);
