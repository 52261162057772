import { useFormikContext } from 'formik';
import { FC } from 'react';

import { TextEditorRef } from 'components/TextEditor/types';
import { Button } from 'components/FormControls/Button';

import {
	IGeneralBroadcastProps,
	ISendBroadcastProps,
	ISendBroadcastValues,
} from '../../types';

interface IBroadcastControlsProps
	extends Omit<
			ISendBroadcastProps,
			| 'setSentToNumberOfPeople'
			| 'showSpecialBroadcastRejectModal'
			| 'showSuccessSentSpecialBroadcastModal'
		>,
		IGeneralBroadcastProps {
	editorRef: TextEditorRef;
}

export const BroadcastControls: FC<IBroadcastControlsProps> = ({
	editorRef,
	broadcastInfo,
	hideSendBroadcastModal,
}) => {
	const { values, setFieldValue, handleSubmit } =
		useFormikContext<ISendBroadcastValues>();

	const handleSubmitForm = async () => {
		const emailMessage = editorRef.current?.editor?.data.get() || '';

		await setFieldValue('emailMessage', emailMessage);

		handleSubmit();
	};

	const disableSendSpecialBroadcast =
		!values.sendByEmail &&
		!values.sendByPhone &&
		!broadcastInfo?.hasAnyBuyers &&
		!broadcastInfo?.hasAnyLeads &&
		!broadcastInfo?.hasAnyProspects;

	return (
		<div className="broadcast-btns">
			<Button
				value="Cancel"
				className="btn-secondary"
				onClick={hideSendBroadcastModal}
			/>
			<Button
				className="btn-primary"
				value="Send Special Broadcast"
				disabled={disableSendSpecialBroadcast}
				onClick={() => void handleSubmitForm()}
			/>
		</div>
	);
};
