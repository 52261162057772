import { generalStatisticKeys } from 'constants/general/statistic/generalStatisticKeys';
import { IStatisticRenderHelper } from 'types/statistic/statisticRenderHelper';

export const studioStatisticKeys: IStatisticRenderHelper[] = [
	{
		key: 'organizations',
		title: 'Active Organizations',
	},
	{
		key: 'projects',
		title: 'Active Projects',
	},
	...generalStatisticKeys(),
];
