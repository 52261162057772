import { HTTPS } from 'constants/general/secureProtocol';
import { studioSelector } from 'store/studio';

import { useAppSelector } from './redux/useAppSelector';

export const usePreviewUrl = (url?: string) => {
	const studio = useAppSelector(studioSelector);

	const consumerUrl = process.env.REACT_APP_CONSUMER_URL;

	if (!studio || !consumerUrl || !url) return '';

	return `${HTTPS}${studio.networkDomain}.${consumerUrl}/${url}`;
};
