import { IStatisticRenderHelper } from 'types/statistic/statisticRenderHelper';

export const generalStatisticKeys = (
	subjects?: string
): IStatisticRenderHelper[] => [
	{
		key: 'people',
		title: subjects || 'People',
	},
	{
		key: 'images',
		title: 'Images',
	},
	{
		key: 'orders',
		title: 'Orders',
	},
];
