import { FC, useRef, useState } from 'react';

import { galleryImageActionMenuList } from 'constants/imageGallery/galleryImageActionMenuList';
import { GalleryImageActionMenu } from 'constants/imageGallery/galleryImageActionMenu';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToastify } from 'hooks/useToastify';

import { IGalleryImage } from 'api/models/responses/imageGallery';
import { CellActionMenu } from 'components/Table/CellActionMenu';
import { getImageBlobUrl } from 'utils/images/getImageBlobUrl';
import { deleteGalleryImageAsync } from 'store/imageGallery';

export const MediaItem: FC<IGalleryImage> = ({
	imageFileName,
	galleryImageKey,
	imageRelativePath,
	imagePreviewRelativePath,
}) => {
	const [showActionMenu, setShowActionMenu] = useState(false);

	const downloadLinkRef = useRef<HTMLAnchorElement>(null);

	const dispatch = useAppDispatch();

	const { showSuccessToastify } = useToastify();

	const setActiveActionMenu = () => setShowActionMenu(true);
	const handleMouseLeave = () => setShowActionMenu(false);

	const imagePreviewUrl = getImageBlobUrl(imagePreviewRelativePath);
	const imageUrl = getImageBlobUrl(imageRelativePath);

	const handleMenuItemClick = (menuItem: GalleryImageActionMenu) => {
		const absoluteImageUrl = `${window.location.origin}${imageUrl}`;

		switch (menuItem) {
			case GalleryImageActionMenu.DownloadFile: {
				const downloadLink = downloadLinkRef.current;

				if (!downloadLink) return;

				downloadLink.href = imageUrl;
				downloadLink.download = imageFileName;
				downloadLink.click();

				setShowActionMenu(false);
				break;
			}

			case GalleryImageActionMenu.DeletePermanently:
				void dispatch(deleteGalleryImageAsync(galleryImageKey));
				break;

			case GalleryImageActionMenu.CopyUrlToClipboard:
				void navigator.clipboard.writeText(absoluteImageUrl);

				showSuccessToastify({ title: 'Image URL copied' });

				setShowActionMenu(false);
				break;

			default:
				break;
		}
	};

	return (
		<div className="media-item" onMouseLeave={handleMouseLeave}>
			<CellActionMenu
				className="media-item-btn"
				showActionMenu={showActionMenu}
				menuList={galleryImageActionMenuList}
				handleMenuItemClick={handleMenuItemClick}
				setActiveActionMenu={setActiveActionMenu}
			/>
			<img alt="media-image" className="media-img" src={imagePreviewUrl} />
			<span className="media-img-name" title={imageFileName}>
				{imageFileName}
			</span>
			<a ref={downloadLinkRef} />
		</div>
	);
};
