import { FC } from 'react';

import { useCreatePriceListOptionBtn } from 'pages/PriceLists/hooks/useCreatePriceListOptionBtn';
import { PriceListControls } from 'pages/PriceLists/components/PriceListControls';
import { PriceTable } from 'pages/PriceLists/components/PriceTable';

import { ImagoPriceListTabs } from 'constants/priceLists/tabs/imagoPriceListTabs';
import { imagoTableHeaders } from 'constants/priceLists/imago/imagoTableHeaders';

import { Button } from 'components/FormControls/Button';
import { NoItemsFound } from 'components/NoItemsFound';
import { Loader } from 'components/Loader';

import { CatalogProductCategoryTypes } from 'api/models/responses/catalogProducts/catalogProducts';

import { useImagoProducts } from '../../hooks/useImagoProducts';
import { Product } from '../../components/Product';
import { IPriceListOptionsProps } from '../..';

const headers = imagoTableHeaders();

export const PriceListPrints: FC<IPriceListOptionsProps> = ({
	priceListKey,
	setActiveTab,
	isPriceListPending,
}) => {
	const {
		productsUI,
		updateProduct,
		deleteProduct,
		lastProductUI,
		previewImageFile,
		handleAddProduct,
		isCatalogPending,
		isProductsPending,
		handleSaveProduct,
		clearProductError,
		uploadProductImage,
		deleteProductImage,
		updateCatalogProduct,
		changeProductSequence,
		catalogProductsSelectOptions,
	} = useImagoProducts({
		priceListKey,
		catalogProductCategoryType: CatalogProductCategoryTypes.Prints,
	});

	const handleBack = () => {
		setActiveTab(ImagoPriceListTabs.Packages);
	};

	const handleNext = () => {
		setActiveTab(ImagoPriceListTabs.GiftAccessories);
	};

	const PackagesList = productsUI.map((productUI) => (
		<Product
			productUI={productUI}
			updateProduct={updateProduct}
			deleteProduct={deleteProduct}
			previewImageFile={previewImageFile}
			clearProductError={clearProductError}
			uploadProductImage={uploadProductImage}
			deleteProductImage={deleteProductImage}
			productSelectPlaceholder="Select Product"
			updateCatalogProduct={updateCatalogProduct}
			changeProductSequence={changeProductSequence}
			catalogProductsSelectOptions={catalogProductsSelectOptions}
			key={productUI.priceListImagoFulfilledProductKey || productUI.sequence}
		/>
	));

	const showLoader =
		isCatalogPending || isProductsPending || isPriceListPending;

	const showSaveBtn =
		!!productsUI.length && !lastProductUI?.priceListImagoFulfilledProductKey;

	const { btnValue, btnClassName, btnClickHandler } =
		useCreatePriceListOptionBtn({
			showSaveBtn,
			optionName: 'Product',
			handleAdd: handleAddProduct,
			handleSave: handleSaveProduct,
		});

	return (
		<>
			<span className="price-package-title">Prints</span>
			<div className="price-container price-imago-container">
				{showLoader ? (
					<Loader />
				) : (
					<>
						{productsUI.length ? (
							<PriceTable className="price-imago-package" headers={headers}>
								{PackagesList}
							</PriceTable>
						) : (
							<NoItemsFound title="prints" />
						)}
						<Button
							value={btnValue}
							onClick={btnClickHandler}
							className={`price-add-back ${btnClassName}`}
						/>
					</>
				)}
			</div>
			<PriceListControls handleBack={handleBack} handleNext={handleNext} />
		</>
	);
};
