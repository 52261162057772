import { useAuth } from 'react-oidc-context';
import { useCallback } from 'react';

import { setSubscription } from 'store/subscriptions';
import { setStudio } from 'store/studio';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { broadcastChannel } from 'App';

export type Logout = () => Promise<void>;

export const useLogout = (): Logout => {
	const dispatch = useAppDispatch();
	const authContext = useAuth();

	const logout = useCallback(async () => {
		try {
			dispatch(setStudio(null));
			dispatch(setSubscription(null));

			broadcastChannel.postMessage('oidc_logged_out');

			await authContext.signoutRedirect();
		} catch (error) {
			console.log(error);
		}
	}, [authContext]);

	return logout;
};
