import { useFormikContext } from 'formik';
import { FC } from 'react';

import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

import { Domain } from 'pages/RegistrationSteps/CreateStudio/components/StudioInformation/Domain';

import { IUpdateStudioProps, UpdateStudioValues } from '..';

export const UpdateStudioForm: FC<
	Pick<IUpdateStudioProps, 'hideUpdateStudioModal'>
> = ({ hideUpdateStudioModal }) => {
	const { values, errors, touched, handleChange, handleSubmit } =
		useFormikContext<UpdateStudioValues>();

	return (
		<div className="modal-body acc-user-modal">
			<div className="acc-user-modal-container">
				<InputGrid
					id="studioName"
					required={false}
					label="Studio Name"
					placeholder="Studio Name"
					value={values.studioName}
					error={errors.studioName}
					handleChange={handleChange}
					touched={touched.studioName}
				/>
				<Domain
					handleChange={handleChange}
					value={values.networkDomain}
					error={errors.networkDomain}
					touched={touched.networkDomain}
				/>
			</div>
			<div className="btn-container-decrease acc-user-btns">
				<Button
					value="Update Studio"
					className="btn-primary"
					onClick={() => handleSubmit()}
				/>
				<Button
					value="Back"
					className="btn-secondary"
					onClick={hideUpdateStudioModal}
				/>
			</div>
		</div>
	);
};
