import { useEffect } from 'react';

import { MARKET_SELL } from 'constants/navigation';
import { ROUTES } from 'constants/ROUTES';

import { IBreadcrumbs, setBreadcrumbs } from 'store/breadcrumbs';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import NavigationService from 'utils/NavigationService';

export const useCampaignsBreadcrumbs = (
	campaignDetailsBreadcrumb?: IBreadcrumbs | null,
	broadcastDetailsBreadcrumbs?: IBreadcrumbs | null
) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		const marketSellNavigation =
			NavigationService.getParentNavigation(MARKET_SELL);

		const campaignsNavigation = NavigationService.getSubNavigation(
			MARKET_SELL,
			ROUTES.CAMPAIGNS.CAMPAIGNS
		);

		if (!marketSellNavigation || !campaignsNavigation) return;

		const marketSellBreadcrumbs: IBreadcrumbs = {
			isActive: false,
			path: marketSellNavigation.path,
			title: marketSellNavigation.title,
		};

		const campaignsBreadcrumbs: IBreadcrumbs = {
			isActive: !campaignDetailsBreadcrumb,
			path: campaignsNavigation.path,
			title: campaignsNavigation.title,
		};

		const breadcrumbs: IBreadcrumbs[] = [
			marketSellBreadcrumbs,
			campaignsBreadcrumbs,
		];

		if (campaignDetailsBreadcrumb) {
			breadcrumbs.push(campaignDetailsBreadcrumb);
		}

		if (broadcastDetailsBreadcrumbs) {
			breadcrumbs.push(broadcastDetailsBreadcrumbs);
		}

		dispatch(setBreadcrumbs(breadcrumbs));
	}, [
		campaignDetailsBreadcrumb?.path,
		campaignDetailsBreadcrumb?.title,
		broadcastDetailsBreadcrumbs?.path,
		broadcastDetailsBreadcrumbs?.title,
	]);
};
