import { useFormikContext } from 'formik';
import { FC } from 'react';

import { ICopyCampaignBody } from 'api/models/requests/campaigns';
import { Button } from 'components/FormControls/Button';

interface ICampaignCopyControlsProps {
	hideCampaignCopyModal: () => void;
}

export const CampaignCopyControls: FC<ICampaignCopyControlsProps> = ({
	hideCampaignCopyModal,
}) => {
	const { handleSubmit } = useFormikContext<ICopyCampaignBody>();

	return (
		<div className="campaign-modal-btns">
			<Button
				value="Copy Campaign"
				className="btn-secondary"
				onClick={() => handleSubmit()}
			/>
			<Button
				value="Cancel"
				className="btn-primary"
				onClick={hideCampaignCopyModal}
			/>
		</div>
	);
};
