import { FC } from 'react';

import { createPhoneNumberAsync, studioMarketingSelector } from 'store/studio';
import { subscriptionSelector } from 'store/subscriptions';

import { UpgradePlan } from 'components/Modal/components/UpgradePlan';
import { InputGrid } from 'components/FormControls/InputGrid';
import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { Modal } from 'components/Modal';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useToggleSection } from 'hooks/useToggleSection';

import { useModalControls } from 'hooks/useModalControls';
import {
	upgradePlanTitle,
	upgradePlanSubtitle,
} from 'constants/subscriptions/upgradePlan';

export const TextMessageSettings: FC = () => {
	const studioMarketing = useAppSelector(studioMarketingSelector);
	const subscription = useAppSelector(subscriptionSelector);

	const dispatch = useAppDispatch();

	const { isOpen, handleToggle } = useToggleSection(true);

	const {
		modalRef: upgradePlanModalRef,
		showModal: showUpgradePlanModal,
		hideModal: hideUpgradePlanModal,
	} = useModalControls();

	const isPhoneNumberVerified = !!studioMarketing?.isPhoneNumberVerified;
	const isPhoneExist = !!studioMarketing?.twillioPhoneNumber;
	const freeSmsMarketing = !!subscription?.freeSmsMarketing;

	const showPhoneNumber = isPhoneNumberVerified && isPhoneExist;
	const showSettingUpMessage = !isPhoneNumberVerified && isPhoneExist;

	const handleCreatePhoneNumber = () => {
		if (!freeSmsMarketing) {
			return showUpgradePlanModal();
		}

		void dispatch(createPhoneNumberAsync());
	};

	return (
		<>
			<ToggleSection
				isOpen={isOpen}
				title="Text Message Settings"
				handleToggle={handleToggle}
				className="acc-user-header"
			>
				<div className="marketing-wrapper">
					{showPhoneNumber && (
						<p className="marketing-text">
							Your customers will receive your sms text marketing from this
							number. They can reply &#39;Stop&#39; at any time to opt out.
						</p>
					)}
					{!isPhoneExist && (
						<p className="marketing-text">
							Click the button below to get a phone number and activate text
							marketing for your account.
						</p>
					)}
					{showSettingUpMessage && (
						<p className="marketing-text">
							Setting up text message marketing for your account
						</p>
					)}
					{!isPhoneExist && (
						<Button
							onClick={handleCreatePhoneNumber}
							className="btn-primary marketing-btn"
							value="Activate Text Message Marketing"
						/>
					)}
					{showPhoneNumber && (
						<div className="marketing-form">
							<InputGrid
								disabled
								required={false}
								label="Phone Number:"
								placeholder={studioMarketing?.twillioPhoneNumber || ''}
							/>
						</div>
					)}
				</div>
			</ToggleSection>
			<Modal
				title={upgradePlanTitle}
				ref={upgradePlanModalRef}
				subtitle={upgradePlanSubtitle}
			>
				<UpgradePlan hideModal={hideUpgradePlanModal} />
			</Modal>
		</>
	);
};
