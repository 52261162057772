import { Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { useNavigate } from 'react-router-dom';
import { FC, useEffect, useRef } from 'react';

import { BillingEntityTypes } from 'api/models/responses/studioBilling/billingEntityTypes';
import { IBillingInfoBody } from 'api/models/requests/studioBilling/billingInfo';
import { IBankAccountBody } from 'api/models/requests/studioBilling/bankAccount';
import StudioBillingService from 'api/services/StudioBillingService';
import StudioService from 'api/services/StudioService';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';

import { useRegistrationStepsStatus } from 'hooks/auth/useRegistrationStepsStatus';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { setStudio, studioSelector } from 'store/studio';
import {
	setBankACH,
	setStudioBilling,
	setIsBankACHFulfilled,
	isBankACHFulfilledSelector,
	setIsBillingInfoFulfilled,
} from 'store/studioBilling';

import { ROUTES } from 'constants/ROUTES';
import {
	ITinField,
	IPartialEinField,
	IPartialSsnField,
	IPartialPhoneField,
} from 'components/W9InfoForm/types';

import { getPaidFormValidationScheme } from './validations';
import { SideWrapper } from '../components/SideWrapper';
import { SetupAccountForm } from './SetupAccountForm';
import { IGetPaidFormValues } from './types';

export const SetupAccount: FC = () => {
	const studio = useAppSelector(studioSelector);

	const isBankACHFulfilled = useAppSelector(isBankACHFulfilledSelector);

	const formRef = useRef<FormikProps<IGetPaidFormValues> | null>(null);

	const { isSetupAccountCompleted } = useRegistrationStepsStatus();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const billingPhoneInitial: IPartialPhoneField = {
		firstPartPhone: '',
		secondPartPhone: '',
		thirdPartPhone: '',
	};

	const ssnInitial: IPartialSsnField = {
		firstPartSsn: '',
		secondPartSsn: '',
		thirdPartSsn: '',
	};

	const einInitial: IPartialEinField = {
		firstPartEin: '',
		secondPartEin: '',
	};

	const tinInitial: ITinField = {
		ssn: ssnInitial,
		ein: einInitial,
	};

	const getPaidFormInitial: IGetPaidFormValues = {
		bankAccountName: '',
		bankAccountNumber: '',
		confirmBankAccountNumber: '',
		bankRoutingNumber: '',
		confirmBankRoutingNumber: '',
		billingFirstName: '',
		billingLastName: '',
		billingBusinessName: '',
		billingStreet: '',
		billingState: '',
		billingPostalCode: '',
		billingPhone: billingPhoneInitial,
		billingFax: '',
		billingEmail: '',
		billingPhoneExt: '',
		billingEntityType: BillingEntityTypes.IndividualSoleProprietor,
		tin: tinInitial,
		billingCity: '',
		billingW9Initials: '',
	};

	const submitBankACH = async (
		bankACHBody: IBankAccountBody,
		setErrors: FormikHelpers<IGetPaidFormValues>['setErrors']
	): Promise<boolean> => {
		try {
			const data = await StudioBillingService.createBankACHInformation(
				bankACHBody
			);

			dispatch(setBankACH(data));
			setIsBankACHFulfilled(true);
			setErrors({});

			return true;
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			if (type === ErrorTypes.ValidationError) {
				const studioBankAccountAlreadyExist = errors.studioBankAccount;

				if (studioBankAccountAlreadyExist) {
					dispatch(setIsBankACHFulfilled(true));
					return true;
				}

				const updatedErrors = Object.entries(errors).reduce<
					FormikErrors<IGetPaidFormValues>
				>(
					(acc, [key, messages]) => ({
						...acc,
						[key]: messages[0],
					}),
					{}
				);

				setErrors(updatedErrors);
				return false;
			}

			return false;
		}
	};

	const submitW9Info = async (
		w9BillingBody: IBillingInfoBody,
		setErrors: FormikHelpers<IGetPaidFormValues>['setErrors']
	): Promise<boolean> => {
		try {
			const data = await StudioBillingService.updateBillingInfo(w9BillingBody);

			dispatch(setStudioBilling(data));
			return true;
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const form = formRef.current;

			if (!form) return false;

			const updatedErrorMessages = Object.entries(errors).reduce<
				FormikErrors<IGetPaidFormValues>
			>((acc, [key, messages]) => {
				const splittedKey = key.split('.');

				const parsedKey = splittedKey[splittedKey.length - 1];

				const parsedMessage = messages[0] || '';

				if (parsedKey === 'billingPhone') {
					acc.billingPhone = {};
					acc.billingPhone.firstPartPhone = parsedMessage;

					return acc;
				}

				if (parsedKey === 'ssn') {
					acc.tin = {};
					acc.tin.ssn = {};
					acc.tin.ssn.firstPartSsn = parsedMessage;

					return acc;
				}

				if (parsedKey === 'ein') {
					acc.tin = {};
					acc.tin.ssn = {};
					acc.tin.ssn.firstPartSsn = parsedMessage;

					return acc;
				}

				return {
					...acc,
					[parsedKey]: parsedMessage,
				};
			}, {});

			const formErrors = form.errors;

			setErrors({
				...getPaidFormInitial,
				bankAccountName: formErrors.bankAccountName,
				bankAccountNumber: formErrors.bankAccountNumber,
				bankRoutingNumber: formErrors.bankRoutingNumber,
				...updatedErrorMessages,
			} as FormikErrors<IGetPaidFormValues>);

			return false;
		}
	};

	const onSubmit = async (
		values: IGetPaidFormValues,
		{ setErrors }: FormikHelpers<IGetPaidFormValues>
	) => {
		const {
			tin,
			billingPhone,
			bankAccountName,
			bankRoutingNumber,
			bankAccountNumber,
			confirmBankAccountNumber,
			confirmBankRoutingNumber,
			...w9BillingRest
		} = values;

		const { firstPartPhone, secondPartPhone, thirdPartPhone } = billingPhone;
		const { ssn, ein } = tin;

		const fullBillingPhone = `${firstPartPhone}${secondPartPhone}${thirdPartPhone}`;
		const fullSsn = `${ssn.firstPartSsn}${ssn.secondPartSsn}${ssn.thirdPartSsn}`;
		const fullEin = `${ein.firstPartEin}${ein.secondPartEin}`;

		const bankACHBody: IBankAccountBody = {
			bankAccountName,
			bankAccountNumber,
			bankRoutingNumber,
		};

		const w9BillingBody: IBillingInfoBody = {
			...w9BillingRest,
			ssn: fullSsn,
			ein: fullEin,
			billingPhone: fullBillingPhone,
		};

		if (!isBankACHFulfilled) {
			const successSubmitBankACH = await submitBankACH(bankACHBody, setErrors);

			if (!successSubmitBankACH) return;
		}

		const successSubmitW9Info = await submitW9Info(w9BillingBody, setErrors);

		if (!successSubmitW9Info) return;

		const data = await StudioService.getStudio();

		if (!studio) return;

		dispatch(setStudio(data));
	};

	useEffect(() => {
		if (!isSetupAccountCompleted) return;

		navigate(ROUTES.DASHBOARD);

		setTimeout(() => {
			dispatch(setIsBillingInfoFulfilled(true));
		}, 0);
	}, [isSetupAccountCompleted]);

	return (
		<div className="paid-container">
			<div className="panel">
				<Formik
					innerRef={formRef}
					onSubmit={onSubmit}
					initialValues={getPaidFormInitial}
					validationSchema={getPaidFormValidationScheme}
				>
					<SetupAccountForm />
				</Formik>
			</div>
			<SideWrapper />
		</div>
	);
};
