import { FC, SyntheticEvent, useState } from 'react';

import { IPatchBody } from 'api/models/requests/general/patchBody';
import AccountService from 'api/services/AccountService';

import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import {
	userLastNameMaxLength,
	userFirstNameMaxLength,
	userLastNameMaxLengthMessage,
	userFirstNameMaxLengthMessage,
} from 'constants/account/validation/userFullName';

import {
	updateUser,
	setCurrentUser,
	currentUserSelector,
} from 'store/accounts';

interface IUserInfoProps {
	showSendVerificationCodeModal: () => void;
}

export const UserInfo: FC<IUserInfoProps> = ({
	showSendVerificationCodeModal,
}) => {
	const [firstNameError, setFirstNameError] = useState('');
	const [lastNameError, setLastNameError] = useState('');

	const currentUser = useAppSelector(currentUserSelector);

	const dispatch = useAppDispatch();

	const updateUserAsync = async (body: IPatchBody[]) => {
		if (!currentUser) return;

		try {
			const data = await AccountService.updateUser(currentUser.id, body);

			dispatch(setCurrentUser(data));
			dispatch(updateUser(data));
		} catch (error) {
			console.log(error);
		}
	};

	const updateFirstName = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		if (value.length > userFirstNameMaxLength) {
			return setFirstNameError(userFirstNameMaxLengthMessage);
		}

		const updatedField: IPatchBody = {
			value,
			op: 'replace',
			path: 'firstName',
		};

		const body: IPatchBody[] = [updatedField];

		void updateUserAsync(body);
	};

	const clearFirstNameError = () => {
		if (!firstNameError) return;

		setFirstNameError('');
	};

	const updateLastName = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		if (value.length > userLastNameMaxLength) {
			return setLastNameError(userLastNameMaxLengthMessage);
		}

		const updatedField: IPatchBody = {
			value,
			op: 'replace',
			path: 'lastName',
		};

		const body: IPatchBody[] = [updatedField];

		void updateUserAsync(body);
	};

	const clearLastNameError = () => {
		if (!lastNameError) return;

		setLastNameError('');
	};

	return (
		<div className="acc-image-fields">
			<div className="acc-user-form acc-user-fields">
				<InputGrid
					isLazy
					touched
					required={false}
					label="First Name"
					error={firstNameError}
					placeholder="First Name"
					clearError={clearFirstNameError}
					handleLazyChange={updateFirstName}
					defaultValue={currentUser?.firstName}
				/>
				<InputGrid
					isLazy
					touched
					required={false}
					label="Last Name"
					error={lastNameError}
					placeholder="Last Name"
					clearError={clearLastNameError}
					handleLazyChange={updateLastName}
					defaultValue={currentUser?.lastName}
				/>
			</div>
			<ul className="acc-user-list">
				<li className="acc-user-item">
					<b>Role:</b>
					{currentUser?.role}
				</li>
				<li className="acc-user-item">
					<b>Email Address:</b>
					{currentUser?.email}
				</li>
				<li className="acc-user-item acc-item-btn">
					<Button
						className="btn-secondary"
						value="Update Email Address"
						onClick={showSendVerificationCodeModal}
					/>
				</li>
			</ul>
		</div>
	);
};
