import { FC, useCallback, useEffect, useState } from 'react';

import { initialPriceListFormQuestionErrors } from 'constants/priceLists/studio/priceListPackages/initialPackageFormQuestionErrors';
import { initialAlaCarteErrors } from 'constants/priceLists/studio/priceListAlaCarte/initialAlaCarteErrors';
import { PoseAvailableFor } from 'constants/priceLists/poseAvailableFor/poseAvailableFor';
import { StudioPriceListTabs } from 'constants/priceLists/tabs/studioPriceListTabs';

import { ICreateAlaCarteBody } from 'api/models/requests/priceLists/studio/priceListAlaCarte/createAlaCarteBody';
import { IGeneralFormQuestion } from 'api/models/responses/general/generalFormQuestion';
import AlaCarteService from 'api/services/PriceListsService/studio/AlaCarteService';
import { IErrorResponse } from 'api/models/responses/general/errorResponse';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { IPriceListFormQuestionUI } from 'pages/PriceLists/types/studio/priceListFormQuestionUI';
import { useCreatePriceListOptionBtn } from 'pages/PriceLists/hooks/useCreatePriceListOptionBtn';
import { IClearPackageErrors } from 'pages/PriceLists/types/studio/priceListPackageUI';
import { PriceListControls } from 'pages/PriceLists/components/PriceListControls';
import {
	IAlaCarteUI,
	IDeleteAlaCarte,
	IUpdateAlaCarte,
	IUploadAlaCarteImage,
} from 'pages/PriceLists/types/studio/priceListAlaCarteUI';
import {
	PriceTable,
	IHeaderConfig,
} from 'pages/PriceLists/components/PriceTable';

import { initialStudioIsBackgroundRequiredSelector } from 'store/priceLists/priceListBackgrounds';
import { initialRequiredPoseOptionsSelector } from 'store/priceLists/studio/priceListPoseOptions';

import { validateReferenceCode } from 'utils/validations/priceLists/validateReferenceCode';
import { validateRetailValue } from 'utils/validations/priceLists/validateRetailValue';
import { validateOptionName } from 'utils/validations/priceLists/validateOptionName';
import { changeEntitiesSequence } from 'utils/dragAndDrop/changeEntitiesSequence';

import { Button } from 'components/FormControls/Button';
import { NoItemsFound } from 'components/NoItemsFound';
import { Loader } from 'components/Loader';

import { IChangeSequencePayload } from 'hooks/useDragAndDrop';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { PriceListAlaCarte } from './PriceListAlaCarte';
import { validateDescription } from 'utils/validations/general/validateDescription';

const alaCarteTableHeaders: IHeaderConfig[] = [
	{ name: 'Name', required: true, left: true },
	{ name: 'Retail Value', required: true, left: true },
	{ name: 'Package Code', required: true, left: true },
	{
		name: 'Available for',
		left: true,
	},
];

interface IPriceListAlaCartesProps {
	priceListKey?: number;
	isPriceListPending: boolean;
	setActiveTab: (tab: StudioPriceListTabs) => void;
}

export const PriceListAlaCartes: FC<IPriceListAlaCartesProps> = ({
	priceListKey,
	setActiveTab,
	isPriceListPending,
}) => {
	const [previewImageFile, setPreviewImageFile] = useState<File | null>(null);
	const [alaCartesUI, setAlaCartesUI] = useState<IAlaCarteUI[]>([]);
	const [isReorder, setIsReorder] = useState(false);
	const [isPending, setIsPending] = useState(true);

	const initialStudioIsBackgroundRequired = useAppSelector(
		initialStudioIsBackgroundRequiredSelector
	);
	const initialRequiredPoseOptions = useAppSelector(
		initialRequiredPoseOptionsSelector
	);

	const lastAlaCarteUI = alaCartesUI[alaCartesUI.length - 1];

	const getAlaCartes = useCallback(async () => {
		try {
			if (!priceListKey) return;

			const data = await AlaCarteService.getAlaCartes(priceListKey);

			const updatedAlaCartesUI = data.map((alaCarte) => {
				const priceListFormQuestionUI =
					alaCarte.priceListStudioFulfilledALaCarteFormQuestions.map(
						(formQuestion) => ({
							...formQuestion,
							errors: initialPriceListFormQuestionErrors,
							entityKey: formQuestion.priceListStudioFulfilledALaCarteKey,
							formQuestionKey:
								formQuestion.priceListStudioFulfilledALaCarteFormQuestionKey,
						})
					);

				return {
					...alaCarte,
					errors: initialAlaCarteErrors,
					priceListStudioFulfilledALaCarteFormQuestions:
						priceListFormQuestionUI,
				};
			});

			setAlaCartesUI(updatedAlaCartesUI);
		} catch (error) {
			console.log(error);
		}

		setIsPending(false);
	}, [priceListKey]);

	const handleAddAlaCarte = () => {
		if (!priceListKey) return;

		const initialSequence = alaCartesUI.length && lastAlaCarteUI.sequence + 1;

		const newAlaCarteUI: IAlaCarteUI = {
			name: '',
			retailPrice: 0,
			description: '',
			referenceCode: '',
			previewImageUrl: null,
			sequence: initialSequence,
			errors: initialAlaCarteErrors,
			isWaiveShippingCharge: false,
			isIncludeImagesDownload: false,
			availableFor: PoseAvailableFor.Individual,
			priceListStudioFulfilledKey: priceListKey,
			requiredPoseOptions: initialRequiredPoseOptions,
			priceListStudioFulfilledALaCarteFormQuestions: [],
			isBackgroundOptionRequired: initialStudioIsBackgroundRequired,
		};

		setAlaCartesUI([...alaCartesUI, newAlaCarteUI]);
	};

	const handleSaveAlaCarte = async () => {
		if (!priceListKey) return;

		const {
			name,
			sequence,
			retailPrice,
			description,
			availableFor,
			referenceCode,
			requiredPoseOptions,
			isWaiveShippingCharge,
			isIncludeImagesDownload,
			isBackgroundOptionRequired,
		} = lastAlaCarteUI;

		const slicedAlaCartesUI = alaCartesUI.slice(0, -1);

		const referenceCodeValidationMessage = validateReferenceCode(referenceCode);
		const retailPriceValidationMessage = validateRetailValue(retailPrice);
		const descriptionValidationMessage = validateDescription({
			value: description,
		});
		const nameValidationMessage = validateOptionName(name);

		const isValid =
			!nameValidationMessage &&
			!retailPriceValidationMessage &&
			!descriptionValidationMessage &&
			!referenceCodeValidationMessage;

		if (!isValid) {
			const updatedAlaCarteUI: IAlaCarteUI = {
				...lastAlaCarteUI,
				errors: {
					...lastAlaCarteUI.errors,
					name: nameValidationMessage,
					retailPrice: retailPriceValidationMessage,
					description: descriptionValidationMessage,
					referenceCode: referenceCodeValidationMessage,
				},
			};

			setAlaCartesUI([...slicedAlaCartesUI, updatedAlaCarteUI]);
			return;
		}

		try {
			const body: ICreateAlaCarteBody = {
				name,
				sequence,
				retailPrice,
				description,
				availableFor,
				referenceCode,
				previewImageFile,
				requiredPoseOptions,
				isWaiveShippingCharge,
				isIncludeImagesDownload,
				isBackgroundOptionRequired,
			};

			const data = await AlaCarteService.createAlaCarte(priceListKey, body);

			const updatedAlaCarteUI: IAlaCarteUI = {
				...data,
				errors: initialAlaCarteErrors,
				priceListStudioFulfilledALaCarteFormQuestions: [],
			};

			setAlaCartesUI([...slicedAlaCartesUI, updatedAlaCarteUI]);
			setPreviewImageFile(null);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedAlaCarteUI: IAlaCarteUI = {
				...lastAlaCarteUI,
				errors: {
					...lastAlaCarteUI.errors,
					...errors,
				},
			};

			setAlaCartesUI([...slicedAlaCartesUI, updatedAlaCarteUI]);
		}
	};

	const updateAlaCarte = async ({
		value,
		fieldKey,
		sequence,
		alaCarteKey,
		validationMessage,
	}: IUpdateAlaCarte) => {
		if (!alaCarteKey || validationMessage) {
			const updatedAlaCartesUI = alaCartesUI.map((alaCarteUI) =>
				alaCarteUI.sequence === sequence
					? {
							...alaCarteUI,
							[fieldKey]: value,
							errors: { ...alaCarteUI.errors, [fieldKey]: validationMessage },
					  }
					: alaCarteUI
			);

			setAlaCartesUI(updatedAlaCartesUI);
			return;
		}

		if (!alaCarteKey) return;

		try {
			const updatedFiled: IPatchBody = {
				value,
				op: 'replace',
				path: fieldKey,
			};

			const body: IPatchBody[] = [updatedFiled];

			const data = await AlaCarteService.updateAlaCarte(alaCarteKey, body);

			const updatedAlaCartesUI = alaCartesUI.map((alaCarteUI) =>
				alaCarteUI.priceListStudioFulfilledALaCarteKey === alaCarteKey
					? {
							...data,
							errors: initialAlaCarteErrors,
							priceListStudioFulfilledALaCarteFormQuestions:
								alaCarteUI.priceListStudioFulfilledALaCarteFormQuestions,
					  }
					: alaCarteUI
			);

			setAlaCartesUI(updatedAlaCartesUI);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedAlaCartesUI = alaCartesUI.map((alaCarteUI) =>
				alaCarteUI.sequence === sequence
					? {
							...alaCarteUI,
							errors: {
								...alaCarteUI.errors,
								...errors,
							},
					  }
					: alaCarteUI
			);

			setAlaCartesUI(updatedAlaCartesUI);
		}
	};

	const changeAlaCarteSequence = (payload: IChangeSequencePayload) => {
		const reorderedAlaCartesUI = changeEntitiesSequence({
			...payload,
			entities: alaCartesUI,
			fieldKey: 'priceListStudioFulfilledALaCarteKey',
		});

		setAlaCartesUI(reorderedAlaCartesUI);
		setIsReorder(true);
	};

	const reorderAlaCartes = useCallback(async () => {
		if (!priceListKey || !isReorder) return;

		const body = alaCartesUI.reduce<Record<number, number>>(
			(acc, { sequence, priceListStudioFulfilledALaCarteKey }) =>
				priceListStudioFulfilledALaCarteKey
					? { ...acc, [priceListStudioFulfilledALaCarteKey]: sequence }
					: acc,
			{}
		);

		try {
			await AlaCarteService.reorderAlaCartes(priceListKey, body);
		} catch (error) {
			console.log(error);
		}
	}, [priceListKey, isReorder]);

	const uploadAlaCarteImage = async ({
		sequence,
		imageFile,
		alaCarteKey,
		validationMessage,
	}: IUploadAlaCarteImage) => {
		if (validationMessage) {
			const updatedAlaCartesUI = alaCartesUI.map((alaCarteUI) =>
				alaCarteUI.sequence === sequence
					? {
							...alaCarteUI,
							errors: {
								...alaCarteUI.errors,
								previewImageUrl: validationMessage,
							},
					  }
					: alaCarteUI
			);

			setAlaCartesUI(updatedAlaCartesUI);
			return;
		}

		if (!alaCarteKey) {
			setPreviewImageFile(imageFile);
			return;
		}

		try {
			const data = await AlaCarteService.uploadAlaCartePreviewImage(
				alaCarteKey,
				imageFile
			);

			const updatedAlaCartesUI = alaCartesUI.map((alaCarteUI) =>
				alaCarteUI.priceListStudioFulfilledALaCarteKey === alaCarteKey
					? { ...alaCarteUI, previewImageUrl: data }
					: alaCarteUI
			);

			setAlaCartesUI(updatedAlaCartesUI);
		} catch (error) {
			console.log(error);
		}
	};

	const deleteAlaCarteImage = async (alaCarteKey?: number) => {
		if (!alaCarteKey) {
			setPreviewImageFile(null);
			return;
		}

		try {
			await AlaCarteService.deleteAlaCartePreviewImage(alaCarteKey);

			const updatedAlaCartesUI = alaCartesUI.map((alaCarteUI) =>
				alaCarteUI.priceListStudioFulfilledALaCarteKey === alaCarteKey
					? { ...alaCarteUI, previewImageUrl: null }
					: alaCarteUI
			);

			setAlaCartesUI(updatedAlaCartesUI);
		} catch (error) {
			console.log(error);
		}
	};

	const deleteAlaCarte = async ({ sequence, alaCarteKey }: IDeleteAlaCarte) => {
		const updatedAlaCartesUI = alaCartesUI.filter(
			(alaCarteUI) => alaCarteUI.sequence !== sequence
		);

		setAlaCartesUI(updatedAlaCartesUI);
		setPreviewImageFile(null);

		if (alaCarteKey) {
			try {
				await AlaCarteService.deleteAlaCarte(alaCarteKey);
			} catch (error) {
				console.log(error);
			}
		}
	};

	const clearAlaCarteError = ({ sequence, fieldKey }: IClearPackageErrors) => {
		const updatedAlaCartesUI = alaCartesUI.map((alaCarteUI) =>
			alaCarteUI.sequence === sequence
				? {
						...alaCarteUI,
						errors: { ...alaCarteUI.errors, [fieldKey]: '' },
				  }
				: alaCarteUI
		);

		setAlaCartesUI(updatedAlaCartesUI);
	};

	const setAlaCarteFormQuestionsUI = (
		entityKey: number,
		formQuestionsUI: IPriceListFormQuestionUI[]
	) => {
		const updatedAlaCartesUI = alaCartesUI.map((alaCarteUI) =>
			alaCarteUI.priceListStudioFulfilledALaCarteKey === entityKey
				? {
						...alaCarteUI,
						priceListStudioFulfilledALaCarteFormQuestions: formQuestionsUI,
				  }
				: alaCarteUI
		);

		setAlaCartesUI(updatedAlaCartesUI);
	};

	const createFormQuestion = async (
		alaCarteKey: number,
		body: IGeneralFormQuestion
	) => {
		const data = await AlaCarteService.createAlaCarteFormQuestion(
			alaCarteKey,
			body
		);

		return {
			...data,
			errors: initialPriceListFormQuestionErrors,
			entityKey: data.priceListStudioFulfilledALaCarteKey,
			formQuestionKey: data.priceListStudioFulfilledALaCarteFormQuestionKey,
		};
	};

	const updateFormQuestion = async (
		formQuestionKey: number,
		body: IPatchBody[]
	) => {
		const data = await AlaCarteService.updateAlaCarteFormQuestion(
			formQuestionKey,
			body
		);

		return {
			...data,
			errors: initialPriceListFormQuestionErrors,
			entityKey: data.priceListStudioFulfilledALaCarteKey,
			formQuestionKey: data.priceListStudioFulfilledALaCarteFormQuestionKey,
		};
	};

	const deleteFormQuestion = async (formQuestionKey: number) =>
		AlaCarteService.deleteAlaCarteFormQuestion(formQuestionKey);

	const reorderFormQuestions = async (
		alaCarteKey: number,
		body: Record<number, number>
	) => AlaCarteService.reorderAlaCarteFormQuestions(alaCarteKey, body);

	const handleBack = () => {
		setActiveTab(StudioPriceListTabs.Packages);
	};

	useEffect(() => {
		void getAlaCartes();
	}, [getAlaCartes]);

	useEffect(() => {
		void reorderAlaCartes();
	}, [reorderAlaCartes]);

	const AlaCartesList = alaCartesUI.map((alaCarteUI) => (
		<PriceListAlaCarte
			alaCarteUI={alaCarteUI}
			deleteAlaCarte={deleteAlaCarte}
			updateAlaCarte={updateAlaCarte}
			previewImageFile={previewImageFile}
			clearAlaCarteError={clearAlaCarteError}
			createFormQuestion={createFormQuestion}
			updateFormQuestion={updateFormQuestion}
			deleteFormQuestion={deleteFormQuestion}
			deleteAlaCarteImage={deleteAlaCarteImage}
			uploadAlaCarteImage={uploadAlaCarteImage}
			reorderFormQuestions={reorderFormQuestions}
			setFormQuestionsUI={setAlaCarteFormQuestionsUI}
			changeAlaCartesSequence={changeAlaCarteSequence}
			key={
				alaCarteUI.priceListStudioFulfilledALaCarteKey || alaCarteUI.sequence
			}
		/>
	));

	const showSaveBtn =
		!!alaCartesUI.length &&
		!lastAlaCarteUI?.priceListStudioFulfilledALaCarteKey;

	const { btnValue, btnClassName, btnClickHandler } =
		useCreatePriceListOptionBtn({
			showSaveBtn,
			optionName: 'a la carte',
			handleAdd: handleAddAlaCarte,
			handleSave: handleSaveAlaCarte,
		});

	const showLoader = isPending || isPriceListPending;
	const showPriceTable = !showLoader && !!alaCartesUI.length;
	const showNoItemsFound = !showLoader && !alaCartesUI.length;

	return (
		<>
			<span className="price-package-title">a la carté</span>
			<div className="price-container">
				{showLoader && <Loader />}
				{showPriceTable && (
					<PriceTable
						headers={alaCarteTableHeaders}
						className="price-table-carte"
					>
						{AlaCartesList}
					</PriceTable>
				)}
				{showNoItemsFound && <NoItemsFound title="a la cartes" />}
				<Button
					value={btnValue}
					onClick={btnClickHandler}
					className={`price-add-back ${btnClassName}`}
				/>
				<PriceListControls showNextBtn={false} handleBack={handleBack} />
			</div>
		</>
	);
};
