import { FC } from 'react';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { Button } from 'components/FormControls/Button';
import { studioSelector } from 'store/studio';

interface StudioInfoProps {
	showUpdateStudioModal: () => void;
}

export const StudioInfo: FC<StudioInfoProps> = ({ showUpdateStudioModal }) => {
	const studio = useAppSelector(studioSelector);

	return (
		<>
			<span className="acc-user-item">
				<b>Account #:</b>
				{studio?.studioKey}
			</span>
			<ul className="acc-user-list">
				<li className="acc-user-item acc-user-names">
					<b>Studio Name:</b>
					<span>{studio?.studioName}</span>
				</li>
				{/* TODO: add correct link for networkDomain */}
				<li className="acc-user-item acc-user-names">
					<b>Domain:</b>
					<a href="#">{studio?.networkDomain}</a>
				</li>
				<li className="acc-user-item acc-item-btn">
					<Button
						className="btn-secondary"
						onClick={showUpdateStudioModal}
						value="Update Studio Name and URL"
					/>
				</li>
			</ul>
		</>
	);
};
