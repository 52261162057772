import { AuthProviderProps } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';

const authority = process.env.REACT_APP_OID_AUTHORITY || '';
const redirectUri = process.env.REACT_APP_OID_REDIRECT_URI || '';

export const oidcConfig: AuthProviderProps = {
	authority,
	redirect_uri: redirectUri,
	client_id: 'Imago',
	response_type: 'code',
	post_logout_redirect_uri: 'https://get.theimago.io/',
	scope: 'openid profile offline_access imago.studio.api.access',
	automaticSilentRenew: true,
	loadUserInfo: true,
	userStore: new WebStorageStateStore({ store: window.localStorage }),

	onSigninCallback() {
		window.history.replaceState({}, document.title, window.location.pathname);
	},
};
