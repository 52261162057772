import { FC, useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';

import { getSubjectFormQuestionsUI } from 'utils/subjectFormQuestions/getSubjectFormQuestionsUI';
import { getPriceListSelectOptionKey } from 'utils/priceLists/getPriceListSelectOptionKey';

import SubjectFormQuestionsService from 'api/services/SubjectFormQuestionsService';
import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';
import { IPreset, IPresetSale } from 'api/models/responses/presets/presets';
import PresetsService from 'api/services/PresetsService';
import {
	ICreatePreset,
	ICreatePresetSale,
	SaleCondition,
} from 'api/models/requests/presets/createPresets';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';

import { salesNotAvailableParam } from 'constants/priceLists/selectOptions/salesNotAvailableSelectOption';
import { PriceListFulfillment } from 'constants/priceLists/priceListFulfillment';
import { projectJourneyInfo } from 'constants/projects/projectJourneyInfo';
import { presetsUrls } from 'constants/apiUrls/presetsUrls';
import { ROUTES } from 'constants/ROUTES';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { ISaleValues } from 'components/Sales/Sale/types';
import { Loader } from 'components/Loader';

import {
	copiedJourneyPresetKeySelector,
	createJourneyPresetValuesSelector,
} from 'store/journeyPresets';
import {
	getReferencedItemsAsync,
	isValidSubjectFormQuestionsSelector,
	setSubjectFormQuestions,
	subjectFormQuestionsParamsSelector,
} from 'store/projects';

import { useJourneyPresetBreadcrumbs } from 'pages/JourneyPresets/hooks/useJourneyPresetBreadcrumbs';
import { getProcessedErrors } from 'utils/errors/getProcessedErrors';

import { journeyPresetDetailsScheme } from './validation';
import { JourneyPresetForm } from './JourneyPresetForm';
import { IJourneyPresetValues } from './types';

export const CreateJourneyPresetDetails: FC = () => {
	const [presetDetails, setPresetDetails] = useState<IPreset | null>(null);
	const [presetSales, setPresetSales] = useState<IPresetSale[]>([]);

	const [isFormQuestionsPending, setIsFormQuestionsPending] = useState(false);
	const [isPresetSalesPending, setIsPresetSalesPending] = useState(false);
	const [isPresetPending, setIsPresetPending] = useState(false);

	const copiedPresetKey = useAppSelector(copiedJourneyPresetKeySelector);
	const presetValues = useAppSelector(createJourneyPresetValuesSelector);
	const isValidSubjectFormQuestions = useAppSelector(
		isValidSubjectFormQuestionsSelector
	);
	const subjectFormQuestionsParams = useAppSelector(
		subjectFormQuestionsParamsSelector
	);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useJourneyPresetBreadcrumbs();

	const initialJourney =
		presetDetails?.journey || presetValues?.journey || ProjectJourneys.A;

	const staticDescription = projectJourneyInfo[initialJourney].description;

	const initialDescription =
		presetDetails?.presetDescription || staticDescription;
	const initialPresetName =
		presetDetails?.presetName || presetValues?.journeyName || '';
	const initialProjectType =
		presetDetails?.projectType || presetValues?.projectType || null;
	const initialIsMarketingEnabled = presetDetails?.isMarketingEnabled || true;

	const initialLeadCampaignKey =
		presetDetails?.leadCampaign?.campaignKey || null;
	const initialBuyerCampaignKey =
		presetDetails?.buyerCampaign?.campaignKey || null;
	const initialProspectCampaignKey =
		presetDetails?.prospectCampaign?.campaignKey || null;
	const initialAccessCodeMessage = presetDetails?.accessCodeMessage || '';
	const initialSmsSpecialMessage = presetDetails?.smsSpecialMessage || '';
	const initialEmailSpecialMessage = presetDetails?.emailSpecialMessage || '';

	const salesTypes = projectJourneyInfo[initialJourney].salesTypes;

	const emptyPresetSales = salesTypes.reduce<ISaleValues[]>((acc, saleType) => {
		if (!saleType) return acc;

		const initialSale: ISaleValues = {
			saleType,
			priceListKey: null,
			projectGreeting: '',
			saleExpiresInDays: '0',
			isKioskModeEnabled: true,
			orderNotificationEmail: '',
			orderConfirmationMessage: '',
			condition: SaleCondition.After,
		};

		return [...acc, initialSale];
	}, []);

	const copiedPresetSales: ISaleValues[] = presetSales.map(
		({
			saleType,
			priceList,
			condition,
			projectGreeting,
			saleExpiresInDays,
			isKioskModeEnabled,
			orderNotificationEmail,
			orderConfirmationMessage,
		}) => {
			const priceListKey =
				priceList &&
				getPriceListSelectOptionKey(
					priceList.priceListKey,
					priceList.fulfillmentType
				);

			return {
				saleType,
				condition,
				priceListKey,
				projectGreeting,
				isKioskModeEnabled,
				orderNotificationEmail,
				orderConfirmationMessage,
				saleExpiresInDays: saleExpiresInDays.toString(),
			};
		}
	);

	const initialPresetSales = copiedPresetKey
		? copiedPresetSales
		: emptyPresetSales;

	const initialValues: IJourneyPresetValues = {
		presetName: initialPresetName,
		presetDescription: initialDescription,
		projectType: initialProjectType,
		journey: initialJourney,
		presetSales: initialPresetSales,
		isMarketingEnabled: initialIsMarketingEnabled,
		prospectCampaignKey: initialProspectCampaignKey,
		leadCampaignKey: initialLeadCampaignKey,
		buyerCampaignKey: initialBuyerCampaignKey,
		accessCodeMessage: initialAccessCodeMessage,
		smsSpecialMessage: initialSmsSpecialMessage,
		emailSpecialMessage: initialEmailSpecialMessage,
	};

	const getPreset = async (presetKey: number) => {
		setIsPresetPending(true);

		try {
			const data = await PresetsService.getPreset(presetKey);

			setPresetDetails(data);
		} catch (error) {
			console.log(error);
		}

		setIsPresetPending(false);
	};

	const getPresetSales = async (presetKey: number) => {
		setIsPresetSalesPending(true);

		try {
			const data = await PresetsService.getPresetSales(presetKey);

			setPresetSales(data);
		} catch (error) {
			console.log(error);
		}

		setIsPresetSalesPending(false);
	};

	const getPresetSubjectFormQuestions = async (presetKey: number) => {
		setIsFormQuestionsPending(true);

		try {
			const data = await SubjectFormQuestionsService.getSubjectFormQuestions(
				presetsUrls.getPresetsFormQuestions(presetKey)
			);

			const subjectFormQuestionsUI = getSubjectFormQuestionsUI(data);

			dispatch(setSubjectFormQuestions(subjectFormQuestionsUI));
		} catch (error) {
			console.log(error);
		}

		setIsFormQuestionsPending(false);
	};

	const onSubmit = async (
		values: IJourneyPresetValues,
		{ setErrors }: FormikHelpers<IJourneyPresetValues>
	) => {
		if (!isValidSubjectFormQuestions) return;

		const {
			projectType,
			presetSales: presetSalesValues,
			...restValues
		} = values;

		if (!projectType) return;

		const presetSalesParams = presetSalesValues.reduce<ICreatePresetSale[]>(
			(acc, sale) => {
				const { priceListKey, saleExpiresInDays } = sale;

				if (!priceListKey) return acc;

				const [key, fulfillmentType] = priceListKey.split('-');

				const priceListParam =
					fulfillmentType !== PriceListFulfillment.NA
						? {
								priceListKey: +key,
								fulfillmentType: fulfillmentType as PriceListFulfillment,
						  }
						: salesNotAvailableParam;

				return [
					...acc,
					{
						...sale,
						...priceListParam,
						saleExpiresInDays: +saleExpiresInDays,
					},
				];
			},
			[]
		);

		const body: ICreatePreset = {
			...restValues,
			projectType,
			presetSales: presetSalesParams,
			presetSubjectFormQuestions: subjectFormQuestionsParams,
		};

		try {
			await PresetsService.createPreset(body);

			navigate(ROUTES.PRESETS.PRESETS);
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			if (
				type === ErrorTypes.BusinessError ||
				type === ErrorTypes.ValidationError
			) {
				const processedErrors = getProcessedErrors(values, errors);

				setErrors(processedErrors);
			}
		}
	};

	useEffect(() => {
		if (!copiedPresetKey) return;

		void getPreset(copiedPresetKey);
		void getPresetSales(copiedPresetKey);
		void getPresetSubjectFormQuestions(copiedPresetKey);
	}, [copiedPresetKey]);

	useEffect(() => {
		const { journey, projectType } = initialValues;

		if (!journey || !projectType) return;

		void dispatch(getReferencedItemsAsync({ journey, projectType }));
	}, [initialValues.journey, initialValues.projectType]);

	const showLoader =
		isPresetPending || isPresetSalesPending || isFormQuestionsPending;

	if (showLoader) {
		return <Loader isFullHeight />;
	}

	return (
		<Formik
			enableReinitialize
			onSubmit={onSubmit}
			initialValues={initialValues}
			validationSchema={journeyPresetDetailsScheme}
		>
			<JourneyPresetForm presetSales={presetSales} />
		</Formik>
	);
};
