import { IMarketingStatistic } from 'api/models/responses/dashboard/marketingStatistic';
import { IStudioStatistic } from 'api/models/responses/dashboard/studioStatistic';
import { ISendLinkBody } from 'api/models/requests/dashboard/sendLink';
import { Periods } from 'api/models/requests/dashboard/periods';

import { dashboardUrls } from 'constants/apiUrls/dashboardUrls';
import { api } from 'config/api';

class DashboardService {
	async getMarketingStatistic(period: Periods): Promise<IMarketingStatistic> {
		const { data } = await api.get<IMarketingStatistic>(
			dashboardUrls.marketingStatistic,
			{
				params: {
					period,
				},
			}
		);

		return data;
	}

	async getStudioStatistic(period: Periods): Promise<IStudioStatistic> {
		const { data } = await api.get<IStudioStatistic>(dashboardUrls.studio, {
			params: {
				period,
			},
		});

		return data;
	}

	async sendLink(body: ISendLinkBody): Promise<void> {
		const { data } = await api.post<void>(dashboardUrls.sendLink, body);

		return data;
	}
}

export default new DashboardService();
