import { FC, useEffect, useState } from 'react';

import { ToggleSection } from 'components/ToggleSection';
import { Loader } from 'components/Loader';

import { useAppSelector } from 'hooks/redux/useAppSelector';

import {
	additionalChargeOptionsSelector,
	isPendingBaseOptionsSelector,
} from 'store/priceLists/studio/priceListBaseOptions';

import { AdditionalChargeOptions } from './components/AdditionalChargeOptions';
import { ShippingOptions } from './components/ShippingOptions';
import { PresaleOptions } from './components/PresaleOptions';

import { IPriceListOptionsProps } from '../..';

export interface IOrderOptionsContainerProps
	extends Omit<IPriceListOptionsProps, 'isPriceListPending' | 'setActiveTab'> {
	className: string;
	isPriceListPending: boolean;
}

export const OrderOptionsContainer: FC<IOrderOptionsContainerProps> = ({
	className,
	priceListStudio,
	isPriceListPending,
	updatePriceListStudio,
}) => {
	const isPendingBaseOptions = useAppSelector(isPendingBaseOptionsSelector);

	const isAddBuddyPoseAtPresale = priceListStudio?.isAddBuddyPoseAtPresale;
	const priceListKey = priceListStudio?.priceListStudioFulfilledKey;
	const isChargeSalesTax = priceListStudio?.isChargeSalesTax;
	const salesTaxPercent = priceListStudio?.salesTaxPercent;

	const showLoader = isPriceListPending || isPendingBaseOptions;

	const additionalChargeOptionsUI = useAppSelector(
		additionalChargeOptionsSelector
	);

	const isSectionEmpty = additionalChargeOptionsUI.length > 0;

	const [isSectionOpen, setSectionOpen] = useState(isSectionEmpty);

	useEffect(() => {
		setSectionOpen(isSectionEmpty);
	}, [isSectionEmpty]);

	const handleToggle = () => {
		setSectionOpen((prev) => !prev);
	};

	return (
		<ToggleSection
			isOpen={isSectionOpen}
			className={className}
			title="Order Options"
			handleToggle={handleToggle}
		>
			{showLoader ? (
				<Loader />
			) : (
				<>
					<AdditionalChargeOptions
						priceListKey={priceListKey}
						salesTaxPercent={salesTaxPercent}
						isChargeSalesTax={isChargeSalesTax}
						updatePriceListStudio={updatePriceListStudio}
					/>
					<ShippingOptions priceListKey={priceListKey} />
					<PresaleOptions
						updatePriceListStudio={updatePriceListStudio}
						isAddBuddyPoseAtPresale={isAddBuddyPoseAtPresale}
					/>
				</>
			)}
		</ToggleSection>
	);
};
