import { ChangeEvent, FC, RefObject } from 'react';

import { Button } from 'components/FormControls/Button';
import { UploadButton } from 'components/UploadButton';

interface IAlbumControlsProps {
	handleCreateAlbum: () => void;
	fileInputRef: RefObject<HTMLInputElement>;
	handleUpload: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const AlbumControls: FC<IAlbumControlsProps> = ({
	fileInputRef,
	handleUpload,
	handleCreateAlbum,
}) => (
	<div className="project-image-btns">
		<Button
			className="btn-primary"
			value="Add A New Group"
			onClick={handleCreateAlbum}
		/>
		<UploadButton
			accept=".csv"
			fileInputRef={fileInputRef}
			handleUpload={handleUpload}
			text="Upload Image Access Code CSV"
		/>
	</div>
);
