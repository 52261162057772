import { ChangeEvent, FC, useState } from 'react';

import { Button } from 'components/FormControls/Button';
import { Input } from 'components/FormControls/Input';

interface ISearchProps {
	title?: string;
	placeholder: string;
	handleSearch: (value: string) => void;
}

export const Search: FC<ISearchProps> = ({
	title,
	placeholder,
	handleSearch,
}) => {
	const [value, setValue] = useState('');

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
	};

	const handleClick = () => {
		handleSearch(value);
	};

	return (
		<>
			{title && <h1 className="org-search-title-def">{title}</h1>}
			<Input
				value={value}
				className="org-search"
				placeholder={placeholder}
				handleChange={handleChange}
			/>
			<Button
				value="Search"
				onClick={handleClick}
				className="btn-secondary org-btn"
			/>
		</>
	);
};
