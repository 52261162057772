import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { FC, useCallback, useEffect, useState } from 'react';
import { CellProps, Column } from 'react-table';

import { useCampaignsBreadcrumbs } from 'pages/Campaigns/hooks/useCampaignsBreadcrumbs';
import { useSortBroadcasts } from 'pages/Campaigns/hooks/useSortBroadcasts';

import { BroadcastItemsSortLabels } from 'constants/broadcasts/broadcastItemsSortLabels';
import { BroadcastActionMenu } from 'constants/broadcasts/broadcastActionMenu';
import { ROUTES } from 'constants/ROUTES';

import { ColumnLabelContainer } from 'components/Table/ColumnLabelContainer';
import { ConfirmDelete } from 'components/Modal/components/ConfirmDelete';
import { UpgradePlan } from 'components/Modal/components/UpgradePlan';
import { Agreement } from 'components/Modal/components/Agreement';
import { CellActionMenu } from 'components/Table/CellActionMenu';
import { Button } from 'components/FormControls/Button';
import { Loader } from 'components/Loader';
import { Modal } from 'components/Modal';
import { Table } from 'components/Table';

import { setCampaignKey, setCopiedBroadcastKey } from 'store/broadcasts';
import { subscriptionSelector } from 'store/subscriptions';
import { IBreadcrumbs } from 'store/breadcrumbs';

import { IPatchBody } from 'api/models/requests/general/patchBody';
import CampaignsService from 'api/services/CampaignsService';
import BroadcastService from 'api/services/BroadcastService';
import {
	IBroadcastItem,
	ICampaignDetails,
} from 'api/models/responses/campaigns/campaignDetails';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useModalControls } from 'hooks/useModalControls';
import { useActionMenu } from 'hooks/useActionMenu';

import { CampaignDetailsHeader } from './CampaignDetailsHeader';

interface IActionMenuPayload {
	key: number;
	isCopy: boolean;
	broadcastName: string;
	menuItem: BroadcastActionMenu;
}

export const CampaignDetails: FC = () => {
	const [campaignDetails, setCampaignDetails] =
		useState<ICampaignDetails | null>(null);
	const [broadcastKey, setBroadcastKey] = useState<number | null>(null);
	const [broadcastNameInfo, setBroadcastNameInfo] = useState('');

	const [isPending, setIsPending] = useState(true);

	const isCopyAvailable = useAppSelector(subscriptionSelector)?.customCampaigns;

	const { campaignId } = useParams();
	const { pathname } = useLocation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const campaignName = campaignDetails?.name || '';
	const broadcasts = campaignDetails?.broadcasts || [];

	const campaignDetailsBreadcrumbs: IBreadcrumbs = {
		path: pathname,
		isActive: true,
		title: campaignName,
	};

	useCampaignsBreadcrumbs(campaignDetailsBreadcrumbs);

	const { orderBy, setSortParams, sortedBroadcasts, orderDirection } =
		useSortBroadcasts(broadcasts);

	const { actionMenuId, setActionMenuId } = useActionMenu();

	const {
		modalRef: confirmDeleteModalRef,
		showModal: showConfirmDeleteModal,
		hideModal: hideConfirmDeleteModal,
	} = useModalControls();

	const {
		modalRef: successDeleteModalRef,
		showModal: showSuccessDeleteModal,
		hideModal: hideSuccessDeleteModal,
	} = useModalControls();

	const {
		modalRef: upgradePlanModalRef,
		showModal: showUpgradePlanModal,
		hideModal: hideUpgradePlanModal,
	} = useModalControls();

	const getCampaignDetails = useCallback(async () => {
		if (!campaignId) return;

		try {
			const data = await CampaignsService.getCampaign(+campaignId);

			setCampaignDetails(data);
		} catch (error) {
			console.log(error);
		}

		setIsPending(false);
	}, [campaignId]);

	const updateCampaign = async (body: IPatchBody[]) => {
		if (!campaignId) return;

		const data = await CampaignsService.updateCampaign(+campaignId, body);

		setCampaignDetails(data);
	};

	const deleteBroadcast = async () => {
		if (!broadcastKey || !campaignDetails) return;

		try {
			await BroadcastService.deleteBroadcast(broadcastKey);

			const updatedBroadcasts = broadcasts.filter(
				(broadcast) => broadcast.key !== broadcastKey
			);

			setCampaignDetails({
				...campaignDetails,
				broadcasts: updatedBroadcasts,
			});

			showSuccessDeleteModal();
			hideConfirmDeleteModal();
		} catch (error) {
			console.log(error);
		}
	};

	const handleConfirmDelete = () => {
		void deleteBroadcast();
	};

	const handleMenuItemClick = ({
		key,
		isCopy,
		menuItem,
		broadcastName,
	}: IActionMenuPayload) => {
		switch (menuItem) {
			case BroadcastActionMenu.DuplicateBroadcast: {
				if (!campaignId) return;

				if (!isCopyAvailable) return showUpgradePlanModal();

				dispatch(setCopiedBroadcastKey(key));
				dispatch(setCampaignKey(+campaignId));

				return navigate(ROUTES.CAMPAIGNS.CREATE_BROADCAST);
			}

			case BroadcastActionMenu.DeletePermanently: {
				if (!isCopy && !isCopyAvailable) return showUpgradePlanModal();

				setBroadcastNameInfo(broadcastName);
				setBroadcastKey(key);

				return showConfirmDeleteModal();
			}

			default:
				return;
		}
	};

	const handleCreateBroadcast = () => {
		if (!campaignId) return;

		if (!isCopyAvailable) {
			return showUpgradePlanModal();
		}

		dispatch(setCampaignKey(+campaignId));

		navigate(ROUTES.CAMPAIGNS.CREATE_BROADCAST);
	};

	useEffect(() => {
		void getCampaignDetails();
	}, []);

	useEffect(() => {
		dispatch(setCampaignKey(null));
		dispatch(setCopiedBroadcastKey(null));
	}, []);

	const columns: Column<IBroadcastItem>[] = [
		{
			Header: (
				<ColumnLabelContainer<BroadcastItemsSortLabels>
					label="Name"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={BroadcastItemsSortLabels.Name}
				/>
			),
			accessor: 'name',
			Cell: ({ row }: CellProps<IBroadcastItem>) => {
				const { key, name } = row.original;

				return (
					<Link
						title={name}
						className="card-table-link"
						to={`${ROUTES.CAMPAIGNS.BROADCASTS}/${key}`}
					>
						{name}
					</Link>
				);
			},
		},
		{
			Header: (
				<ColumnLabelContainer<BroadcastItemsSortLabels>
					label="Send"
					orderBy={orderBy}
					setSortParams={setSortParams}
					orderDirection={orderDirection}
					sortLabel={BroadcastItemsSortLabels.Send}
				/>
			),
			accessor: 'send',
		},
		{
			id: 'actionMenu',
			Header: <span className="thead-empty">_</span>,
			Cell: ({ row }: CellProps<IBroadcastItem>) => {
				const { key, name, isCopy } = row.original;

				return (
					<CellActionMenu
						handleMenuItemClick={(menuItem) =>
							handleMenuItemClick({
								key,
								isCopy,
								menuItem,
								broadcastName: name,
							})
						}
						showActionMenu={actionMenuId === key}
						menuList={Object.values(BroadcastActionMenu)}
						setActiveActionMenu={() => setActionMenuId(key)}
					/>
				);
			},
		},
	];

	return (
		<>
			<div className="campaign-container">
				{isPending ? (
					<Loader />
				) : (
					<>
						<CampaignDetailsHeader
							name={campaignName}
							disabledEdit={!isCopyAvailable}
							updateCampaign={updateCampaign}
							target={campaignDetails?.campaignTarget}
						/>
						<div className="campaign-table-wrapper">
							<Table
								columns={columns}
								data={sortedBroadcasts}
								className="org-table table-action-menu"
							/>
						</div>
						<Button
							value="+ New Broadcast"
							onClick={handleCreateBroadcast}
							className="campaign-btn btn-primary"
						/>
					</>
				)}
			</div>
			<Modal
				title="Delete Broadcast"
				ref={confirmDeleteModalRef}
				subtitle={`Are you sure you would like to delete the Broadcast ${broadcastNameInfo}? This action cannot be undone.`}
			>
				<ConfirmDelete
					handlePermanentlyDelete={handleConfirmDelete}
					hideConfirmDeleteModal={hideConfirmDeleteModal}
				/>
			</Modal>
			<Modal
				title="Broadcast Deleted"
				ref={successDeleteModalRef}
				subtitle={`The Broadcast ${broadcastNameInfo} has been deleted.`}
			>
				<Agreement handleAgreement={hideSuccessDeleteModal} />
			</Modal>
			<Modal
				ref={upgradePlanModalRef}
				title="Upgrade Your Plan"
				subtitle="This option is not available on your current plan. Upgrade your plan now to get access to this feature and more."
			>
				<UpgradePlan hideModal={hideUpgradePlanModal} />
			</Modal>
		</>
	);
};
