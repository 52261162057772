import { Periods } from 'api/models/requests/dashboard/periods';

import { ISelectOption } from 'types/ui/select';

import { periodsUI } from './periodsUI';

export const periodsSelectOptions: ISelectOption<Periods>[] = Object.values(
	Periods
).map((period) => ({
	value: period,
	label: periodsUI[period],
}));
