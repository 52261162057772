import { FC, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';

import { TextEditorRef } from 'components/TextEditor/types';
import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { TextEditor } from 'components/TextEditor';

import { IPriceListSale } from 'api/models/responses/priceLists/priceListSale';
import { useToggleSection } from 'hooks/useToggleSection';

import { OrderConfirmation } from '../OrderConfirmation';
import { SalePriceList } from '../SalePriceList';
import { IEditSaleValues } from '../..';

interface ISaleDetailsFormProps {
	isPending: boolean;
	editorRef: TextEditorRef;
	projectGreetingInitial: string;
	allowedPriceListsInitial: IPriceListSale[];
}

export const SaleDetailsForm: FC<ISaleDetailsFormProps> = ({
	isPending,
	editorRef,
	projectGreetingInitial,
	allowedPriceListsInitial,
}) => {
	const { handleSubmit, isSubmitting, errors } =
		useFormikContext<IEditSaleValues>();

	const containerRef = useRef<HTMLDivElement>(null);

	const { isOpen, handleToggle } = useToggleSection(true);

	useEffect(() => {
		if (!isSubmitting) return;

		containerRef?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	}, [errors, isSubmitting]);

	return (
		<div ref={containerRef}>
			<SalePriceList
				disabled={isPending}
				allowedPriceLists={allowedPriceListsInitial}
			/>
			<OrderConfirmation disabled={isPending} />
			<ToggleSection
				title="Project Greeting"
				className="presale-section"
				isOpen={isOpen}
				handleToggle={handleToggle}
			>
				<TextEditor
					disabled={isPending}
					editorRef={editorRef}
					initialContent={projectGreetingInitial}
				/>
			</ToggleSection>
			<Button
				value="Save"
				disabled={isPending}
				onClick={() => handleSubmit()}
				className="btn-primary presale-modal-btn"
			/>
		</div>
	);
};
