import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IBankAccountRes } from 'api/models/responses/studioBilling/bankAccountRes';
import { IBillingInfo } from 'api/models/responses/studioBilling/billingInfo';
import { ICardInfo } from 'api/models/responses/studioBilling/cardInfo';

import { IStudioBillingState } from './types';

const initialState: IStudioBillingState = {
	bankACH: null,
	cardInfo: null,
	studioBilling: null,
	isBankACHFulfilled: false,
	isBillingInfoFulfilled: false,
};

const studioBillingSlice = createSlice({
	name: 'studioBilling',
	initialState,
	reducers: {
		setBankACH: (state, { payload }: PayloadAction<IBankAccountRes>) => {
			state.bankACH = payload;
		},

		setIsBankACHFulfilled: (state, { payload }: PayloadAction<boolean>) => {
			state.isBankACHFulfilled = payload;
		},

		setStudioBilling: (state, { payload }: PayloadAction<IBillingInfo>) => {
			state.studioBilling = payload;
		},

		setIsBillingInfoFulfilled: (state, { payload }: PayloadAction<boolean>) => {
			state.isBillingInfoFulfilled = payload;
		},

		setCardInfo: (state, { payload }: PayloadAction<ICardInfo>) => {
			state.cardInfo = payload;
		},
	},
});

export const studioBillingReducer = studioBillingSlice.reducer;
export const {
	setBankACH,
	setCardInfo,
	setStudioBilling,
	setIsBankACHFulfilled,
	setIsBillingInfoFulfilled,
} = studioBillingSlice.actions;
