import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';
import {
	FC,
	useRef,
	useState,
	useEffect,
	ChangeEvent,
	useCallback,
} from 'react';

import { ICreateProspects } from 'api/models/responses/projects/createProspects';
import { CampaignsTarget } from 'api/models/responses/campaigns/campaign';
import ProjectsService from 'api/services/ProjectsService';
import {
	ICampaignStatistic,
	ICampaignMarketingStatistic,
} from 'api/models/responses/projects/marketing';

import { Agreement } from 'components/Modal/components/Agreement';
import { UploadButton } from 'components/UploadButton';
import { Loader } from 'components/Loader';
import { Modal } from 'components/Modal';
import { Table } from 'components/Table';

import { SCV_FORMAT } from 'constants/general/csvFormat';
import { ROUTES } from 'constants/ROUTES';

import { useModalControls } from 'hooks/useModalControls';
import { IUpdateTimeline } from 'pages/Projects/types';

import { AddProspectsConfirmation } from './components/AddProspectsConfirmation';
import { AddProspectsReport } from './components/AddProspectsReport';
import { ProjectMessage } from './components/ProjectMessage';
import { SelectCampaign } from './components/SelectCampaign';
import { AddProspects } from './components/AddProspects';
import { CampaignEdit } from './components/CampaignEdit';
import { Campaign } from './components/Campaign';
import { IMarketingContainerProps } from '..';

export type MarketingProps = IUpdateTimeline &
	Omit<IMarketingContainerProps, 'hasAnySentBroadcast'>;

export const Marketing: FC<MarketingProps> = ({
	journey,
	projectKey,
	accessCodeMessage,
	smsSpecialMessage,
	setProjectDetails,
	isMarketingEnabled,
	emailSpecialMessage,
	setIsUpdateTimeline,
}) => {
	const [campaignStatistics, setCampaignStatistics] =
		useState<ICampaignMarketingStatistic | null>(null);

	const [uploadedFile, setUploadedFile] = useState<File | null>(null);

	const [confirmationData, setConfirmationData] =
		useState<ICreateProspects | null>(null);

	const [changeableCampaignTarget, setChangeableCampaignTarget] =
		useState<CampaignsTarget | null>(null);

	const [isMarketingStatisticPending, setIsMarketingStatisticPending] =
		useState(false);

	const fileInputRef = useRef<HTMLInputElement>(null);

	const {
		modalRef: fileErrorModalRef,
		showModal: showFileErrorModal,
		hideModal: hideFileErrorModal,
	} = useModalControls();

	const {
		modalRef: addProspectsModalRef,
		showModal: showAddProspectsModal,
		hideModal: hideAddProspectsModal,
	} = useModalControls();

	const {
		modalRef: changeCampaignModalRef,
		showModal: showChangeCampaignModal,
		hideModal: hideChangeCampaignModal,
	} = useModalControls();

	const {
		modalRef: addProspectsReportModalRef,
		showModal: showAddProspectsReportModal,
		hideModal: hideAddProspectsReportModal,
	} = useModalControls();

	const {
		modalRef: addProspectsConfirmationModalRef,
		showModal: showAddProspectsConfirmationModal,
		hideModal: hideAddProspectsConfirmationModal,
	} = useModalControls();

	const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		const fileInput = fileInputRef.current;

		if (!file || !fileInput) return;

		fileInput.value = '';

		if (file.type !== SCV_FORMAT) {
			showFileErrorModal();
			return;
		}

		setUploadedFile(file);
		showAddProspectsModal();
	};

	const getMarketingCampaignStatistics = useCallback(async () => {
		if (!projectKey) return;

		setIsMarketingStatisticPending(true);

		try {
			const data = await ProjectsService.getMarketingCampaignStatistics(
				projectKey
			);

			setCampaignStatistics(data);
		} catch (error) {
			console.log(error);
		}

		setIsMarketingStatisticPending(false);
	}, [projectKey]);

	useEffect(() => {
		void getMarketingCampaignStatistics();
	}, [getMarketingCampaignStatistics]);

	const totalNumberOfImportedRecords =
		confirmationData?.totalNumberOfImportedRecords || 0;
	const totalNumberOfRowsWithData =
		confirmationData?.totalNumberOfRowsWithData || 0;

	const errorRecordsCount =
		totalNumberOfRowsWithData - totalNumberOfImportedRecords;

	const isPlural = errorRecordsCount > 1;

	const stringConditionPart = isPlural
		? `are ${errorRecordsCount} people`
		: `is ${errorRecordsCount} person`;

	const erredRecordsMessage = errorRecordsCount
		? `There
			${stringConditionPart}
			in this list we are not able to add`
		: '';

	const campaignMarketingStatistics =
		campaignStatistics?.campaignMarketingStatistics || [];

	const BroadcastsList = campaignMarketingStatistics.map(
		({ name, campaignKey, campaignTarget, broadcasts }, index) => {
			const handleEdit = () => {
				setChangeableCampaignTarget(campaignTarget);
				showChangeCampaignModal();
			};

			return (
				<Campaign
					key={campaignKey}
					broadcasts={broadcasts}
					campaignTarget={campaignTarget}
					header={
						<CampaignEdit
							campaignName={name}
							handleEdit={handleEdit}
							disabled={!isMarketingEnabled}
						>
							{!index && (
								<UploadButton
									accept=".csv"
									text="+ Add Prospects"
									handleUpload={handleUpload}
									fileInputRef={fileInputRef}
									disabled={!isMarketingEnabled}
								/>
							)}
						</CampaignEdit>
					}
				/>
			);
		}
	);

	const columns: Column<ICampaignStatistic>[] = [
		{
			Header: <span className="thead-empty">_</span>,
			accessor: 'campaignTarget',
			Cell: ({ row }: CellProps<ICampaignStatistic>) => {
				const { campaignKey, campaignTarget } = row.original;

				const path = `${ROUTES.CAMPAIGNS.CAMPAIGNS}/${campaignKey}`;
				const value = `${campaignTarget} Campaign`;

				return (
					<Link to={path} title={value}>
						{value}
					</Link>
				);
			},
		},
		{
			Header: 'Campaign',
			accessor: 'name',
		},
		{
			Header: '# of Broadcasts',
			accessor: 'broadcastsCount',
		},
		{
			Header: 'People',
			accessor: 'people',
		},
		{
			Header: 'Sends',
			accessor: 'sends',
		},
		{
			Header: 'Opens',
			accessor: 'opens',
		},
		{
			Header: 'Clicks',
			accessor: 'clicks',
		},
		{
			Header: 'Converted',
			accessor: 'converted',
		},
		{
			Header: 'Unsubscribe',
			accessor: 'unsubscribes',
		},
	];

	if (isMarketingStatisticPending) {
		return <Loader />;
	}

	return (
		<>
			<Table
				columns={columns}
				data={campaignMarketingStatistics}
				className="org-table project-details-table"
			/>
			{BroadcastsList}
			<ProjectMessage
				projectKey={projectKey}
				setProjectDetails={setProjectDetails}
				accessCodeMessage={accessCodeMessage}
				smsSpecialMessage={smsSpecialMessage}
				isMarketingEnabled={isMarketingEnabled}
				emailSpecialMessage={emailSpecialMessage}
			/>
			<Modal
				title="Select Campaign"
				ref={changeCampaignModalRef}
				subtitle="Select the new campaign you would like to use for this project."
			>
				<SelectCampaign
					journey={journey}
					projectKey={projectKey}
					campaignTarget={changeableCampaignTarget}
					setIsUpdateTimeline={setIsUpdateTimeline}
					hideChangeCampaignModal={hideChangeCampaignModal}
				/>
			</Modal>
			<Modal
				title="Add Prospects"
				ref={addProspectsModalRef}
				subtitle="Select the columns with email addresses and phone numbers."
				className="modal-fixed"
			>
				<AddProspects
					projectKey={projectKey}
					uploadedFile={uploadedFile}
					setConfirmationData={setConfirmationData}
					hideAddProspectsModal={hideAddProspectsModal}
					showAddProspectsConfirmationModal={showAddProspectsConfirmationModal}
				/>
			</Modal>
			<Modal
				title={
					totalNumberOfImportedRecords
						? 'Adding your Prospects'
						: 'No Prospects Found'
				}
				ref={addProspectsConfirmationModalRef}
				subtitle={
					totalNumberOfImportedRecords
						? 'The list of prospects is being added to this project.'
						: ''
				}
			>
				<AddProspectsConfirmation
					confirmationData={confirmationData}
					erredRecordsMessage={erredRecordsMessage}
					showAddProspectsReportModal={showAddProspectsReportModal}
					hideAddProspectsConfirmationModal={hideAddProspectsConfirmationModal}
				/>
			</Modal>
			<Modal ref={addProspectsReportModalRef} title={`${erredRecordsMessage}:`}>
				<AddProspectsReport
					confirmationData={confirmationData}
					hideAddProspectsReportModal={hideAddProspectsReportModal}
				/>
			</Modal>
			<Modal
				ref={fileErrorModalRef}
				title="Wrong Type of File"
				subtitle="Please select a valid .csv file to import your prospects."
			>
				<Agreement handleAgreement={hideFileErrorModal} />
			</Modal>
		</>
	);
};
