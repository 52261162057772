import { Formik, FormikHelpers } from 'formik';
import { FC } from 'react';

import { IChangeEmailBody } from 'api/models/requests/account/changeEmailBody';
import AccountService from 'api/services/AccountService';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { currentUserSelector, setCurrentUser } from 'store/accounts';

import { changeEmailValidationSchema } from './validation';
import { ChangeEmailForm } from './ChangeEmailForm';

export interface IChangeEmailValues {
	verificationCode: string;
}

export interface IChangeEmailProps {
	confirmedEmail: string;
	hideChangeEmailModal: () => void;
}

export const ChangeEmail: FC<IChangeEmailProps> = ({
	confirmedEmail,
	hideChangeEmailModal,
}) => {
	const currentUser = useAppSelector(currentUserSelector);

	const dispatch = useAppDispatch();

	const initialValues: IChangeEmailValues = {
		verificationCode: '',
	};

	const onSubmit = async (
		values: IChangeEmailValues,
		{ setErrors }: FormikHelpers<IChangeEmailValues>
	) => {
		if (!currentUser) return;

		const body: IChangeEmailBody = {
			email: confirmedEmail,
			verificationCode: values.verificationCode,
		};

		try {
			await AccountService.changeEmail(body);

			dispatch(setCurrentUser({ ...currentUser, email: confirmedEmail }));

			hideChangeEmailModal();
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			if (type === ErrorTypes.BusinessError) {
				const verificationCodeError = errors.verificationCode?.[0];

				if (verificationCodeError) {
					setErrors({ verificationCode: verificationCodeError });
				}
			}
		}
	};

	return (
		<Formik
			onSubmit={onSubmit}
			initialValues={initialValues}
			validationSchema={changeEmailValidationSchema}
		>
			<ChangeEmailForm
				confirmedEmail={confirmedEmail}
				hideChangeEmailModal={hideChangeEmailModal}
			/>
		</Formik>
	);
};
