import { tz } from 'moment-timezone';
import moment from 'moment';

export const inHourTimeline = (
	startDayHour: number,
	endDateHour: number,
	timeZone?: string
) => {
	const hours = timeZone ? tz(timeZone).hours() : moment().hours();

	return hours > startDayHour && hours < endDateHour;
};
