import { object, string } from 'yup';

import { getInvalidKeyWordsMessage } from 'utils/broadcast/getInvalidKeyWordsMessage';
import { getMaxLengthMessage } from 'utils/validations/general/getMaxLengthMessage';
import { parseKeyWordsFromString } from 'utils/broadcast/parseKeyWordsFromString';
import { validateKeyWords } from 'utils/broadcast/validateKeyWords';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import {
	emailBodyMaxLength,
	maxEmailSubjectLength,
	emailBodyMaxLengthError,
} from 'constants/broadcasts/validation';

export const getSendSpecialBroadcastScheme = (
	sendByEmail: boolean,
	sendByPhone: boolean
) =>
	object().shape({
		emailSubject: sendByEmail
			? string()
					.required(requiredFieldMessage)
					.max(
						maxEmailSubjectLength,
						getMaxLengthMessage(maxEmailSubjectLength)
					)
					.test('', '', (value, context) => {
						const parsedKeyWords = parseKeyWordsFromString(value);

						const { isValidKeyWords, invalidKeyWords } =
							validateKeyWords(parsedKeyWords);

						if (!isValidKeyWords) {
							return context.createError({
								message: getInvalidKeyWordsMessage(invalidKeyWords),
							});
						}

						return true;
					})
			: string().notRequired(),
		emailMessage: sendByEmail
			? string()
					.required(requiredFieldMessage)
					.max(emailBodyMaxLength, emailBodyMaxLengthError)
					.test('', '', (value, context) => {
						const parsedKeyWords = parseKeyWordsFromString(value);

						const { isValidKeyWords, invalidKeyWords } =
							validateKeyWords(parsedKeyWords);

						if (!isValidKeyWords) {
							return context.createError({
								message: getInvalidKeyWordsMessage(invalidKeyWords),
							});
						}

						return true;
					})
			: string().notRequired(),
		textMessage: sendByPhone
			? string()
					.required(requiredFieldMessage)
					.test('', '', (value, context) => {
						const parsedKeyWords = parseKeyWordsFromString(value);

						const { isValidKeyWords, invalidKeyWords } =
							validateKeyWords(parsedKeyWords);

						if (!isValidKeyWords) {
							return context.createError({
								message: getInvalidKeyWordsMessage(invalidKeyWords),
							});
						}

						return true;
					})
			: string().notRequired(),
		sendToEmailAddresses: string().notRequired(),
		sendToPhoneNumbers: string().notRequired(),
	});
