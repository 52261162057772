import { FC } from 'react';

import { TableRowNames } from 'pages/RegistrationSteps/SelectPlan/components/TableRowLabels';
import { TableColumn } from 'pages/RegistrationSteps/SelectPlan/components/TableColumn';

import { IErrorResponse } from 'api/models/responses/general/errorResponse';
import SubscriptionsService from 'api/services/SubscriptionsService';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToastify } from 'hooks/useToastify';

import { Button } from 'components/FormControls/Button';
import {
	setSubscription,
	subscriptionsSelector,
	setActiveSubscriptionKey,
	activeSubscriptionKeySelector,
} from 'store/subscriptions';

interface IUpgradeYourPlanProps {
	hideUpgradePlanModal: () => void;
}

export const UpgradeYourPlan: FC<IUpgradeYourPlanProps> = ({
	hideUpgradePlanModal,
}) => {
	const subscriptions = useAppSelector(subscriptionsSelector);
	const activeSubscriptionLevelKey = useAppSelector(
		activeSubscriptionKeySelector
	);

	const { showErrorsToastify } = useToastify();
	const dispatch = useAppDispatch();

	const selectSubscription = (key: number) => {
		dispatch(setActiveSubscriptionKey(key));
	};

	const handleChoosePlan = async () => {
		try {
			await SubscriptionsService.setStudioSubscription(
				activeSubscriptionLevelKey
			);

			const newSubscription = subscriptions.find(
				({ subscriptionLevelKey }) =>
					subscriptionLevelKey === activeSubscriptionLevelKey
			);

			if (!newSubscription) return;

			dispatch(setSubscription(newSubscription));
		} catch (error) {
			const { type, errors } = error as IErrorResponse;

			const toastifyError = {
				type,
				errors,
			};

			showErrorsToastify(toastifyError);
		}

		hideUpgradePlanModal();
	};

	const TableColumnsList = subscriptions.map((subscription) => (
		<TableColumn
			{...subscription}
			key={subscription.subscriptionLevelKey}
			selectSubscription={selectSubscription}
		/>
	));

	return (
		<div className="plans">
			<div className="panel-plans">
				<TableRowNames />
				{TableColumnsList}
			</div>
			<span className="plans-clarify">
				* Does not include 3% cc processing fee
			</span>
			<div className="accing-plan-btns">
				<Button
					value="Upgrade"
					className="btn-primary"
					onClick={() => void handleChoosePlan()}
				/>
				<Button
					value="Back"
					className="btn-secondary"
					onClick={hideUpgradePlanModal}
				/>
			</div>
		</div>
	);
};
