import { ChangeEvent, FC, useState } from 'react';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import {
	organizationNameRegEx,
	nameValidationMessage,
} from 'constants/organizations/validation';

import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

import PresetsService from 'api/services/PresetsService';

interface ICreatePresetProps {
	projectKey?: number;
	hideCreateModal: () => void;
	showSuccessModal: () => void;
	setPresetKey: (presetKey: number) => void;
}

export const CreatePreset: FC<ICreatePresetProps> = ({
	projectKey,
	setPresetKey,
	hideCreateModal,
	showSuccessModal,
}) => {
	const [presetName, setPresetName] = useState('');
	const [error, setError] = useState('');

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		setPresetName(value);
		setError('');
	};

	const handleCreate = async () => {
		if (!projectKey) return;

		if (!presetName) {
			return setError(requiredFieldMessage);
		}

		const isValidName = organizationNameRegEx.test(presetName);

		if (!isValidName) {
			return setError(nameValidationMessage);
		}

		try {
			const { presetKey } = await PresetsService.createPresetFromProject(
				projectKey,
				{
					presetName,
				}
			);

			setPresetKey(presetKey);

			hideCreateModal();
			showSuccessModal();
		} catch (errorParam) {
			console.log(errorParam);
		}
	};

	return (
		<div className="modal-body">
			<div className="preset-modal">
				<InputGrid
					touched
					error={error}
					id="presetName"
					className="row"
					value={presetName}
					label="Preset Name"
					handleChange={handleChange}
				/>
				<Button
					value="Create"
					className="org-modal-btn btn-primary"
					onClick={() => void handleCreate()}
				/>
			</div>
		</div>
	);
};
