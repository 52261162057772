import { FC } from 'react';

import { Button } from 'components/FormControls/Button';

interface ISuccessCreateProps {
	handleView: () => void;
	handleClose: () => void;
}

export const SuccessCreate: FC<ISuccessCreateProps> = ({
	handleView,
	handleClose,
}) => (
	<div className="org-modal-confirm">
		<Button value="View Preset" className="btn-primary" onClick={handleView} />
		<Button value="Close" className="btn-secondary" onClick={handleClose} />
	</div>
);
