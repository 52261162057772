import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getInitialPaginationResponse } from 'constants/general/pagination/getInitialPaginationResponse';
import { initialGeneralStatistic } from 'constants/general/statistic/initialGeneralStatistic';

import { IMarketingStatistic } from 'api/models/responses/dashboard/marketingStatistic';
import { PaginatedOrdersWaiting } from 'api/models/responses/orders/orderWaiting';
import { IStudioStatistic } from 'api/models/responses/dashboard/studioStatistic';
import { ILatestProject } from 'api/models/responses/projects/latestProjects';
import { IComingSoon } from 'api/models/responses/projects/comingSoon';
import { PaginatedOrders } from 'api/models/responses/orders/order';
import { Periods } from 'api/models/requests/dashboard/periods';

import { IDashboardState } from './types';

const initialOrdersWaiting: PaginatedOrdersWaiting =
	getInitialPaginationResponse();

const initialLatestOrders: PaginatedOrders = getInitialPaginationResponse();

const initialStudioStatistic: IStudioStatistic = {
	...initialGeneralStatistic,
	projects: 0,
	organizations: 0,
};

const initialState: IDashboardState = {
	comingSoon: [],
	latestProjects: [],
	marketingStatistic: null,
	selectedPeriod: Periods.Week,
	isStudioStatisticPending: false,
	latestOrders: initialLatestOrders,
	isMarketingStatisticPending: false,
	ordersWaiting: initialOrdersWaiting,
	studioStatistic: initialStudioStatistic,
};

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		setMarketingStatistic: (
			state,
			{ payload }: PayloadAction<IMarketingStatistic>
		) => {
			state.marketingStatistic = payload;
		},

		setIsMarketingStatisticPending: (
			state,
			{ payload }: PayloadAction<boolean>
		) => {
			state.isMarketingStatisticPending = payload;
		},

		setStudioStatistic: (
			state,
			{ payload }: PayloadAction<IStudioStatistic>
		) => {
			state.studioStatistic = payload;
		},

		setIsStudioStatisticPending: (
			state,
			{ payload }: PayloadAction<boolean>
		) => {
			state.isStudioStatisticPending = payload;
		},

		setLatestOrders: (state, { payload }: PayloadAction<PaginatedOrders>) => {
			state.latestOrders = payload;
		},

		setComingSoon: (state, { payload }: PayloadAction<IComingSoon[]>) => {
			state.comingSoon = payload;
		},

		setLatestProjects: (
			state,
			{ payload }: PayloadAction<ILatestProject[]>
		) => {
			state.latestProjects = payload;
		},

		setSelectedPeriod: (state, { payload }: PayloadAction<Periods>) => {
			state.selectedPeriod = payload;
		},

		setOrdersWaiting: (
			state,
			{ payload }: PayloadAction<PaginatedOrdersWaiting>
		) => {
			state.ordersWaiting = payload;
		},
	},
});

export const dashboardReducer = dashboardSlice.reducer;
export const {
	setComingSoon,
	setLatestOrders,
	setOrdersWaiting,
	setSelectedPeriod,
	setLatestProjects,
	setStudioStatistic,
	setMarketingStatistic,
	setIsStudioStatisticPending,
	setIsMarketingStatisticPending,
} = dashboardSlice.actions;
