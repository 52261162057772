import { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

import { MarketingKeywords } from 'components/Broadcast/MarketingKeywords';
import { TextEditorRef } from 'components/TextEditor/types';
import { Button } from 'components/FormControls/Button';

import { IBroadcast } from 'api/models/responses/broadcasts/broadcast';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { subscriptionSelector } from 'store/subscriptions';

import { BroadcastTextMessageContainer } from './components/BroadcastTextMessageContainer';
import { BroadcastEmailContainer } from './components/BroadcastEmailContainer';
import { BroadcastSettings } from './components/BroadcastSettings';
import { SendTestBroadcast } from './components/SendTestBroadcast';

import { IBroadcastValues } from '..';

interface IBroadcastFormProps
	extends Pick<
		IBroadcast,
		| 'replyTo'
		| 'emailFrom'
		| 'isTemplate'
		| 'senderName'
		| 'phoneNumber'
		| 'campaignKey'
		| 'campaignTarget'
		| 'projectDateType'
	> {
	editorRef: TextEditorRef;
	initialEmailBody: string;
	isCreateBroadcast: boolean;
	parsedSpecialDate: Date | null;
}

export const BroadcastForm: FC<IBroadcastFormProps> = ({
	replyTo,
	editorRef,
	emailFrom,
	isTemplate,
	senderName,
	phoneNumber,
	campaignKey,
	campaignTarget,
	projectDateType,
	initialEmailBody,
	isCreateBroadcast,
	parsedSpecialDate,
}) => {
	const [sendTestBroadcastSubmitted, setSendTestBroadcastSubmitted] =
		useState(false);

	const { errors, handleSubmit, isSubmitting, setFieldValue } =
		useFormikContext<IBroadcastValues>();

	const isCopyAvailable = useAppSelector(subscriptionSelector)?.customCampaigns;

	const broadcastControlBtnValue = isCreateBroadcast ? 'Create' : 'Save';

	const handleSubmitForm = async () => {
		const emailMessage = editorRef.current?.editor?.data.get() || '';

		await setFieldValue('emailMessage', emailMessage);

		handleSubmit();
	};

	useEffect(() => {
		if (!isSubmitting && !sendTestBroadcastSubmitted) return;

		const firstErrorKey = Object.keys(errors)[0];

		if (!firstErrorKey) return;

		setSendTestBroadcastSubmitted(false);

		if (firstErrorKey === 'emailMessage') {
			const $field = document.getElementById('emailSubject');

			$field?.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
			});

			return;
		}

		const $field = document.getElementById(firstErrorKey);

		if (!$field) return;

		$field.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	}, [errors, isSubmitting, sendTestBroadcastSubmitted]);

	return (
		<div className="campaign-section-container">
			<BroadcastSettings
				isTemplate={isTemplate}
				campaignTarget={campaignTarget}
				disabledEdit={!isCopyAvailable}
				projectDateType={projectDateType}
				parsedSpecialDate={parsedSpecialDate}
			/>
			<BroadcastEmailContainer
				replyTo={replyTo}
				editorRef={editorRef}
				emailFrom={emailFrom}
				senderName={senderName}
				disabledEdit={!isCopyAvailable}
				initialEmailBody={initialEmailBody}
			/>
			<BroadcastTextMessageContainer
				phoneNumber={phoneNumber}
				disabledEdit={!isCopyAvailable}
			/>
			<MarketingKeywords showTestBroadcastValues />
			<SendTestBroadcast
				editorRef={editorRef}
				campaignKey={campaignKey}
				disableSendTest={isCreateBroadcast}
				setSendTestBroadcastSubmitted={setSendTestBroadcastSubmitted}
			/>
			<Button
				disabled={!isCopyAvailable}
				value={broadcastControlBtnValue}
				className="campaign-btn btn-primary"
				onClick={() => void handleSubmitForm()}
			/>
		</div>
	);
};
