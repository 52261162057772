import { FC, useEffect, useRef } from 'react';

import { PoseOptionsSubTitle } from 'pages/PriceLists/components/BackgroundOptions/components/PoseOptionsSubTitle';
import { PriceListPoseOptionsPrompt } from 'pages/PriceLists/components/PriceListPoseOptionsPrompt';
import { AddOptionTypeModalBody } from 'pages/PriceLists/components/AddOptionTypeModalBody';
import { BackgroundOptions } from 'pages/PriceLists/components/BackgroundOptions';

import { getImagoBackgroundsAsync } from 'store/priceLists/priceListBackgrounds';
import {
	addPoseOptionType,
	showImagoBackgroundOptionsSelector,
	filteredPoseOptionTypesSelectOptionsSelector,
} from 'store/priceLists/imago/priceListPoseOptions';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToggleSection } from 'hooks/useToggleSection';

import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { Modal, ModalRef } from 'components/Modal';
import { Loader } from 'components/Loader';

import { ImagoPoseOptionTypes } from 'api/models/responses/priceLists/imago/priceListOptions/poseOptionTypes';
import { PriceListFulfillment } from 'constants/priceLists/priceListFulfillment';

interface IPoseOptionsContainerProps {
	priceListKey?: number;
	isPriceListPending: boolean;
}

export const PoseOptionsContainer: FC<IPoseOptionsContainerProps> = ({
	priceListKey,
	isPriceListPending,
}) => {
	const addPoseOptionModalRef = useRef<ModalRef>(null);

	const filteredPoseOptionTypesSelectOptions = useAppSelector(
		filteredPoseOptionTypesSelectOptionsSelector
	);
	const showImagoBackgroundOptions = useAppSelector(
		showImagoBackgroundOptionsSelector
	);

	const { isOpen, handleToggle } = useToggleSection(true);

	const dispatch = useAppDispatch();

	const showAddPoseOptionModal = () => {
		addPoseOptionModalRef?.current?.open();
	};

	const hideAddPoseOptionModal = () => {
		addPoseOptionModalRef?.current?.close();
	};

	const addPoseOptionTypeHandler = (
		selectedPoseOptionType: ImagoPoseOptionTypes
	) => {
		dispatch(addPoseOptionType(selectedPoseOptionType));
		hideAddPoseOptionModal();
	};

	useEffect(() => {
		if (!priceListKey) return;

		void dispatch(getImagoBackgroundsAsync(priceListKey));
	}, [priceListKey]);

	const showLoader = isPriceListPending;

	const disabledAddPoseOptionBtn = showImagoBackgroundOptions;

	return (
		<>
			<ToggleSection
				isOpen={isOpen}
				title="Pose Options"
				handleToggle={handleToggle}
				className="price-search-container"
				header={
					<PoseOptionsSubTitle title="(prompted when pose is selected)" />
				}
			>
				{showLoader ? (
					<Loader />
				) : (
					<div className="price-container">
						{showImagoBackgroundOptions && (
							<BackgroundOptions
								priceListKey={priceListKey}
								fulfillment={PriceListFulfillment.Imago}
							/>
						)}
						<PriceListPoseOptionsPrompt />
						<Button
							onClick={showAddPoseOptionModal}
							disabled={disabledAddPoseOptionBtn}
							className="price-add-type btn-secondary"
							value="+ Add Another Type of Pose Option"
						/>
					</div>
				)}
			</ToggleSection>
			<Modal ref={addPoseOptionModalRef} title="Add Type of Pose Option">
				<AddOptionTypeModalBody<ImagoPoseOptionTypes>
					selectLabel="Pose Option"
					btnValue="Add This Pose Option"
					addOptionType={addPoseOptionTypeHandler}
					selectPlaceholder="Select Pose Option Type"
					selectOptions={filteredPoseOptionTypesSelectOptions}
				/>
			</Modal>
		</>
	);
};
