import { useNavigate } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { FC } from 'react';

import { projectJourneyFilterSelectOptions } from 'constants/projects/selectOptions/projectJourneysSelectOptions';
import { projectTypeFilterSelectOptions } from 'constants/projects/selectOptions/projectTypesSelectOptions';
import { ROUTES } from 'constants/ROUTES';

import { ProjectTypeFilterSelectOption } from 'types/projects/selectOptions/projectTypeFilterSelectOption';
import { JourneyFilterSelectOption } from 'types/projects/selectOptions/journeyFilterSelectOption';

import { SelectComponent } from 'components/FormControls/Select';
import { Button } from 'components/FormControls/Button';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { ISelectOption } from 'types/ui/select';
import { Steps } from 'pages/Projects/types';
import {
	setFilterByJourney,
	setFilterByProjectType,
	filterByJourneySelector,
	filterByProjectTypeSelector,
} from 'store/journeyPresets';

import { JourneyPresets } from './JourneyPresets';
import { IStep1Props } from '../..';

export const Form: FC<IStep1Props> = ({
	setStep,
	getPaginatedJourneyPresets,
}) => {
	const filterByProjectType = useAppSelector(filterByProjectTypeSelector);
	const filterByJourney = useAppSelector(filterByJourneySelector);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const handleProjectTypeChange = (
		option: SingleValue<ISelectOption<ProjectTypeFilterSelectOption>>
	) => {
		if (!option) return;

		dispatch(setFilterByProjectType(option.value));
	};

	const handleJourneyChange = (
		option: SingleValue<ISelectOption<JourneyFilterSelectOption>>
	) => {
		if (!option) return;

		dispatch(setFilterByJourney(option.value));
	};

	const handleContinue = () => {
		setStep(Steps.STEP2);
	};

	const handleCreateBlankProject = () => {
		navigate(ROUTES.PROJECTS.CREATE_BLANK_PROJECT);
	};

	return (
		<form className="preset-blank-form">
			<div className="input-form-grid">
				<SelectComponent
					id="projectType"
					value={filterByProjectType}
					label="Filter by Project Type"
					onChange={handleProjectTypeChange}
					selectOptions={projectTypeFilterSelectOptions}
				/>
			</div>
			<div className="input-form-grid">
				<SelectComponent
					id="journey"
					value={filterByJourney}
					label="Filter by Journey"
					onChange={handleJourneyChange}
					selectOptions={projectJourneyFilterSelectOptions}
				/>
			</div>
			<JourneyPresets getPaginatedJourneyPresets={getPaginatedJourneyPresets} />
			<Button
				value="Continue"
				className="btn-primary"
				onClick={handleContinue}
				disabled={!filterByJourney}
			/>
			<Button
				className="btn-secondary"
				value="+ Create a blank project"
				onClick={handleCreateBlankProject}
			/>
		</form>
	);
};
