import { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { uploadPhotosBlobUrl } from 'constants/images/blobUrls';
import { useToastify } from 'hooks/useToastify';
import { api } from 'config/api';

export const useAxiosInterceptors = () => {
	const [setupFinished, setSetupFinished] = useState(false);

	const { showErrorToastify } = useToastify();

	const authContext = useAuth();

	const { user, isLoading, isAuthenticated } = authContext;

	const reqInterceptorFulfilled = useCallback(
		(request: InternalAxiosRequestConfig) => {
			const { headers, url } = request;

			if (url?.includes(uploadPhotosBlobUrl)) {
				return request;
			}

			if (user) {
				headers.Authorization = `Bearer ${user.access_token}`;
			}

			return request;
		},
		[user]
	);

	const resInterceptorEject = async (error: AxiosError) => {
		const statusCode = error.response?.status;

		switch (statusCode) {
			case 401: {
				await authContext.signinRedirect();
				break;
			}

			case 413: {
				showErrorToastify({
					title: 'File is too large.',
					message: 'Please upload a smaller file.',
				});
				break;
			}

			default:
				break;
		}
		return Promise.reject(error.response?.data);
	};

	useEffect(() => {
		if (!isAuthenticated || isLoading) return;

		const reqInterceptor = api.interceptors.request.use(
			reqInterceptorFulfilled,
			null,
			{ synchronous: true }
		);

		const resInterceptor = api.interceptors.response.use(
			null,
			resInterceptorEject
		);

		setSetupFinished(true);

		return () => {
			api.interceptors.request.eject(reqInterceptor);
			api.interceptors.response.eject(resInterceptor);
		};
	}, [isLoading, isAuthenticated, reqInterceptorFulfilled]);

	return setupFinished;
};
