import { FC, SyntheticEvent, useState } from 'react';

import { IOrganizationDetails } from 'api/models/responses/organizations/organizationDetails';
import { IErrorResponse } from 'api/models/responses/general/errorResponse';
import OrganizationService from 'api/services/OrganizationService';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { useAsyncOptimizedCheckbox } from 'hooks/formControls/useAsyncOptimizedCheckbox';
import { useToggleSection } from 'hooks/useToggleSection';
import { usePreviewUrl } from 'hooks/usePreviewUrl';
import { useQrCode } from 'hooks/useQrCode';

import { InputGrid } from 'components/FormControls/InputGrid';
import { Checkbox } from 'components/FormControls/Checkbox';
import { ToggleSection } from 'components/ToggleSection';
import { QrCode } from 'components/QrCode';

import { requiredFieldMessage } from 'constants/general/validation/generalMessages';
import { HTTPS } from 'constants/general/secureProtocol';
import {
	orgUrlLengthLimit,
	orgNameLengthLimit,
	orgUrlRegExMessage,
	orgNameRegExMessage,
	organizationUrlRegEx,
	organizationNameRegEx,
	orgUrlMaxLengthMessage,
	orgNameMaxLengthMessage,
} from 'constants/organizations/validation';

import { validateUrlByReservedWords } from 'utils/validations/general/validateUrlByReservedWords';

import { SearchResultImageContainer } from './SearchResultImageContainer';
import { CoverImageContainer } from './CoverImageContainer';

interface IErrors {
	organizationName: string;
	organizationUrl: string;
}

const initialErrors = {
	organizationName: '',
	organizationUrl: '',
};

interface IOrganizationDetailsProps {
	organizationDetails: IOrganizationDetails | null;
	setOrganizationDetails: (organizationDetails: IOrganizationDetails) => void;
}

export const OrganizationDetails: FC<IOrganizationDetailsProps> = ({
	organizationDetails,
	setOrganizationDetails,
}) => {
	const [errors, setErrors] = useState<IErrors>(initialErrors);

	const organizationName = organizationDetails?.organizationName;
	const organizationUrl = organizationDetails?.organizationUrl;
	const shortDomain = organizationDetails?.shortUrlDomain;
	const shortUrlCode = organizationDetails?.shortUrlCode;

	const searchResultsImageRelativePath =
		organizationDetails?.searchResultsImageRelativePath;
	const coverImagePreviewRelativePath =
		organizationDetails?.coverImagePreviewRelativePath || '';

	const qrCodeUrl =
		(organizationDetails &&
			`${HTTPS}${organizationDetails.shortUrlDomain}/${organizationDetails.shortUrlCode}`) ||
		'';

	const { isOpen, handleToggle } = useToggleSection(true);
	const orgPreviewUrl = usePreviewUrl(organizationUrl);
	const qrUrl = useQrCode(qrCodeUrl);

	const organizationKey = organizationDetails?.organizationKey || 0;

	const handleOrganizationUpdate = async (
		changedValue: string | boolean,
		path: string
	) => {
		try {
			if (!organizationKey) return;

			const updatedOrganizationFields: IPatchBody = {
				path,
				value: changedValue,
				op: 'replace',
			};

			const organizationUpdateBody: IPatchBody[] = [updatedOrganizationFields];

			const data = await OrganizationService.partialOrganizationUpdate(
				organizationKey,
				organizationUpdateBody
			);

			if (!data) return;

			setOrganizationDetails(data);
		} catch (error) {
			const { errors: errorsRes } = error as IErrorResponse;

			const updatedErrorMessages = Object.entries(errorsRes).reduce<IErrors>(
				(acc, [key, messages]) => {
					const splittedKey = key.split('.');

					const parsedKey = splittedKey[splittedKey.length - 1];

					const doesKeyExist = parsedKey in errors;

					const parsedMessage = messages[0] || '';

					if (!doesKeyExist) return acc;

					return {
						...acc,
						[parsedKey]: parsedMessage,
					};
				},
				{} as IErrors
			);

			setErrors({ ...errors, ...updatedErrorMessages });
		}
	};

	const handleIsPrivateUpdate = async (isPrivate: boolean) => {
		await handleOrganizationUpdate(!isPrivate, 'isPrivate');
	};

	const isPrivate = organizationDetails?.isPrivate;

	const { checked, isPending, handleChange } = useAsyncOptimizedCheckbox(
		isPrivate === undefined ? isPrivate : !isPrivate,
		handleIsPrivateUpdate
	);

	const handleOrgNameChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		const createErrorObject = (message: string) => ({
			...errors,
			organizationName: message,
		});

		if (!value.length) {
			return setErrors(createErrorObject(requiredFieldMessage));
		}

		if (value.length > orgNameLengthLimit) {
			return setErrors(createErrorObject(orgNameMaxLengthMessage));
		}

		if (!organizationNameRegEx.test(value)) {
			return setErrors(createErrorObject(orgNameRegExMessage));
		}

		void handleOrganizationUpdate(value, 'organizationName');
	};

	const handleOrgUrlChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;

		const createErrorObject = (message: string) => ({
			...errors,
			organizationUrl: message,
		});

		if (!value.length) {
			return setErrors(createErrorObject(requiredFieldMessage));
		}

		if (value.length > orgUrlLengthLimit) {
			return setErrors(createErrorObject(orgUrlMaxLengthMessage));
		}

		if (!organizationUrlRegEx.test(value)) {
			return setErrors(createErrorObject(orgUrlRegExMessage));
		}

		const message = validateUrlByReservedWords(value);

		if (message) {
			return setErrors(createErrorObject(message));
		}

		void handleOrganizationUpdate(value, 'organizationUrl');
	};

	const clearError = (id: string) => {
		setErrors({ ...errors, [id]: '' });
	};

	const handleUploadOrganizationCoverImage = async (file: File) => {
		if (!organizationDetails) return;

		const data = await OrganizationService.setCoverImage(
			organizationDetails.organizationKey,
			file
		);

		if (!data) return;

		setOrganizationDetails({
			...organizationDetails,
			coverImagePreviewRelativePath: data,
		});
	};

	const handleDeleteOrganizationCoverImage = async () => {
		if (!organizationDetails) return;

		try {
			await OrganizationService.deleteCoverImage(organizationKey);

			setOrganizationDetails({
				...organizationDetails,
				coverImagePreviewRelativePath: '',
			});
		} catch (error) {
			console.log(error);
		}
	};

	const setSearchResultsImageRelativePath = (path: string) => {
		if (!organizationDetails) return;

		setOrganizationDetails({
			...organizationDetails,
			searchResultsImageRelativePath: path,
		});
	};

	const orgUrlSupLabel =
		(organizationDetails &&
			`${organizationDetails.studioNetworkDomain}.accessmyevent/`) ||
		'';

	return (
		<ToggleSection
			isOpen={isOpen}
			handleToggle={handleToggle}
			title="Organization Details"
			className="org-details-header details"
			header={
				<a
					target="_blank"
					rel="noreferrer"
					href={orgPreviewUrl}
					className="btn-secondary org-details-btn"
				>
					View Organization Page
				</a>
			}
		>
			<section className="org-details-section">
				<div className="org-details-form">
					<div className="org-form-fields">
						<InputGrid
							row
							isLazy
							touched
							label="Org. Name:"
							className="autorow"
							id="organizationName"
							placeholder="Org Name"
							clearError={clearError}
							defaultValue={organizationName}
							error={errors.organizationName}
							handleLazyChange={handleOrgNameChange}
						/>
						<InputGrid
							row
							isLazy
							touched
							label="Org. URL:"
							className="autorow"
							id="organizationUrl"
							placeholder="Org URL"
							clearError={clearError}
							supLabel={orgUrlSupLabel}
							defaultValue={organizationUrl}
							error={errors.organizationUrl}
							handleLazyChange={handleOrgUrlChange}
						/>
					</div>
					<div className="org-check">
						<Checkbox
							checked={checked}
							disabled={isPending}
							id="private-organization"
							handleChange={handleChange}
							label="show in consumer search results"
						/>
						<div className="org-code-form">
							<span className="label">Short URL:</span>
							<span className="org-code-link">
								{shortDomain}/<span className="org-code">{shortUrlCode}</span>
							</span>
						</div>
					</div>
					<div className="org-form-grid">
						<SearchResultImageContainer
							organizationKey={organizationKey}
							setSearchResultsImageRelativePath={
								setSearchResultsImageRelativePath
							}
							searchResultsImageRelativePath={searchResultsImageRelativePath}
						/>
						<QrCode
							qrUrl={qrUrl}
							label="Organization QR Code"
							fileName={organizationName || ''}
						/>
					</div>
				</div>
				<CoverImageContainer
					label="Organization Cover Image"
					handleDeleteImage={handleDeleteOrganizationCoverImage}
					handleUploadImage={handleUploadOrganizationCoverImage}
					coverImagePreviewRelativePath={coverImagePreviewRelativePath}
				/>
			</section>
		</ToggleSection>
	);
};
