import { FC, RefObject } from 'react';

import { SUBJECT_FORM_QUESTION_LIMIT } from 'constants/projects/validation/subjectFormQuestion';
import { Button } from 'components/FormControls/Button';

import { ISubjectFormQuestionUI, ISubjectFormQuestionsControls } from './types';
import { SubjectFormLabels } from './SubjectFormLabels';
import { SubjectFormRow } from './SubjectFormRow';

interface ISubjectFormQuestionsProps extends ISubjectFormQuestionsControls {
	title?: string;
	disabled?: boolean;
	hideBurger?: boolean;
	handleCreate: () => void;
	disabledFieldId?: string;
	createAvailable?: boolean;
	disableMultipleCreation?: boolean;
	parentRef?: RefObject<HTMLDivElement>;
	subjectFormQuestions: ISubjectFormQuestionUI[];
}

export const SubjectFormQuestions: FC<ISubjectFormQuestionsProps> = ({
	title,
	disabled,
	parentRef,
	hideBurger,
	handleSave,
	clearError,
	handleUpdate,
	handleCreate,
	deleteFormRow,
	changeSequence,
	disabledFieldId,
	createAvailable,
	subjectFormQuestions,
	disableMultipleCreation,
}) => {
	const FormRowsList = subjectFormQuestions.map(
		({
			errors,
			options,
			sequence,
			localKey,
			formQuestion,
			dataFieldType,
			isAnswerRequired,
			subjectFormQuestionKey,
		}) => (
			<SubjectFormRow
				errors={errors}
				options={options}
				sequence={sequence}
				localKey={localKey}
				disabled={disabled}
				clearError={clearError}
				formQuestion={formQuestion}
				handleUpdate={handleUpdate}
				deleteFormRow={deleteFormRow}
				dataFieldType={dataFieldType}
				changeSequence={changeSequence}
				disabledFieldId={disabledFieldId}
				isAnswerRequired={isAnswerRequired}
				key={subjectFormQuestionKey || localKey}
				subjectFormQuestionKey={subjectFormQuestionKey}
				hideBurger={hideBurger && !subjectFormQuestionKey}
			/>
		)
	);

	const subjectFormQuestionKey =
		subjectFormQuestions.length &&
		subjectFormQuestions[subjectFormQuestions.length - 1]
			.subjectFormQuestionKey;

	const showSaveButton = !subjectFormQuestionKey && disableMultipleCreation;
	const showCreateButton =
		createAvailable &&
		subjectFormQuestions.length < SUBJECT_FORM_QUESTION_LIMIT;

	return (
		<div className="project-section" ref={parentRef}>
			{title && <h2 className="project-section-title">{title}</h2>}
			<div className="project-form">
				<SubjectFormLabels />
				{FormRowsList}
			</div>
			{showCreateButton &&
				(showSaveButton && handleSave ? (
					<Button
						value="Save"
						onClick={handleSave}
						className="btn-primary project-form-btn"
					/>
				) : (
					<Button
						value="+ Add Question"
						onClick={handleCreate}
						className="btn-secondary project-form-btn"
					/>
				))}
		</div>
	);
};

SubjectFormQuestions.defaultProps = {
	createAvailable: true,
	disableMultipleCreation: false,
};
