import { FC, useEffect } from 'react';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useModalControls } from 'hooks/useModalControls';
import { useToggleSection } from 'hooks/useToggleSection';

import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { Modal } from 'components/Modal';

import {
	subscriptionSelector,
	getSubscriptionsAsync,
	setActiveSubscriptionKey,
	setInitiallyShowUpgradePlanModal,
	initiallyShowUpgradePlanModalSelector,
} from 'store/subscriptions';

import { UpgradeYourPlan } from './components/UpgradeYourPlan';
import { PlanInfoList } from './components/PlanInfoList';

export const PlanInformation: FC = () => {
	const initiallyShowUpgradePlanModalInitially = useAppSelector(
		initiallyShowUpgradePlanModalSelector
	);
	const subscription = useAppSelector(subscriptionSelector);

	const dispatch = useAppDispatch();

	const { isOpen, handleToggle } = useToggleSection(true);

	const {
		modalRef: upgradePlanModalRef,
		showModal: showUpgradePlanModal,
		hideModal: hideUpgradePlanModal,
	} = useModalControls();

	const showUpgradeModalHof = () => {
		if (!subscription) return;

		showUpgradePlanModal();
		dispatch(setActiveSubscriptionKey(subscription.subscriptionLevelKey));
	};

	useEffect(() => {
		void dispatch(getSubscriptionsAsync());
	}, []);

	useEffect(() => {
		if (!initiallyShowUpgradePlanModalInitially) return;

		showUpgradeModalHof();

		dispatch(setInitiallyShowUpgradePlanModal(false));
	}, [initiallyShowUpgradePlanModalInitially]);

	return (
		<div className="accing-toggle-section">
			<ToggleSection
				isOpen={isOpen}
				title="Plan Information"
				handleToggle={handleToggle}
				className="accing-toggle-header"
			>
				<div className="accing-wrapper">
					<div className="accing-list-wrapper">
						<PlanInfoList />
						<Button
							value="Upgrade Now"
							onClick={showUpgradeModalHof}
							className="btn-primary accing-btn"
						/>
					</div>
				</div>
			</ToggleSection>
			<Modal
				className="modal-plan"
				ref={upgradePlanModalRef}
				title="Upgrade Your Plan"
				subtitle="Choose a plan that best suits your business needs"
			>
				<UpgradeYourPlan hideUpgradePlanModal={hideUpgradePlanModal} />
			</Modal>
		</div>
	);
};
