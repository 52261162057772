import { FC } from 'react';

export const PackageOptionsHeader: FC = () => (
	<span className="price-pack-title">
		Build-Your-Own Package Options
		<span>
			Setup each Product do you want to be available in your Build-Your-Own
			Packages
		</span>
	</span>
);
