import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import cn from 'classnames';

import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';
import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';

import { fontFamilySelectOptions } from 'constants/textEditor/fontFamily';
import { fontSizeSelectOptions } from 'constants/textEditor/fontSize';

import { useToggleSection } from 'hooks/useToggleSection';
import { ISelectOption } from 'types/ui/select';

import { IAddButtonValues } from '../types';

export const AddButton: FC = () => {
	const [showFontColorPicker, setShowFontColorPicker] = useState(false);
	const [showBackgroundColorPicker, setShowBackgroundColorPicker] =
		useState(false);

	const { values, handleChange, handleSubmit, setFieldValue } =
		useFormikContext<IAddButtonValues>();

	const handleSelectFontSize = (option: SingleValue<ISelectOption<string>>) => {
		if (!option) return;

		void setFieldValue('fontSize', option.value);
	};

	const handleSelectFontFamily = (
		option: SingleValue<ISelectOption<string>>
	) => {
		if (!option) return;

		void setFieldValue('fontFamily', option.value);
	};

	const handleSelectBold = () => {
		const isBold = values.bold;

		void setFieldValue('bold', !isBold);
	};

	const handleSelectItalic = () => {
		const isItalic = values.italic;

		void setFieldValue('italic', !isItalic);
	};

	const handleSelectUnderline = () => {
		const isUnderline = values.underline;

		void setFieldValue('underline', !isUnderline);
	};

	const handleShowFontColorPicker = (e: SyntheticEvent<HTMLButtonElement>) => {
		e.stopPropagation();

		setShowFontColorPicker(true);
	};

	const handleShowBackgroundColorPicker = (e: SyntheticEvent<HTMLElement>) => {
		e.stopPropagation();

		setShowBackgroundColorPicker(true);
	};

	const handleChangeFontColor = (color: ColorResult) => {
		void setFieldValue('color', color.hex);
	};

	const handleChangeBackgroundColor = (color: ColorResult) => {
		void setFieldValue('backgroundColor', color.hex);
	};

	useEffect(() => {
		const hideColorPicker = () => {
			setShowFontColorPicker(false);
			setShowBackgroundColorPicker(false);
		};

		document.addEventListener('click', hideColorPicker);

		return () => {
			document.removeEventListener('click', hideColorPicker);
		};
	}, []);

	const { isOpen, handleToggle } = useToggleSection(false);

	return (
		<div className="org-create-btn">
			<ToggleSection
				title="Create Button"
				isOpen={isOpen}
				className=""
				handleToggle={handleToggle}
			>
				<div className="org-create-btn-form">
					<InputGrid
						row
						id="link"
						className="autorow"
						label="Button Link"
						value={values.link}
						placeholder="http://"
						handleChange={handleChange}
					/>
					<InputGrid
						row
						id="label"
						className="autorow"
						label="Button Label"
						value={values.label}
						placeholder="Button"
						handleChange={handleChange}
					/>
					<div className="org-create-btn-select">
						<SelectComponent
							id="fontFamily"
							label="Font Family"
							value={values.fontFamily}
							selectPlaceholder="Font-family"
							onChange={handleSelectFontFamily}
							selectOptions={fontFamilySelectOptions}
						/>
					</div>
				</div>
				<div className="org-create-btn-form">
					<div className="org-create-btn-select org-select-color">
						<label className="label">Button Color</label>
						<button
							className="btn-colorpicker"
							onClick={handleShowFontColorPicker}
						>
							<i style={{ backgroundColor: values.backgroundColor }} />
						</button>
						{showFontColorPicker && (
							<div
								className="org-colorpicker"
								onClick={(e) => e.stopPropagation()}
							>
								<ChromePicker
									disableAlpha
									color={values.backgroundColor}
									onChange={handleChangeBackgroundColor}
								/>
							</div>
						)}
					</div>
					<div className="org-create-btn-select fonts">
						<SelectComponent
							id="fontSize"
							label="Font Size"
							value={values.fontSize}
							selectPlaceholder="Font-size"
							onChange={handleSelectFontSize}
							selectOptions={fontSizeSelectOptions}
						/>
						<div className="org-create-btn-font">
							<b
								onClick={handleSelectBold}
								className={cn('org-btn-font', {
									'is-active': values.bold,
								})}
							>
								B
							</b>
							<i
								onClick={handleSelectItalic}
								className={cn('org-btn-font', {
									'is-active': values.italic,
								})}
							>
								I
							</i>
							<ins
								onClick={handleSelectUnderline}
								className={cn('org-btn-font', {
									'is-active': values.underline,
								})}
							>
								U
							</ins>
							<ins
								onClick={handleShowBackgroundColorPicker}
								className="org-btn-font org-colorpicker-btn"
							>
								A
							</ins>
							{showBackgroundColorPicker && (
								<div
									className="org-colorpicker"
									onClick={(e) => e.stopPropagation()}
								>
									<ChromePicker
										disableAlpha
										color={values.color}
										onChange={handleChangeFontColor}
									/>
								</div>
							)}
						</div>
					</div>
					<Button
						value="Add Button"
						className="btn-secondary"
						onClick={() => handleSubmit()}
					/>
				</div>
			</ToggleSection>
		</div>
	);
};
