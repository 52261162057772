import { BrowserRouter } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { FC, useEffect } from 'react';
import Zendesk from 'react-zendesk';

import { Router } from 'router';

export const broadcastChannel = new BroadcastChannel('oidc_logout');

export const App: FC = () => {
	const authContext = useAuth();

	const { isLoading, settings, isAuthenticated } = authContext;

	useEffect(() => {
		if (isLoading || isAuthenticated) return;

		const { pathname } = window.location;

		let prompt: string | undefined = undefined;
		let acrValues: string | undefined = undefined;
		let redirectUri: string | undefined = settings.redirect_uri;

		const lowerCasePathname = pathname.toLowerCase();

		if (lowerCasePathname == '/new-login') {
			prompt = 'create';
		}

		if (lowerCasePathname == '/accept-invite/new-login') {
			redirectUri = settings.redirect_uri.concat('accept-invite/connect');
			prompt = 'create';
			acrValues = 'from-invite';
		}

		if (lowerCasePathname == '/accept-invite/existing-login') {
			redirectUri = settings.redirect_uri.concat('accept-invite/connect');
		}

		void authContext.signinRedirect({
			prompt,
			acr_values: acrValues,
			redirect_uri: redirectUri,
		});
	}, [isLoading, isAuthenticated]);

	useEffect(() => {
		const handleMessage = async (event: MessageEvent) => {
			if (event.data === 'oidc_logged_out') {
				await authContext.signoutSilent();
				broadcastChannel.close();
			}
		};

		broadcastChannel.onmessage = handleMessage;
	}, [authContext]);

	const zendeskKey = process.env.REACT_APP_ZENDESK_KEY;

	return (
		<div className="app">
			<BrowserRouter>
				<Router />
			</BrowserRouter>
			{zendeskKey && <Zendesk defer zendeskKey={zendeskKey} />}
		</div>
	);
};
