import { FC, useEffect } from 'react';

import { useRemoveButtonFocus } from 'hooks/useRemoveButtonFocus';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useDefineDevice } from 'hooks/useDefineDevice';

import { setIsOpen as setIsOpenSidebar } from 'store/sidebar';
import { getSubscriptionAsync } from 'store/subscriptions';
import { getCurrentUserAsync } from 'store/accounts';
import {
	getStudiosAsync,
	getStudioMarketingAsync,
	getStudioPhotoSettingsAsync,
} from 'store/studio';

import { Sidebar } from './Sidebar';
import { Content } from './Content';
import { Aside } from './Aside';

export const Layout: FC = () => {
	const dispatch = useAppDispatch();

	const { isDesktop } = useDefineDevice();

	useRemoveButtonFocus();

	useEffect(() => {
		void dispatch(getStudiosAsync());
		void dispatch(getCurrentUserAsync());
		void dispatch(getSubscriptionAsync());
		void dispatch(getStudioMarketingAsync());
		void dispatch(getStudioPhotoSettingsAsync());
	}, []);

	useEffect(() => {
		if (isDesktop) return;

		dispatch(setIsOpenSidebar(false));
	}, [isDesktop]);

	return (
		<div className="page-container">
			<Sidebar />
			<Content />
			<Aside />
		</div>
	);
};
