import { SingleValue } from 'react-select';
import { FC, useEffect } from 'react';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { periodsSelectOptions } from 'constants/dashboard/periodsSelectOptions';
import { SelectComponent } from 'components/FormControls/Select';
import { Periods } from 'api/models/requests/dashboard/periods';
import { ISelectOption } from 'types/ui/select';
import {
	setSelectedPeriod,
	selectedPeriodSelector,
	getStudioStatisticAsync,
	isStudioStatisticPendingSelector,
	isMarketingStatisticPendingSelector,
} from 'store/dashboard';

export const DateRangeSelect: FC = () => {
	const selectedPeriod = useAppSelector(selectedPeriodSelector);
	const isMarketingStatisticPending = useAppSelector(
		isMarketingStatisticPendingSelector
	);
	const isStudioStatisticPending = useAppSelector(
		isStudioStatisticPendingSelector
	);

	const dispatch = useAppDispatch();

	const handleChange = (option: SingleValue<ISelectOption<Periods>>) => {
		if (!option) return;

		dispatch(setSelectedPeriod(option.value));
	};

	useEffect(() => {
		if (!selectedPeriod) return;

		void dispatch(getStudioStatisticAsync(selectedPeriod));
	}, [selectedPeriod]);

	const disabled = isMarketingStatisticPending || isStudioStatisticPending;

	return (
		<div className="data-control">
			<label htmlFor="dateRange" className="data-control-label">
				Stats overview for
			</label>
			<SelectComponent
				id="period"
				disabled={disabled}
				value={selectedPeriod}
				onChange={handleChange}
				selectOptions={periodsSelectOptions}
			/>
		</div>
	);
};
