import { forwardRef, ButtonHTMLAttributes, ReactNode } from 'react';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	children?: ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
	(props, buttonRef) => {
		const { value, children, ...restProps } = props;

		return (
			<button {...restProps} ref={buttonRef}>
				{value}
				{children}
			</button>
		);
	}
);

Button.displayName = 'Button';

Button.defaultProps = {
	type: 'button',
};
