import { FC } from 'react';

import { Button } from 'components/FormControls/Button';

interface IPriceListControlsProps {
	handleBack: () => void;
	handleNext?: () => void;
	showNextBtn?: boolean;
}

export const PriceListControls: FC<IPriceListControlsProps> = ({
	handleBack,
	handleNext,
	showNextBtn,
}) => (
	<div className="price-controls">
		<Button className="btn-secondary" value="Back" onClick={handleBack} />
		{showNextBtn && (
			<Button className="btn-primary" value="Next" onClick={handleNext} />
		)}
	</div>
);

PriceListControls.defaultProps = {
	showNextBtn: true,
};
