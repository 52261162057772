import { FC } from 'react';

import { usePasswordInput } from 'hooks/formControls/usePasswordInput';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { InputGrid } from 'components/FormControls/InputGrid';
import { bankACHSelector } from 'store/studioBilling';

export const BankInformationList: FC = () => {
	const bankACH = useAppSelector(bankACHSelector);

	const routingInputControls = usePasswordInput();
	const accountInputControls = usePasswordInput();

	return (
		<ul className="accing-field-list">
			<li className="accing-field-item">
				<InputGrid
					row
					isLazy
					readOnly
					required={false}
					label="Routing #"
					type={routingInputControls.inputType}
					defaultValue={bankACH?.bankRoutingNumber}
				/>
				<span
					className="acc-toggle-btn"
					onClick={routingInputControls.handleShowValue}
				>
					{routingInputControls.toggleValue}
				</span>
			</li>
			<li className="accing-field-item">
				<InputGrid
					row
					isLazy
					readOnly
					required={false}
					label="Account #"
					type={accountInputControls.inputType}
					defaultValue={bankACH?.bankAccountNumberLastFour}
				/>
				<span className="accing-field-info">
					Account# field shows only 4 last digits
				</span>
				<span
					className="acc-toggle-btn"
					onClick={accountInputControls.handleShowValue}
				>
					{accountInputControls.toggleValue}
				</span>
			</li>
		</ul>
	);
};
