import { FC } from 'react';

import { Button } from 'components/FormControls/Button';

interface IConfirmDeleteProps {
	hideConfirmDeleteModal: () => void;
	handlePermanentlyDelete: () => Promise<void> | void;
}

export const ConfirmDelete: FC<IConfirmDeleteProps> = ({
	hideConfirmDeleteModal,
	handlePermanentlyDelete,
}) => (
	<div className="org-modal-confirm">
		<Button
			className="btn-secondary"
			value="Permanently Delete"
			onClick={() => void handlePermanentlyDelete()}
		/>
		<Button
			value="Cancel"
			className="btn-primary"
			onClick={hideConfirmDeleteModal}
		/>
	</div>
);
