import { useFormikContext } from 'formik';
import { FC, useEffect } from 'react';

import { BroadcastEmail } from 'components/Broadcast/BroadcastEmail';
import { TextEditorRef } from 'components/TextEditor/types';

import { IGeneralBroadcastProps, ISendBroadcastValues } from '../../types';

interface IEmailSectionProps extends IGeneralBroadcastProps {
	editorRef: TextEditorRef;
}

export const EmailSection: FC<IEmailSectionProps> = ({
	editorRef,
	broadcastInfo,
}) => {
	const { values, errors, touched, setErrors, handleChange, setFieldValue } =
		useFormikContext<ISendBroadcastValues>();

	const clearEmailBodyError = () => {
		setErrors({
			...errors,
			emailMessage: '',
		});
	};

	useEffect(() => {
		if (values.sendByEmail) return;

		void setFieldValue('emailSubject', '');
		void setFieldValue('emailMessage', '');
		void setFieldValue('sendToEmailAddress', '');

		clearEmailBodyError();
	}, [values.sendByEmail]);

	const emailMessageError =
		errors.emailMessage && touched.emailMessage ? errors.emailMessage : '';

	return (
		<BroadcastEmail
			editorRef={editorRef}
			handleChange={handleChange}
			replyTo={broadcastInfo?.replyTo}
			emailSubject={values.emailSubject}
			emailBodyError={emailMessageError}
			emailFrom={broadcastInfo?.emailFrom}
			senderName={broadcastInfo?.senderName}
			emailSubjectError={errors.emailSubject}
			clearEmailBodyError={clearEmailBodyError}
			emailSubjectTouched={touched.emailSubject}
			disabledEmailSubject={!values.sendByEmail}
		/>
	);
};
