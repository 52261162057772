import { useFormikContext } from 'formik';
import { FC, useEffect } from 'react';

import { Checkbox } from 'components/FormControls/Checkbox';
import { inHourTimeline } from 'utils/dates/inHourTimeline';
import {
	endDayHour,
	startDayHour,
} from 'constants/general/dates/dayHoursLimit';

import { IGeneralBroadcastProps, ISendBroadcastValues } from '../../types';
import { timeZonesMoment } from 'constants/studio/timeZonesMoment';

type FieldKeys = keyof Pick<
	ISendBroadcastValues,
	'buyers' | 'leads' | 'prospects'
>;

interface ICheckbox {
	id: FieldKeys;
	label: string;
}

const checkboxesGroup: ICheckbox[] = [
	{
		id: 'prospects',
		label: 'Prospects',
	},
	{
		id: 'leads',
		label: 'Leads',
	},
	{
		id: 'buyers',
		label: 'Buyers',
	},
];

export const BroadcastHeader: FC<IGeneralBroadcastProps> = ({
	isPending,
	broadcastInfo,
}) => {
	const { values, setFieldValue, handleChange } =
		useFormikContext<ISendBroadcastValues>();

	const disabledGroup: Record<FieldKeys, boolean> = {
		leads: !!(!broadcastInfo?.hasAnyLeads || isPending),
		buyers: !!(!broadcastInfo?.hasAnyBuyers || isPending),
		prospects: !!(!broadcastInfo?.hasAnyProspects || isPending),
	};

	const textMessageInTimeline = inHourTimeline(
		startDayHour,
		endDayHour,
		broadcastInfo ? timeZonesMoment[broadcastInfo.timeZone] : undefined
	);

	const disabledSendByPhone =
		!broadcastInfo?.hasVerifiedPhoneNumber || !textMessageInTimeline;

	const CheckboxesGroupList = checkboxesGroup.map(({ id, label }) => (
		<Checkbox
			id={id}
			key={id}
			label={label}
			checked={values[id]}
			handleChange={handleChange}
			disabled={disabledGroup[id]}
		/>
	));

	useEffect(() => {
		if (values.sendByPhone) return;

		void setFieldValue('textMessage', '');
		void setFieldValue('sendToPhoneNumber', '');
	}, [values.sendByPhone]);

	return (
		<div className="broadcast-header">
			<h4 className="broadcast-header-title">Send Special Message to</h4>
			<h4 className="broadcast-header-title">Send Special Message by</h4>
			<div className="broadcast-list">{CheckboxesGroupList}</div>
			<div className="broadcast-list">
				<Checkbox
					label="Email"
					id="sendByEmail"
					handleChange={handleChange}
					checked={values.sendByEmail}
				/>
				<Checkbox
					id="sendByPhone"
					label="Text Message"
					handleChange={handleChange}
					checked={values.sendByPhone}
					disabled={disabledSendByPhone}
					suplabel="Only allowed between 9AM-9PM"
				/>
			</div>
		</div>
	);
};
