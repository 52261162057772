import { IGeneralStatistic } from 'api/models/responses/general/generalStatistic';

export const initialGeneralStatistic: IGeneralStatistic = {
	people: 0,
	images: 0,
	orders: 0,
	revenue: 0,
	timestamp: '',
	averageOrder: 0,
	revenuePerPerson: 0,
};
