import { FC } from 'react';

import { Button } from 'components/FormControls/Button';

interface IControlBtnColumnProps {
	disabled: boolean;
	showSaveBtn: boolean;
	handleAdd: () => void;
	handleSave: () => Promise<void>;
}

export const ControlBtnColumn: FC<IControlBtnColumnProps> = ({
	disabled,
	handleAdd,
	handleSave,
	showSaveBtn,
}) => {
	const createPackageBtnValue = showSaveBtn
		? 'Save Form Question'
		: '+ Add Form Question';

	const createPackageBtnClassName = showSaveBtn
		? 'btn-primary'
		: 'btn-secondary';

	const createPackageBtnHandleClick = showSaveBtn ? handleSave : handleAdd;

	return (
		<tr>
			<td colSpan={2}>
				<Button
					disabled={disabled}
					value={createPackageBtnValue}
					onClick={createPackageBtnHandleClick}
					className={`price-question-btn ${createPackageBtnClassName}`}
				/>
			</td>
		</tr>
	);
};
