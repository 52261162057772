import { FC, useCallback, useEffect } from 'react';
import { CellProps, Column } from 'react-table';
import { Link } from 'react-router-dom';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { IOrder } from 'api/models/responses/orders/order';
import OrdersService from 'api/services/OrdersService';

import { normalizeDigit } from 'utils/ui/normalizeDigit';
import { normalizeDate } from 'utils/ui/normalizeDate';

import { Table } from 'components/Table';
import { Card } from 'components/Card';

import { setLatestOrders, latestOrdersSelector } from 'store/dashboard';
import { salesUI } from 'constants/projects/salesUI';

export const NewOrders: FC = () => {
	const latestOrders = useAppSelector(latestOrdersSelector);

	const dispatch = useAppDispatch();

	const getLatestOrders = useCallback(async () => {
		try {
			const data = await OrdersService.getLatestOrders();

			dispatch(setLatestOrders(data));
		} catch (error) {
			console.log(error);
		}
	}, []);

	useEffect(() => {
		void getLatestOrders();
	}, [getLatestOrders]);

	const columns: Column<IOrder>[] = [
		{
			Header: 'Date',
			accessor: 'orderDate',
			Cell: ({ value }: CellProps<IOrder, IOrder['orderDate']>) => (
				<>{normalizeDate(value, 'MM/DD')}</>
			),
		},
		{
			Header: 'Project',
			accessor: 'projectName',
		},
		{
			Header: <div className="tr-left">Sale</div>,
			accessor: 'saleType',
			Cell: ({ value }: CellProps<IOrder, IOrder['saleType']>) => {
				const { title, shortTitle } = salesUI[value];

				return <div className="td-left">{shortTitle ?? title}</div>;
			},
		},
		{
			Header: <div className="tr-left">Customer</div>,
			accessor: 'customerName',
			Cell: ({ value }: CellProps<IOrder, IOrder['customerName']>) => (
				<div className="td-left">{value}</div>
			),
		},
		{
			Header: 'Order #',
			accessor: 'orderKey',
			Cell: ({ value }: CellProps<IOrder, IOrder['orderKey']>) => (
				// TODO: add correct route to order
				<Link
					to={`/${value}`}
					title={value.toString()}
					className="card-table-link"
				>
					#{value}
				</Link>
			),
		},
		{
			Header: <div className="tr-right">Revenue</div>,
			accessor: 'wholesaleTotal',
			Cell: ({ value }: CellProps<IOrder, IOrder['wholesaleTotal']>) => (
				<div className="td-right">
					{normalizeDigit({ value, isPrice: true, minimumFractionDigits: 2 })}
				</div>
			),
		},
	];

	return (
		<Card
			columnFit="col-2"
			title="New Orders"
			bodyClassName="card-table"
			subtitle="(10 most recent orders) "
		>
			<Table
				className="card-orders-table"
				columns={columns}
				data={latestOrders.results}
			/>
		</Card>
	);
};
