import { FC } from 'react';

import { Button } from 'components/FormControls/Button';
import { Confetti } from 'components/Confetti';
import { Portal } from 'components/Portal';

interface ISuccessCreateProjectProps {
	url: string;
	hideSuccessCreateProjectModal: () => void;
}

export const SuccessCreateProject: FC<ISuccessCreateProjectProps> = ({
	url,
	hideSuccessCreateProjectModal,
}) => {
	const handleCopyUrl = () => {
		void navigator.clipboard.writeText(url);
	};

	return (
		<>
			<span className="project-modal-created">{url}</span>
			<div className="project-modal-created-btn">
				<Button
					value="Copy URL"
					className="btn-secondary"
					onClick={handleCopyUrl}
				/>
				<Button
					value="OK"
					className="btn-primary"
					onClick={hideSuccessCreateProjectModal}
				/>
			</div>
			<Portal>
				<Confetti />
			</Portal>
		</>
	);
};
