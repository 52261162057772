import { FC, useCallback, useEffect, useState } from 'react';

import { watermarkImagePreviews } from 'constants/studioPhotoSettings/watermarkImagePreviews';
import { watermarkTypesUI } from 'constants/studioPhotoSettings/watermarkTypesUI';
import { photosBlobStorageLowResUrl } from 'constants/images/blobUrls';

import { CloudPhotoTypes } from 'api/models/responses/photos/cloudPhotoTypes';
import PhotoService from 'api/services/PhotoService';

import { Agreement } from 'components/Modal/components/Agreement';
import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { Modal } from 'components/Modal';

import { getPhotoBlobUrl } from 'utils/photos/getPhotoBlobUrl';
import { prepareSasUrl } from 'utils/photos/prepareSasUrl';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useModalControls } from 'hooks/useModalControls';
import { useToggleSection } from 'hooks/useToggleSection';

import {
	watermarkTypeSelector,
	disabledWatermarkSelector,
	customWatermarkImageGuidByTypeSelector,
	defaultWatermarkImageGuidByTypeSelector,
} from 'store/studio';

import { EditWatermark } from '../EditWatermark';

export interface IWatermarkError {
	message: string;
}

export const WatermarkSection: FC = () => {
	const [defaultWatermarkUrl, setDefaultWatermarkUrl] = useState('');
	const [customWatermarkUrl, setCustomWatermarkUrl] = useState('');

	const [updateWatermarkTimeLimitError, setUpdateWatermarkTimeLimitError] =
		useState<IWatermarkError>({ message: '' });

	const [isDefaultWatermarkPending, setIsDefaultWatermarkPending] =
		useState(false);
	const [isCustomWatermarkPending, setIsCustomWatermarkPending] =
		useState(false);

	const disabledWatermark = useAppSelector(disabledWatermarkSelector);
	const watermarkType = useAppSelector(watermarkTypeSelector);
	const defaultWatermarkImageGuidByType = useAppSelector(
		defaultWatermarkImageGuidByTypeSelector
	);
	const customWatermarkImageGuidByType = useAppSelector(
		customWatermarkImageGuidByTypeSelector
	);

	const {
		modalRef: rejectUpdateModalRef,
		showModal: showRejectUpdateModal,
		hideModal: hideRejectUpdateModal,
	} = useModalControls();

	const {
		modalRef: updateModalRef,
		showModal: showUpdateModal,
		hideModal: hideUpdateModal,
	} = useModalControls();

	const { isOpen, handleToggle } = useToggleSection(true);

	const defaultWatermarkGuid = defaultWatermarkImageGuidByType[watermarkType];
	const customWatermarkGuid = customWatermarkImageGuidByType[watermarkType];

	const getCustomWatermarkUrl = useCallback(async () => {
		if (!customWatermarkGuid) {
			setCustomWatermarkUrl('');
			return;
		}

		setIsCustomWatermarkPending(true);

		try {
			const customWatermarkSasUrl = await PhotoService.getImageSasUrl(
				customWatermarkGuid,
				CloudPhotoTypes.Service
			);

			const preparedSasUrl = prepareSasUrl(
				CloudPhotoTypes.Service,
				customWatermarkSasUrl
			);

			const url = getPhotoBlobUrl(photosBlobStorageLowResUrl, preparedSasUrl);

			setCustomWatermarkUrl(url);
			setIsCustomWatermarkPending(false);
		} catch (error) {
			console.log(error);
		}
	}, [customWatermarkGuid]);

	const getDefaultWatermarkUrl = useCallback(async () => {
		if (!defaultWatermarkGuid) return;

		setIsDefaultWatermarkPending(true);

		try {
			const defaultWatermarkSasUrl = await PhotoService.getImageSasUrl(
				defaultWatermarkGuid,
				CloudPhotoTypes.Service
			);

			const preparedSasUrl = prepareSasUrl(
				CloudPhotoTypes.Service,
				defaultWatermarkSasUrl
			);

			const url = getPhotoBlobUrl(photosBlobStorageLowResUrl, preparedSasUrl);

			setDefaultWatermarkUrl(url);
			setIsDefaultWatermarkPending(false);
		} catch (error) {
			console.log(error);
		}
	}, [defaultWatermarkGuid]);

	const handleAgreeUpdateWatermarkReject = () => {
		hideRejectUpdateModal();
		setUpdateWatermarkTimeLimitError({ message: '' });
	};

	useEffect(() => {
		void getCustomWatermarkUrl();
	}, [getCustomWatermarkUrl]);

	useEffect(() => {
		void getDefaultWatermarkUrl();
	}, [getDefaultWatermarkUrl]);

	useEffect(() => {
		if (!updateWatermarkTimeLimitError.message) return;

		showRejectUpdateModal();
	}, [updateWatermarkTimeLimitError]);

	const watermarkUrl = customWatermarkUrl || defaultWatermarkUrl;
	const isWatermarkPending =
		isCustomWatermarkPending || isDefaultWatermarkPending;

	return (
		<>
			<ToggleSection
				isOpen={isOpen}
				title="Watermark"
				handleToggle={handleToggle}
				className="watermark-img-section"
			>
				<div className="watermark">
					<img
						alt="watermark"
						className="watermark-img"
						src={watermarkImagePreviews[watermarkType]}
					/>
					<ul className="watermark-img-list">
						<b>Watermark Images:</b>
						<span>{disabledWatermark ? 'No' : 'Yes'}</span>
						{!disabledWatermark && (
							<>
								<b>Show Watermark:</b>
								<span>{watermarkTypesUI[watermarkType]}</span>
								<span className="watermark-img-size">
									<b>Watermark:</b>(500x500px)
								</span>
								{isWatermarkPending ? (
									<div className="loader-container">
										<div className="loader" />
									</div>
								) : (
									<div
										style={{ background: '#C0C0C0' }}
										className="media-watermark-mark"
									>
										<img
											alt="watermark"
											src={watermarkUrl}
											className="media-watermark-mark"
										/>
									</div>
								)}
							</>
						)}
					</ul>
					<Button
						value="Update Watermark"
						onClick={showUpdateModal}
						className="btn-secondary watermark-img-btn"
					/>
				</div>
			</ToggleSection>
			<Modal title="Edit Watermark" ref={updateModalRef}>
				<EditWatermark
					hideUpdateModal={hideUpdateModal}
					initialWatermarkType={watermarkType}
					customWatermarkUrl={customWatermarkUrl}
					defaultWatermarkUrl={defaultWatermarkUrl}
					initialDisabledWatermark={disabledWatermark}
					setUpdateWatermarkTimeLimitError={setUpdateWatermarkTimeLimitError}
				/>
			</Modal>
			<Modal
				ref={rejectUpdateModalRef}
				title="Update watermark time limit."
				subtitle={updateWatermarkTimeLimitError.message}
			>
				<Agreement handleAgreement={handleAgreeUpdateWatermarkReject} />
			</Modal>
		</>
	);
};
