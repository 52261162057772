import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import { FC } from 'react';

import { projectJourneysSelectOptions } from 'constants/projects/selectOptions/projectJourneysSelectOptions';
import { projectTypesSelectOptions } from 'constants/projects/selectOptions/projectTypesSelectOptions';

import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

import { ICreateJourneyPresetValues } from 'store/journeyPresets/types';
import { ISelectOption } from 'types/ui/select';

export const JourneyPresetForm: FC = () => {
	const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
		useFormikContext<ICreateJourneyPresetValues>();

	const handleChangeSelect = <T,>(
		id: string,
		option: SingleValue<ISelectOption<T>>
	) => {
		if (!option) return;

		void setFieldValue(id, option.value);
	};

	return (
		<form className="project-blank-form">
			<InputGrid
				required
				id="journeyName"
				label="Preset Name"
				placeholder="Preset name"
				value={values.journeyName}
				handleChange={handleChange}
				touched={!!touched.journeyName}
				error={errors.journeyName || ''}
			/>
			<div className="input-form-grid">
				<SelectComponent
					id="projectType"
					label="Project Type"
					value={values.projectType}
					className="select-required"
					error={errors.projectType}
					touched={!!touched.projectType}
					selectPlaceholder="Project type"
					selectOptions={projectTypesSelectOptions}
					onChange={(option) => handleChangeSelect('projectType', option)}
				/>
			</div>
			<div className="input-form-grid">
				<SelectComponent
					id="journey"
					value={values.journey}
					label="Project Journey"
					error={errors.journey}
					className="select-required"
					touched={!!touched.journey}
					selectPlaceholder="Project Journey"
					selectOptions={projectJourneysSelectOptions}
					onChange={(option) => handleChangeSelect('journey', option)}
				/>
				<span className="sublabel-grid">This cannot be changed later</span>
			</div>
			<Button
				value="Continue"
				className="btn-primary"
				onClick={() => handleSubmit()}
			/>
		</form>
	);
};
