import { FC, useState, useEffect, useCallback, ChangeEvent } from 'react';

import { ProjectJourneys } from 'api/models/requests/projects/projectJourneys';
import { IProject } from 'api/models/responses/projects/projectDetails';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import ProjectsService from 'api/services/ProjectsService';

import { Agreement } from 'components/Modal/components/Agreement';
import { Checkbox } from 'components/FormControls/Checkbox';
import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { Modal } from 'components/Modal';

import { useModalControls } from 'hooks/useModalControls';
import { useToggleSection } from 'hooks/useToggleSection';

import { IUpdateTimeline } from 'pages/Projects/types';

import { SendBroadcast } from './Marketing/components/SendBroadcast';
import { Marketing } from './Marketing';

export interface IMarketingContainerProps extends IUpdateTimeline {
	projectKey: number;
	journey?: ProjectJourneys;
	accessCodeMessage: string;
	smsSpecialMessage: string;
	emailSpecialMessage: string;
	isMarketingEnabled?: boolean;
	hasAnySentBroadcast: boolean;
	setProjectDetails: (projectDetails: IProject) => void;
}

export const MarketingContainer: FC<IMarketingContainerProps> = ({
	journey,
	projectKey,
	accessCodeMessage,
	smsSpecialMessage,
	setProjectDetails,
	isMarketingEnabled,
	emailSpecialMessage,
	hasAnySentBroadcast,
	setIsUpdateTimeline,
}) => {
	const [isMarketingEnabledState, setIsMarketingEnabledState] =
		useState(isMarketingEnabled);

	const [sentToNumberOfPeople, setSentToNumberOfPeople] = useState(0);

	const [isPartialUpdatePending, setIsPartialUpdatePending] = useState(false);

	const { isOpen, handleToggle } = useToggleSection(false);

	const {
		modalRef: infoModalRef,
		showModal: showInfoModal,
		hideModal: hideInfoModal,
	} = useModalControls();

	const {
		modalRef: confirmModalRef,
		showModal: showConfirmModal,
		hideModal: hideConfirmModal,
	} = useModalControls();

	const {
		modalRef: sendSpecialBroadcastModalRef,
		showModal: showSendSpecialBroadcastModal,
		hideModal: hideSendSpecialBroadcastModal,
	} = useModalControls();

	const {
		modalRef: specialBroadcastRejectModalRef,
		showModal: showSpecialBroadcastRejectModal,
		hideModal: hideSpecialBroadcastRejectModal,
	} = useModalControls();

	const {
		modalRef: successSentSpecialBroadcastModal,
		showModal: showSuccessSentSpecialBroadcastModal,
		hideModal: hideSuccessSentSpecialBroadcastModal,
	} = useModalControls();

	const updateIsMarketingEnabled = useCallback(
		(isMarketingEnabledParam: boolean) => {
			if (!projectKey) return;

			setIsPartialUpdatePending(true);

			const updatedProjectFields: IPatchBody = {
				op: 'replace',
				path: 'isMarketingEnabled',
				value: isMarketingEnabledParam,
			};

			const projectUpdateBody: IPatchBody[] = [updatedProjectFields];

			ProjectsService.partialProjectUpdate(projectKey, projectUpdateBody)
				.then((data) => {
					if (!data) return;

					setProjectDetails(data);
					setIsPartialUpdatePending(false);

					if (hasAnySentBroadcast) {
						showInfoModal();
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		[projectKey, hasAnySentBroadcast]
	);

	const handleIsMarketingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.currentTarget;

		if (hasAnySentBroadcast && isMarketingEnabledState) {
			showConfirmModal();
			return;
		}

		setIsMarketingEnabledState(checked);
	};

	const handleConfirm = () => {
		updateIsMarketingEnabled(false);

		hideConfirmModal();
	};

	const disabledMarketingToggle =
		(!isMarketingEnabledState && hasAnySentBroadcast) || isPartialUpdatePending;

	const sentBroadcastInfo = `Sending broadcast to ${sentToNumberOfPeople} ${
		sentToNumberOfPeople > 1 ? 'people' : 'person'
	}`;

	useEffect(() => {
		if (
			isMarketingEnabled === undefined ||
			isMarketingEnabled === isMarketingEnabledState
		)
			return;

		setIsMarketingEnabledState(isMarketingEnabled);
	}, [isMarketingEnabled]);

	useEffect(() => {
		if (
			hasAnySentBroadcast ||
			isMarketingEnabledState === undefined ||
			isMarketingEnabled === isMarketingEnabledState
		)
			return;

		updateIsMarketingEnabled(isMarketingEnabledState);
	}, [isMarketingEnabledState, updateIsMarketingEnabled]);

	return (
		<>
			<ToggleSection
				isOpen={isOpen}
				title="Marketing"
				handleToggle={handleToggle}
				className="preset-details-header"
				header={
					<div className="preset-header-double">
						<Checkbox
							id="isMarketingEnabled"
							disabled={disabledMarketingToggle}
							checked={!!isMarketingEnabledState}
							label="Enable Marketing For This Project"
							handleChange={handleIsMarketingEnabledChange}
						/>
						<Button
							value="Send Special Broadcast"
							disabled={!isMarketingEnabled}
							onClick={showSendSpecialBroadcastModal}
							className="btn-secondary org-details-btn"
						/>
					</div>
				}
			>
				<Marketing
					journey={journey}
					projectKey={projectKey}
					accessCodeMessage={accessCodeMessage}
					smsSpecialMessage={smsSpecialMessage}
					setProjectDetails={setProjectDetails}
					isMarketingEnabled={isMarketingEnabled}
					emailSpecialMessage={emailSpecialMessage}
					setIsUpdateTimeline={setIsUpdateTimeline}
				/>
			</ToggleSection>
			{/* TODO: add styles for modal children */}
			<Modal
				title="Are you sure?"
				ref={confirmModalRef}
				subtitle="Disabling marketing is immediate and cannot be undone. Once disabled, you will not be able to turn marketing back on."
			>
				<div>
					<Button
						onClick={handleConfirm}
						className="btn-secondary"
						value="I'm sure. Disable marketing forever."
					/>
					<Button
						className="btn-primary"
						onClick={hideConfirmModal}
						value="Keep Marketing Enabled"
					/>
				</div>
			</Modal>
			{/* TODO: add styles for modal children */}
			<Modal
				ref={infoModalRef}
				title="Marketing Disabled"
				subtitle="All marketing functions have been disabled. No more marketing messages will be sent. Only order notifications and download notifications will be sent."
			>
				<Button value="Close" className="btn-primary" onClick={hideInfoModal} />
			</Modal>
			<Modal
				className="modal-broadcast"
				title="Send a Special Message"
				ref={sendSpecialBroadcastModalRef}
				subtitle="Send a one-time message to the people in your marketing funnel"
			>
				<SendBroadcast
					projectKey={projectKey}
					showSuccessSentSpecialBroadcastModal={
						showSuccessSentSpecialBroadcastModal
					}
					setSentToNumberOfPeople={setSentToNumberOfPeople}
					hideSendBroadcastModal={hideSendSpecialBroadcastModal}
					showSpecialBroadcastRejectModal={showSpecialBroadcastRejectModal}
				/>
			</Modal>
			<Modal
				title="Sending your Broadcast"
				ref={successSentSpecialBroadcastModal}
				subtitle="This broadcast has been added to the queue and will be sending out shortly. It is OK to close this message."
			>
				{/* TODO: add class name  */}
				<h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
					{sentBroadcastInfo}
				</h2>
				<Button
					value="Close"
					className="btn-primary"
					onClick={hideSuccessSentSpecialBroadcastModal}
				/>
			</Modal>
			<Modal
				ref={specialBroadcastRejectModalRef}
				title="Marketing currently is disabled for this project!"
			>
				<Agreement handleAgreement={hideSpecialBroadcastRejectModal} />
			</Modal>
		</>
	);
};
