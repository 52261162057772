import { FC } from 'react';

import { currentUserSelector, isAssistantRoleSelector } from 'store/accounts';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { useAccUserBreadcrumbs } from './hooks/useAccUserBreadcrumbs';
import { AccountSettings } from './components/AccountSettings';
import { UserSettings } from './components/UserSettings';
import { ManageTeam } from './components/ManageTeam';

export const AccountUser: FC = () => {
	const isAssistantRole = useAppSelector(isAssistantRoleSelector);
	const currentUser = useAppSelector(currentUserSelector);

	useAccUserBreadcrumbs();

	if (!currentUser) return null;

	return (
		<div className="acc-user-container">
			<UserSettings />
			{!isAssistantRole && <AccountSettings />}
			{!isAssistantRole && <ManageTeam />}
		</div>
	);
};
