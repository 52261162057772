import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';
import moment, { utc } from 'moment';
import { Formik } from 'formik';
import { lazy } from 'yup';

import { ICreateBroadcastBody } from 'api/models/requests/broadcast/createBroadcast';
import { ICampaignDetails } from 'api/models/responses/campaigns/campaignDetails';
import CampaignsService from 'api/services/CampaignsService';
import BroadcastService from 'api/services/BroadcastService';
import {
	IBroadcast,
	ProjectDateTypes,
} from 'api/models/responses/broadcasts/broadcast';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useToastify } from 'hooks/useToastify';

import { studioMarketingSelector } from 'store/studio';
import { IBreadcrumbs } from 'store/breadcrumbs';
import {
	setCampaignKey,
	campaignKeySelector,
	setCopiedBroadcastKey,
	copiedBroadcastKeySelector,
} from 'store/broadcasts';

import { useCampaignsBreadcrumbs } from 'pages/Campaigns/hooks/useCampaignsBreadcrumbs';
import { HHMMA } from 'utils/dates/timeFormats';
import { Loader } from 'components/Loader';
import { ROUTES } from 'constants/ROUTES';

import { getBroadcastValidationSchema } from './validation';
import { BroadcastForm } from './BroadcastForm';

export interface IBroadcastValues
	extends Omit<ICreateBroadcastBody, 'time' | 'days' | 'specialDate'> {
	days: string;
	time: Date | null;
	specialDate: Date | null;
}

export const BroadcastDetails: FC = () => {
	const [broadcastDetails, setBroadcastDetails] = useState<IBroadcast | null>(
		null
	);
	const [isBroadcastPending, setIsBroadcastPending] = useState(false);

	const [campaignDetails, setCampaignDetails] =
		useState<ICampaignDetails | null>(null);
	const [isCampaignPending, setIsCampaignPending] = useState(false);

	const copiedBroadcastKey = useAppSelector(copiedBroadcastKeySelector);
	const studioMarketing = useAppSelector(studioMarketingSelector);
	const createCampaignKey = useAppSelector(campaignKeySelector);

	const editorRef = useRef<CKEditor<Editor>>(null);

	const { showSuccessToastify } = useToastify();

	const dispatch = useAppDispatch();
	const { broadcastId } = useParams();
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const isCreateBroadcast = !broadcastId;

	const editCampaignKey = broadcastDetails?.campaignKey || 0;

	const campaignDetailsBreadcrumbs: IBreadcrumbs | null = campaignDetails && {
		isActive: isCreateBroadcast,
		title: campaignDetails.name,
		path: `${ROUTES.CAMPAIGNS.CAMPAIGNS}/${campaignDetails.campaignKey}`,
	};

	const broadcastDetailsBreadcrumbs: IBreadcrumbs | null = broadcastDetails && {
		path: pathname,
		isActive: true,
		title: broadcastDetails.broadcastName,
	};

	useCampaignsBreadcrumbs(
		campaignDetailsBreadcrumbs,
		isCreateBroadcast ? null : broadcastDetailsBreadcrumbs
	);

	const requestCampaignKey = createCampaignKey || editCampaignKey;

	const getCampaignDetails = useCallback(async () => {
		if (!requestCampaignKey || campaignDetails) return;

		setIsCampaignPending(true);

		try {
			const data = await CampaignsService.getCampaign(requestCampaignKey);

			setCampaignDetails(data);
		} catch (error) {
			console.log(error);
		} finally {
			setIsCampaignPending(false);
		}
	}, [campaignDetails, requestCampaignKey]);

	useEffect(() => {
		void getCampaignDetails();
	}, [getCampaignDetails]);

	const getBroadcastDetails = useCallback(async () => {
		const broadcastKey = (broadcastId && +broadcastId) || copiedBroadcastKey;

		if (!broadcastKey || broadcastDetails) return;

		setIsBroadcastPending(true);

		try {
			const data = await BroadcastService.getBroadcast(broadcastKey);

			setBroadcastDetails(data);
		} catch (error) {
			console.log(error);
		} finally {
			setIsBroadcastPending(false);
		}
	}, [broadcastId, broadcastDetails, copiedBroadcastKey]);

	useEffect(() => {
		void getBroadcastDetails();
	}, [getBroadcastDetails]);

	const showLoader = isBroadcastPending || isCampaignPending;

	if (showLoader) return <Loader isFullHeight />;

	if (!broadcastDetails || !campaignDetails || !studioMarketing) return null;

	const { emailSenderName, twillioPhoneNumber, replyToEmailAddress } =
		studioMarketing;

	const { campaignKey, campaignTarget } = campaignDetails;

	const {
		days,
		time,
		replyTo,
		emailFrom,
		emailBody,
		condition,
		smsMessage,
		senderName,
		isTemplate,
		specialDate,
		phoneNumber,
		emailSubject,
		broadcastName,
		projectDateType,
	} = broadcastDetails;

	const parsedSpecialDate = specialDate ? utc(specialDate).toDate() : null;
	const parsedTime = time && moment(time, HHMMA).toDate();

	const initialBroadcastValues: IBroadcastValues = {
		broadcastName,
		days: days.toString(),
		time: parsedTime || null,
		condition,
		textMessage: smsMessage,
		specialDate: parsedSpecialDate,
		emailMessage: emailBody,
		emailSubject,
		projectDateType,
	};

	const onSubmit = async (values: IBroadcastValues) => {
		if (!campaignDetails) return;

		const { specialDate: specialDateValue, ...restValues } = values;

		const timeString = moment(restValues.time).format(HHMMA);

		const body: ICreateBroadcastBody = {
			...restValues,
			time: timeString,
			days: +restValues.days,
		};

		if (
			values.projectDateType === ProjectDateTypes.SpecialDate &&
			specialDateValue
		) {
			body.specialDate = utc(specialDateValue).toISOString();
		}

		try {
			if (isCreateBroadcast) {
				await BroadcastService.createBroadcast(requestCampaignKey, body);

				dispatch(setCampaignKey(null));
				dispatch(setCopiedBroadcastKey(null));
				showSuccessToastify({ title: 'Broadcast created' });
			} else {
				await BroadcastService.updateBroadcast(+broadcastId, body);

				showSuccessToastify({ title: 'Broadcast updated' });
			}

			navigate(`${ROUTES.CAMPAIGNS.CAMPAIGNS}/${campaignDetails.campaignKey}`);
		} catch (error) {
			console.log(error);
		}
	};

	const validationSchema = lazy((values: IBroadcastValues) => {
		const validateSpecialDate =
			values.projectDateType === ProjectDateTypes.SpecialDate;

		return getBroadcastValidationSchema(validateSpecialDate);
	});

	const replyToProp = replyTo || replyToEmailAddress;
	const senderNameProp = senderName || emailSenderName;
	const phoneNumberProp = phoneNumber || twillioPhoneNumber || '';

	return (
		<Formik
			enableReinitialize
			onSubmit={onSubmit}
			validationSchema={validationSchema}
			initialValues={initialBroadcastValues}
		>
			<BroadcastForm
				replyTo={replyToProp}
				editorRef={editorRef}
				emailFrom={emailFrom}
				isTemplate={isTemplate}
				campaignKey={campaignKey}
				senderName={senderNameProp}
				initialEmailBody={emailBody}
				phoneNumber={phoneNumberProp}
				campaignTarget={campaignTarget}
				projectDateType={projectDateType}
				isCreateBroadcast={isCreateBroadcast}
				parsedSpecialDate={parsedSpecialDate}
			/>
		</Formik>
	);
};
