import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import { FC, useEffect } from 'react';

import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

import { ISelectOption } from 'types/ui/select';

import { SendTestBroadcastValues } from '..';
import { subscriptionSelector } from 'store/subscriptions';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { studioMarketingSelector } from 'store/studio';

interface ISendTestBroadcastFormProps {
	disableSendTest: boolean;
	selectOptions: ISelectOption[];
	setSendTestBroadcastSubmitted: (value: boolean) => void;
}

export const SendTestBroadcastForm: FC<ISendTestBroadcastFormProps> = ({
	selectOptions,
	disableSendTest,
	setSendTestBroadcastSubmitted,
}) => {
	const {
		values,
		errors,
		touched,
		isSubmitting,
		handleChange,
		handleSubmit,
		setFieldValue,
	} = useFormikContext<SendTestBroadcastValues>();

	const studioMarketing = useAppSelector(studioMarketingSelector);
	const subscription = useAppSelector(subscriptionSelector);

	const handleChangeProjectKey = (option: SingleValue<ISelectOption>) => {
		if (!option) return;

		void setFieldValue('projectKey', option.value);
	};

	useEffect(() => {
		if (!isSubmitting) return;

		setSendTestBroadcastSubmitted(true);
	}, [isSubmitting]);

	const disablePhoneField =
		!subscription?.freeSmsMarketing || !studioMarketing?.isPhoneNumberVerified;

	return (
		<div className="campaign-section-wrapper campaign-section-test">
			<SelectComponent
				value={values.projectKey}
				disabled={disableSendTest}
				selectOptions={selectOptions}
				onChange={handleChangeProjectKey}
				label="Fill keywords using project"
				selectPlaceholder="Project keyword"
			/>
			<label htmlFor="sendToEmailAddress" className="label">
				Send to Email Address
			</label>
			<InputGrid
				row
				className="autorow"
				id="sendToEmailAddress"
				disabled={disableSendTest}
				handleChange={handleChange}
				label="Send to Email Address"
				error={errors.sendToEmailAddress}
				value={values.sendToEmailAddress}
				placeholder="Send to Email Address"
				touched={touched.sendToEmailAddress}
			/>
			<label htmlFor="sendToPhoneNumber" className="label">
				Send to Phone Number
			</label>
			<InputGrid
				row
				className="autorow"
				id="sendToPhoneNumber"
				handleChange={handleChange}
				label="Send to Phone Number"
				value={values.sendToPhoneNumber}
				error={errors.sendToPhoneNumber}
				placeholder="Send to Phone Number"
				touched={touched.sendToPhoneNumber}
				disabled={disableSendTest || disablePhoneField}
			/>
			<Button
				value="Send Test"
				className="btn-primary"
				disabled={disableSendTest}
				onClick={() => handleSubmit()}
			/>
		</div>
	);
};
