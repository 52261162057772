import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { FC } from 'react';

import { Button } from 'components/FormControls/Button';
import { ROUTES } from 'constants/ROUTES';

import { IPromotionValues } from '../..';

interface IPromotionControlsProps {
	isCreatePromotion: boolean;
}

export const PromotionControls: FC<IPromotionControlsProps> = ({
	isCreatePromotion,
}) => {
	const { handleSubmit } = useFormikContext<IPromotionValues>();

	const navigate = useNavigate();

	const handleClickBack = () => {
		navigate(ROUTES.PROMOTIONS.PROMOTIONS);
	};

	return (
		<div className="promotion-details-footer">
			<Button
				value="Back"
				className="btn-secondary"
				onClick={handleClickBack}
			/>
			<Button
				className="btn-primary"
				onClick={() => handleSubmit()}
				value={isCreatePromotion ? 'Create' : 'Save'}
			/>
		</div>
	);
};
