import { ChangeEvent, FC, useState } from 'react';

import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

interface IMediaHeaderProps {
	setSearchValue: (value: string) => void;
}

export const MediaHeader: FC<IMediaHeaderProps> = ({ setSearchValue }) => {
	const [value, setValue] = useState('');

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value: inputValue } = e.target;

		setValue(inputValue);
	};

	const handleSearch = () => {
		setSearchValue(value);
	};

	return (
		<div className="media-header">
			<InputGrid
				value={value}
				required={false}
				className="media"
				handleChange={handleChange}
				label="Search Media Library"
				placeholder="Search Media Library"
			/>
			<Button value="Search" className="btn-secondary" onClick={handleSearch} />
		</div>
	);
};
