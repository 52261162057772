import { FC } from 'react';

import { OrderShippingTypes } from 'api/models/responses/priceLists/studio/priceListOptions/shippingOption';
import { ICreateShippingOption } from 'api/models/requests/priceLists/studio/priceListOptions/createShippingOption';
import ShippingOptionsService from 'api/services/PriceListsService/studio/ShippingOptionsService';
import { IErrorResponse } from 'api/models/responses/general/errorResponse';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { initialShippingOptionErrors } from 'constants/priceLists/studio/initialShippingOptionErrors';
import { CommonOptionActionMenu } from 'constants/priceLists/commonOptionActionMenu';

import { validateReferenceCode } from 'utils/validations/priceLists/validateReferenceCode';
import { validateRetailValue } from 'utils/validations/priceLists/validateRetailValue';
import { makeEntityAsDefault } from 'utils/dragAndDrop/makeEntityAsDefault';

import { useCreatePriceListOptionBtn } from 'pages/PriceLists/hooks/useCreatePriceListOptionBtn';
import {
	IShippingOptionUI,
	IUpdateShippingOption,
	IDeleteShippingOption,
	IShippingActionMenuParams,
	IClearShippingOptionErrors,
} from 'pages/PriceLists/types/studio/priceListOptions/shippingOptions';
import { OptionsTitle } from 'pages/PriceLists/components/OptionsTitle';
import {
	PriceTable,
	IHeaderConfig,
} from 'pages/PriceLists/components/PriceTable';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useActionMenu } from 'hooks/useActionMenu';

import { Button } from 'components/FormControls/Button';
import { NoItemsFound } from 'components/NoItemsFound';

import {
	setShippingOptions,
	shippingOptionsSelector,
} from 'store/priceLists/studio/priceListBaseOptions';

import { ShippingOption } from './ShippingOption';

const shippingOptionsHeaders: IHeaderConfig[] = [
	{ name: 'Delivery Option', required: true, left: true },
	{ name: 'Price', required: true, left: true },
	{ name: 'Reference Code', required: true, left: true },
];

interface IShippingOptionsProps {
	priceListKey?: number;
}

export const ShippingOptions: FC<IShippingOptionsProps> = ({
	priceListKey,
}) => {
	const shippingOptionsUI = useAppSelector(shippingOptionsSelector);

	const dispatch = useAppDispatch();

	const { actionMenuId, setActionMenuId } = useActionMenu();

	const lastShippingOptionUI = shippingOptionsUI[shippingOptionsUI.length - 1];

	const handleAddShippingOption = () => {
		if (!priceListKey) return;

		const newShippingOption: IShippingOptionUI = {
			shippingPrice: 0,
			isDefault: false,
			referenceCode: '',
			isCollectingMailingAddress: true,
			errors: initialShippingOptionErrors,
			sequence: shippingOptionsUI.length + 1,
			priceListStudioFulfilledKey: priceListKey,
			orderShippingType: OrderShippingTypes.Batch,
		};

		dispatch(setShippingOptions([...shippingOptionsUI, newShippingOption]));
	};

	const setDefaultShippingOption = async (shippingOptionKey?: number) => {
		if (!priceListKey || !shippingOptionKey) return;

		try {
			await ShippingOptionsService.setDefaultShippingOption(
				priceListKey,
				shippingOptionKey
			);
		} catch (error) {
			console.log(error);
		}
	};

	const handleSaveShippingOption = async () => {
		if (!priceListKey) return;

		const {
			sequence,
			shippingPrice,
			referenceCode,
			orderShippingType,
			isCollectingMailingAddress,
		} = lastShippingOptionUI;

		const slicedShippingOptionsUI = shippingOptionsUI.slice(0, -1);

		const isUniq =
			shippingOptionsUI.length === 1 ||
			shippingOptionsUI.some(
				(shippingOptionUI) =>
					shippingOptionUI.orderShippingType !== orderShippingType
			);

		const shippingPriceValidationMessage = validateRetailValue(shippingPrice);
		const referenceCodeValidationMessage = validateReferenceCode(referenceCode);

		const isValid =
			!shippingPriceValidationMessage && !referenceCodeValidationMessage;

		if (!isValid || !isUniq) {
			const updatedShippingOptionUI: IShippingOptionUI = {
				...lastShippingOptionUI,
				errors: {
					shippingPrice: shippingPriceValidationMessage,
					referenceCode: referenceCodeValidationMessage,
					orderShippingType: isUniq
						? ''
						: 'Shipping option with this type already exists',
				},
			};

			dispatch(
				setShippingOptions([
					...slicedShippingOptionsUI,
					updatedShippingOptionUI,
				])
			);

			return;
		}

		try {
			const body: ICreateShippingOption = {
				sequence,
				shippingPrice,
				referenceCode,
				orderShippingType,
				isCollectingMailingAddress,
			};

			const data = await ShippingOptionsService.createShippingOption(
				priceListKey,
				body
			);

			const updatedShippingOptionUI: IShippingOptionUI = {
				...data,
				errors: initialShippingOptionErrors,
			};

			dispatch(
				setShippingOptions([
					...slicedShippingOptionsUI,
					updatedShippingOptionUI,
				])
			);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedShippingOptionUI: IShippingOptionUI = {
				...lastShippingOptionUI,
				errors: {
					...initialShippingOptionErrors,
					...errors,
				},
			};

			dispatch(
				setShippingOptions([
					...slicedShippingOptionsUI,
					updatedShippingOptionUI,
				])
			);
		}
	};

	const updateShippingOption = async ({
		value,
		fieldKey,
		sequence,
		validationMessage,
		shippingOptionKey,
	}: IUpdateShippingOption) => {
		if (!shippingOptionKey || validationMessage) {
			const updatedShippingOptionUI = shippingOptionsUI.map(
				(shippingOptionUI) =>
					shippingOptionUI.sequence === sequence
						? {
								...shippingOptionUI,
								[fieldKey]: value,
								errors: {
									...shippingOptionUI.errors,
									[fieldKey]: validationMessage,
								},
						  }
						: shippingOptionUI
			);

			dispatch(setShippingOptions(updatedShippingOptionUI));
			return;
		}

		if (!shippingOptionKey || !priceListKey) return;

		try {
			const updatedField: IPatchBody = {
				value,
				op: 'replace',
				path: fieldKey,
			};

			const body: IPatchBody[] = [updatedField];

			const data = await ShippingOptionsService.updateShippingOption(
				shippingOptionKey,
				body
			);

			const updatedShippingOptionsUI = shippingOptionsUI.map(
				(shippingOptionUI) =>
					shippingOptionUI.priceListStudioFulfilledShippingOptionKey ===
					shippingOptionKey
						? { ...data, errors: initialShippingOptionErrors }
						: shippingOptionUI
			);

			dispatch(setShippingOptions(updatedShippingOptionsUI));
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedShippingOptionUI = shippingOptionsUI.map(
				(shippingOptionUI) =>
					shippingOptionUI.priceListStudioFulfilledShippingOptionKey ===
					shippingOptionKey
						? {
								...shippingOptionUI,
								errors: {
									...initialShippingOptionErrors,
									[fieldKey]: errors[fieldKey],
								},
						  }
						: shippingOptionUI
			);

			dispatch(setShippingOptions(updatedShippingOptionUI));
		}
	};

	const deleteShippingOption = async ({
		sequence,
		shippingOptionKey,
	}: IDeleteShippingOption) => {
		if (!priceListKey) return;

		const updatedShippingOptionsUI = shippingOptionsUI.filter(
			(shippingOptionUI) => shippingOptionUI.sequence !== sequence
		);

		dispatch(setShippingOptions(updatedShippingOptionsUI));

		if (!shippingOptionKey) return;

		try {
			await ShippingOptionsService.deleteShippingOption(shippingOptionKey);
		} catch (error) {
			console.log(error);
		}
	};

	const clearShippingOptionError = ({
		fieldKey,
		sequence,
	}: IClearShippingOptionErrors) => {
		const updatedShippingOptionsUI = shippingOptionsUI.map((shippingOptionUI) =>
			shippingOptionUI.sequence === sequence
				? {
						...shippingOptionUI,
						errors: { ...shippingOptionUI.errors, [fieldKey]: '' },
				  }
				: shippingOptionUI
		);

		dispatch(setShippingOptions(updatedShippingOptionsUI));
	};

	const changeShippingOptionsUISequence = (shippingOptionKey?: number) => {
		if (!shippingOptionKey) return;

		const reorderedShippingOptionsUI = makeEntityAsDefault<IShippingOptionUI>({
			entities: shippingOptionsUI,
			entityKey: shippingOptionKey,
			fieldKey: 'priceListStudioFulfilledShippingOptionKey',
		});

		const updatedShippingOptionsUI = reorderedShippingOptionsUI.map(
			(shippingOptionUI) =>
				shippingOptionUI.priceListStudioFulfilledShippingOptionKey ===
				shippingOptionKey
					? { ...shippingOptionUI, isDefault: true }
					: { ...shippingOptionUI, isDefault: false }
		);

		dispatch(setShippingOptions(updatedShippingOptionsUI));
	};

	const handleActionMenuItemClick = ({
		menuItem,
		sequence,
		shippingOptionKey,
	}: IShippingActionMenuParams) => {
		switch (menuItem) {
			case CommonOptionActionMenu.MakeAsDefault:
				changeShippingOptionsUISequence(shippingOptionKey);
				void setDefaultShippingOption(shippingOptionKey);
				break;

			case CommonOptionActionMenu.Delete:
				void deleteShippingOption({ sequence, shippingOptionKey });
				break;

			default:
				break;
		}
	};

	const unsavedShippingOption =
		!!shippingOptionsUI.length &&
		!lastShippingOptionUI?.priceListStudioFulfilledShippingOptionKey;

	const disabledCreation =
		!unsavedShippingOption && shippingOptionsUI.length === 2;

	const ShippingOptionsList = shippingOptionsUI.map((shippingOptionUI) => (
		<ShippingOption
			actionMenuId={actionMenuId}
			setActionMenuId={setActionMenuId}
			shippingOptionUI={shippingOptionUI}
			updateShippingOption={updateShippingOption}
			unsavedShippingOptions={unsavedShippingOption}
			disableShippingTypeSelection={disabledCreation}
			handleActionMenuClick={handleActionMenuItemClick}
			clearShippingOptionErrors={clearShippingOptionError}
			key={
				shippingOptionUI?.priceListStudioFulfilledShippingOptionKey ||
				shippingOptionUI.sequence
			}
		/>
	));

	const { btnValue, btnClassName, btnClickHandler } =
		useCreatePriceListOptionBtn({
			optionName: 'Delivery Option',
			showSaveBtn: unsavedShippingOption,
			handleAdd: handleAddShippingOption,
			handleSave: handleSaveShippingOption,
		});

	return (
		<div className="price-container">
			<OptionsTitle title="Delivery Options" />
			{shippingOptionsUI.length ? (
				<PriceTable headers={shippingOptionsHeaders}>
					{ShippingOptionsList}
				</PriceTable>
			) : (
				<NoItemsFound title="delivery options" />
			)}
			<Button
				value={btnValue}
				onClick={btnClickHandler}
				disabled={disabledCreation}
				className={`price-add-back ${btnClassName}`}
			/>
		</div>
	);
};
