import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { FC, useEffect, useMemo } from 'react';
import { utc } from 'moment';

import { IMarketingStatisticColumn } from 'api/models/responses/dashboard/marketingStatistic';
import { Periods } from 'api/models/requests/dashboard/periods';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';

import { Chart } from 'components/Chart';
import { Card } from 'components/Card';

import { chartList, lineColors } from 'constants/dashboard/chart';
import { normalizeDigit } from 'utils/ui/normalizeDigit';
import {
	selectedPeriodSelector,
	getMarketingStatisticAsync,
	marketingStatisticSelector,
	isMarketingStatisticPendingSelector,
} from 'store/dashboard';

import { ChartList } from './ChartList';

interface ILabelsSetup {
	labels: string[];
	isYearTransitionCompleted: boolean;
}

type GroupedMarketingStatistic = Record<
	keyof Omit<IMarketingStatisticColumn, 'endDate' | 'startDate' | 'sales'>,
	number[]
>;

const initialLabelsSetup: ILabelsSetup = {
	labels: [],
	isYearTransitionCompleted: false,
};

const initialGroupedMarketingStatistic: GroupedMarketingStatistic = {
	opens: [],
	sends: [],
	orders: [],
	logins: [],
	clicks: [],
};

export const Schedule: FC = () => {
	const marketingStatistic = useAppSelector(marketingStatisticSelector);
	const selectedPeriod = useAppSelector(selectedPeriodSelector);
	const isMarketingStatisticPending = useAppSelector(
		isMarketingStatisticPendingSelector
	);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!selectedPeriod) return;

		void dispatch(getMarketingStatisticAsync(selectedPeriod));
	}, [selectedPeriod]);

	const sales =
		marketingStatistic?.columns.map((statistic) => statistic.sales) || [];

	const groupedMarketingStatistic = useMemo<GroupedMarketingStatistic>(() => {
		if (!marketingStatistic) return initialGroupedMarketingStatistic;

		return marketingStatistic.columns.reduce<GroupedMarketingStatistic>(
			(acc, { opens, sends, clicks, logins, orders }) => ({
				opens: [...acc.opens, opens],
				sends: [...acc.sends, sends],
				orders: [...acc.orders, orders],
				logins: [...acc.logins, logins],
				clicks: [...acc.clicks, clicks],
			}),
			initialGroupedMarketingStatistic
		);
	}, [marketingStatistic?.columns]);

	const marketingStatisticEntries = Object.entries(groupedMarketingStatistic);
	const marketingStatisticValues = Object.values(groupedMarketingStatistic);

	const unitedMarketingStatisticValues = marketingStatisticValues.reduce<
		number[]
	>((acc, item) => [...acc, ...item], []);

	const maxSalesTotal = Math.max(...sales);
	const minSalesTotal = Math.min(...[0, ...sales]);

	const maxUnited = Math.max(...unitedMarketingStatisticValues);
	const minUnited = Math.min(...unitedMarketingStatisticValues);

	const lines = marketingStatisticEntries.map(([title, values]) => ({
		type: 'line' as const,
		label: title,
		borderColor: lineColors[title],
		borderWidth: 2,
		fill: false,
		data: values,
		yAxisID: 'y',
	}));

	const bar = {
		type: 'bar' as const,
		label: 'Sales total',
		backgroundColor: '#76AD5A',
		borderRadius: 10,
		data: sales,
		yAxisID: 'y1',
		barThickness: 50,
	};

	const labels =
		marketingStatistic?.columns.reduce<ILabelsSetup>(
			(acc, { endDate, startDate }, index, arr) => {
				const { labels: currentLabels, isYearTransitionCompleted } = acc;

				const parsedStartDate = utc(startDate);
				const parsedEndDate = utc(endDate);

				const startDateMonthName = parsedStartDate.format('MMM');
				const endDateMonthName = parsedEndDate.format('MMM');
				const startDateYear = parsedStartDate.format('YYYY');
				const startDateDay = parsedStartDate.format('DD');
				const endDateDay = parsedEndDate.format('DD');

				const isSameMonth = startDateMonthName === endDateMonthName;
				const isSameDay = startDateDay === endDateDay;

				if (selectedPeriod === Periods.Month) {
					const firstLabelPart = `${startDateMonthName} ${startDateDay}`;

					const secondLabelPart = `${
						isSameMonth ? '' : ` ${endDateMonthName}`
					}${isSameDay ? '' : ` ${endDateDay}`}`;

					const label = secondLabelPart
						? `${firstLabelPart} -${secondLabelPart}`
						: firstLabelPart;

					return {
						...acc,
						labels: [...currentLabels, label],
					};
				}

				if (selectedPeriod === Periods.Year) {
					if (!index) {
						const label = `${startDateMonthName} ${startDateYear}`;

						return {
							...acc,
							labels: [...currentLabels, label],
						};
					}

					const prevElem = arr[index - 1];
					const parsedPrevStartDate = utc(prevElem.startDate);
					const prevStartDateYear = parsedPrevStartDate.format('YYYY');

					const isNextYear = startDateYear !== prevStartDateYear;

					if (isNextYear && !isYearTransitionCompleted) {
						const label = `${startDateMonthName} ${startDateYear}`;

						return {
							isYearTransitionCompleted: true,
							labels: [...currentLabels, label],
						};
					}

					return {
						...acc,
						labels: [...currentLabels, startDateMonthName],
					};
				}

				const label = `${startDateMonthName} ${startDateDay}`;

				return {
					...acc,
					labels: [...currentLabels, label],
				};
			},
			initialLabelsSetup
		).labels || [];

	const chartData: ChartData<ChartType> = {
		labels,
		datasets: [...lines, bar],
	};

	const ticks = {
		color: '#9e9faf',
		font: {
			family: 'avenir',
			size: 9,
		},
	};

	const chartOptions: ChartOptions<ChartType> = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					label: (context) => {
						const label = context.dataset.label || '';
						const parsedY = context.parsed.y;

						if (context.dataset.yAxisID === 'y1') {
							return `${label}: $${parsedY}`;
						}

						return `${label}: ${parsedY}`;
					},
				},
			},
		},
		scales: {
			y: {
				type: 'linear',
				position: 'left',
				max: maxUnited,
				min: minUnited,
				ticks,
			},
			y1: {
				type: 'linear',
				position: 'right',
				max: maxSalesTotal,
				min: minSalesTotal,
				ticks: {
					...ticks,
					callback: (value: number | string) =>
						normalizeDigit({ value: +value, isPrice: true }),
				},
				grid: {
					display: false,
				},
			},
		},
	};

	const parsedStartDate = marketingStatistic?.startDate
		? utc(marketingStatistic.startDate)
		: null;

	const parsedEndDate = marketingStatistic?.endDate
		? utc(marketingStatistic.endDate)
		: null;

	const startDateMonthName = parsedStartDate?.format('MMM') || '';
	const startDateYear = parsedStartDate?.format('YYYY') || '';
	const endDateMonthName = parsedEndDate?.format('MMM') || '';
	const startDateDay = parsedStartDate?.format('DD') || '';
	const endDateYear = parsedEndDate?.format('YYYY') || '';
	const endDateDay = parsedEndDate?.format('DD') || '';

	const isSameMonth = startDateMonthName === endDateMonthName;

	const subtitle =
		selectedPeriod === Periods.Year
			? `(${startDateMonthName} ${startDateYear} - ${endDateYear})`
			: `(${startDateMonthName} ${startDateDay} - ${
					isSameMonth ? '' : endDateMonthName
			  } ${endDateDay})`;

	const ChartsList = chartList.map((title) => (
		<li key={title} className={`chart-item ${title}`}>
			{title}
		</li>
	));

	return (
		<Card
			subtitle={subtitle}
			bodyClassName="chart"
			title="Sales + Marketing"
			columnFit="col-2 card-chart"
		>
			<div className="chart-container">
				{!isMarketingStatisticPending && (
					<Chart type="bar" data={chartData} options={chartOptions} />
				)}
			</div>
			<ChartList>{ChartsList}</ChartList>
		</Card>
	);
};
