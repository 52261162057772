import { useFormikContext } from 'formik';
import { FC, useEffect } from 'react';

import { Button } from 'components/FormControls/Button';
import { W9InfoForm } from 'components/W9InfoForm';

import { StepHeader } from 'pages/RegistrationSteps/components/StepHeader';

import { BankACHInformation } from './BankACHInformation';
import { IGetPaidFormValues } from '../types';

export const SetupAccountForm: FC = () => {
	const { errors, isSubmitting, handleSubmit } =
		useFormikContext<IGetPaidFormValues>();

	useEffect(() => {
		if (!isSubmitting) return;

		const firstErrorKey = Object.keys(errors)[0];

		if (!firstErrorKey) return;

		const $field = document.getElementById(firstErrorKey);

		if ($field) {
			$field.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	}, [isSubmitting, errors]);

	return (
		<>
			<StepHeader title="Get Paid" stepCount="Step 3 of 3" />
			<div className="form-wrapper">
				<h2 className="studio-title">Bank ACH Information</h2>
				<BankACHInformation />
				<h2 className="acc-studio-title">W9 Information</h2>
				<W9InfoForm />
			</div>
			<Button
				value="Create New Studio"
				onClick={() => handleSubmit()}
				className="paid-btn btn-primary"
			/>
		</>
	);
};
