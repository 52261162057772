import { FC, useCallback, useEffect, useState } from 'react';

import { ICreateOwnPackageOption } from 'api/models/requests/priceLists/studio/priceListOptions/createOwnPackageOption';
import OwnPackageOptionsService from 'api/services/PriceListsService/studio/OwnPackageOptionsService';
import { IErrorResponse } from 'api/models/responses/general/errorResponse';
import { IPatchBody } from 'api/models/requests/general/patchBody';

import { initialOwnPackageOptionErrors } from 'constants/priceLists/studio/initialOwnPackageOptionErrors';
import { PackageOptionTypes } from 'constants/priceLists/studio/packageOptionTypes';

import { validateReferenceCode } from 'utils/validations/priceLists/validateReferenceCode';
import { validateRetailValue } from 'utils/validations/priceLists/validateRetailValue';
import { validateOptionName } from 'utils/validations/priceLists/validateOptionName';
import { validateDescription } from 'utils/validations/general/validateDescription';
import { validatePoints } from 'utils/validations/priceLists/studio/validatePoints';
import { changeEntitiesSequence } from 'utils/dragAndDrop/changeEntitiesSequence';

import { useCreatePriceListOptionBtn } from 'pages/PriceLists/hooks/useCreatePriceListOptionBtn';
import {
	IOwnPackageOptionUI,
	IUpdateOwnPackageOption,
	IDeleteOwnPackageOption,
	IClearOwnPackageOptionError,
	IUploadOwnPackageOptionPreview,
} from 'pages/PriceLists/types/studio/priceListOptions/ownPackageOptions';
import {
	PriceTable,
	IHeaderConfig,
} from 'pages/PriceLists/components/PriceTable';

import { IChangeSequencePayload } from 'hooks/useDragAndDrop';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { Button } from 'components/FormControls/Button';
import { NoItemsFound } from 'components/NoItemsFound';

import { setGroupedPackageOptions } from 'store/priceLists/studio/priceListPackageOptions';

import { PackageOptionsHeader } from './components/OwnPackageOptionsHeader';
import { OwnPackageOption } from './components/OwnPackageOption';

const packageTableHeaders: IHeaderConfig[] = [
	{ name: 'Product Name', required: true, left: true },
	{
		name: 'Product ID',
		required: true,
		left: true,
		subtitle: 'hhschool package letter',
	},
	{
		name: 'Point Value',
		left: true,
		tooltip:
			'How many of the available points for the BYO package should this product use?',
	},
	{
		name: 'Price',
		required: true,
		left: true,
		subtitle: 'if max # of points exceeded',
	},
];

export interface IOwnPackageOptionsProps {
	priceListKey?: number;
	ownPackageOptionsUI: IOwnPackageOptionUI[];
}

export const OwnPackageOptions: FC<IOwnPackageOptionsProps> = ({
	priceListKey,
	ownPackageOptionsUI,
}) => {
	const [previewImageFile, setPreviewImageFile] = useState<File | null>(null);
	const [isReordered, setIsReordered] = useState(false);

	const lastOwnPackageOptionUI =
		ownPackageOptionsUI[ownPackageOptionsUI.length - 1];

	const dispatch = useAppDispatch();

	const setOwnPackageOptionsUI = (options: IOwnPackageOptionUI[]) => {
		dispatch(
			setGroupedPackageOptions({
				options,
				optionType: PackageOptionTypes.BuildYourOwn,
			})
		);
	};

	const handleAddOwnPackageOption = () => {
		if (!priceListKey) return;

		const initialSequence =
			ownPackageOptionsUI.length && lastOwnPackageOptionUI.sequence + 1;

		const newOwnPackageOption: IOwnPackageOptionUI = {
			name: '',
			price: 0,
			points: 1,
			description: '',
			referenceCode: '',
			previewImageUrl: '',
			sequence: initialSequence,
			errors: initialOwnPackageOptionErrors,
			priceListStudioFulfilledKey: priceListKey,
		};

		setOwnPackageOptionsUI([...ownPackageOptionsUI, newOwnPackageOption]);
	};

	const updateOwnPackageOption = async ({
		value,
		fieldKey,
		sequence,
		validationMessage,
		ownPackageOptionKey,
	}: IUpdateOwnPackageOption) => {
		if (!ownPackageOptionKey || validationMessage) {
			const updatedOwnPackageOptions = ownPackageOptionsUI.map(
				(ownPackageOptionUI) =>
					ownPackageOptionUI.sequence === sequence
						? {
								...ownPackageOptionUI,
								[fieldKey]: value,
								errors: {
									...ownPackageOptionUI.errors,
									[fieldKey]: validationMessage,
								},
						  }
						: ownPackageOptionUI
			);

			setOwnPackageOptionsUI(updatedOwnPackageOptions);
			return;
		}

		if (!ownPackageOptionKey) return;

		try {
			const updatedField: IPatchBody = {
				value,
				op: 'replace',
				path: fieldKey,
			};

			const body: IPatchBody[] = [updatedField];

			const data = await OwnPackageOptionsService.updateOwnPackageOption(
				ownPackageOptionKey,
				body
			);

			const updatedOwnPackageOptions = ownPackageOptionsUI.map(
				(ownPackageOptionUI) =>
					ownPackageOptionUI.priceListStudioFulfilledOwnPackageOptionKey ===
					ownPackageOptionKey
						? {
								...data,
								errors: initialOwnPackageOptionErrors,
						  }
						: ownPackageOptionUI
			);

			setOwnPackageOptionsUI(updatedOwnPackageOptions);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedOwnPackageOptions = ownPackageOptionsUI.map(
				(ownPackageOptionUI) =>
					ownPackageOptionUI.priceListStudioFulfilledOwnPackageOptionKey ===
					ownPackageOptionKey
						? {
								...ownPackageOptionUI,
								errors: {
									...ownPackageOptionUI.errors,
									[fieldKey]: errors[fieldKey],
								},
						  }
						: ownPackageOptionUI
			);

			setOwnPackageOptionsUI(updatedOwnPackageOptions);
		}
	};

	const handleSaveOwnPackageOption = async () => {
		if (!priceListKey) return;

		const { name, price, points, sequence, referenceCode, description } =
			lastOwnPackageOptionUI;

		const slicedOwnPackageOptionsUI = ownPackageOptionsUI.slice(0, -1);

		const referenceCodeValidationMessage = validateReferenceCode(referenceCode);
		const priceValidationMessage = validateRetailValue(price);
		const descriptionValidationMessage = validateDescription({
			value: description,
		});
		const nameValidationMessage = validateOptionName(name);
		const pointsValidationMessage = validatePoints(points);

		const isValid =
			!nameValidationMessage &&
			!priceValidationMessage &&
			!pointsValidationMessage &&
			!descriptionValidationMessage &&
			!referenceCodeValidationMessage;

		if (!isValid) {
			const updatedOwnPackageOptionUI: IOwnPackageOptionUI = {
				...lastOwnPackageOptionUI,
				errors: {
					preview: '',
					name: nameValidationMessage,
					price: priceValidationMessage,
					points: pointsValidationMessage,
					description: descriptionValidationMessage,
					referenceCode: referenceCodeValidationMessage,
				},
			};

			setOwnPackageOptionsUI([
				...slicedOwnPackageOptionsUI,
				updatedOwnPackageOptionUI,
			]);

			return;
		}

		try {
			const body: ICreateOwnPackageOption = {
				name,
				price,
				points,
				sequence,
				description,
				referenceCode,
				previewImageFile,
			};

			const data = await OwnPackageOptionsService.createOwnPackageOption(
				priceListKey,
				body
			);

			const updatedOwnPackageOptionUI: IOwnPackageOptionUI = {
				...data,
				errors: initialOwnPackageOptionErrors,
			};

			setOwnPackageOptionsUI([
				...slicedOwnPackageOptionsUI,
				updatedOwnPackageOptionUI,
			]);
			setPreviewImageFile(null);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedOwnPackageOptionUI: IOwnPackageOptionUI = {
				...lastOwnPackageOptionUI,
				errors: {
					...initialOwnPackageOptionErrors,
					...errors,
				},
			};

			setOwnPackageOptionsUI([
				...slicedOwnPackageOptionsUI,
				updatedOwnPackageOptionUI,
			]);
		}
	};

	const changeOwnPackageOptionSequence = (payload: IChangeSequencePayload) => {
		const reorderedOwnPackageOptions = changeEntitiesSequence({
			...payload,
			entities: ownPackageOptionsUI,
			fieldKey: 'priceListStudioFulfilledOwnPackageOptionKey',
		});

		setOwnPackageOptionsUI(reorderedOwnPackageOptions);
		setIsReordered(true);
	};

	const reorderOwnPackageOptions = useCallback(async () => {
		if (!priceListKey || !isReordered) return;

		const reorderedOwnPackageOptionsPayload = ownPackageOptionsUI.reduce<
			Record<number, number>
		>(
			(acc, { priceListStudioFulfilledOwnPackageOptionKey, sequence }) =>
				priceListStudioFulfilledOwnPackageOptionKey
					? { ...acc, [priceListStudioFulfilledOwnPackageOptionKey]: sequence }
					: acc,
			{}
		);

		try {
			await OwnPackageOptionsService.reorderOwnPackageOptions(
				priceListKey,
				reorderedOwnPackageOptionsPayload
			);
		} catch (error) {
			console.log(error);
		}

		setIsReordered(false);
	}, [isReordered, priceListKey]);

	const uploadImage = async ({
		sequence,
		imageFile,
		validationMessage,
		ownPackageOptionKey,
	}: IUploadOwnPackageOptionPreview) => {
		if (validationMessage) {
			const updatedOwnPackageOptions = ownPackageOptionsUI.map(
				(ownPackageOptionUI) =>
					ownPackageOptionUI.sequence === sequence
						? {
								...ownPackageOptionUI,
								errors: {
									...ownPackageOptionUI.errors,
									preview: validationMessage,
								},
						  }
						: ownPackageOptionUI
			);

			setOwnPackageOptionsUI(updatedOwnPackageOptions);
			return;
		}

		if (!ownPackageOptionKey) {
			setPreviewImageFile(imageFile);
			return;
		}

		try {
			const data =
				await OwnPackageOptionsService.uploadOwnPackageOptionPreviewImage(
					ownPackageOptionKey,
					imageFile
				);

			const updatedOwnPackageOptions = ownPackageOptionsUI.map(
				(ownPackageOptionUI) =>
					ownPackageOptionUI.priceListStudioFulfilledOwnPackageOptionKey ===
					ownPackageOptionKey
						? {
								...ownPackageOptionUI,
								previewImageUrl: data,
						  }
						: ownPackageOptionUI
			);

			setOwnPackageOptionsUI(updatedOwnPackageOptions);
		} catch (error) {
			console.log(error);
		}
	};

	const deleteImage = async (ownPackageKey?: number) => {
		if (!ownPackageKey) {
			setPreviewImageFile(null);
			return;
		}

		try {
			await OwnPackageOptionsService.deleteOwnPackageOptionPreviewImage(
				ownPackageKey
			);

			const updatedOwnPackageOptions = ownPackageOptionsUI.map(
				(ownPackageOptionUI) =>
					ownPackageOptionUI.priceListStudioFulfilledOwnPackageOptionKey ===
					ownPackageKey
						? {
								...ownPackageOptionUI,
								previewImageUrl: '',
						  }
						: ownPackageOptionUI
			);

			setOwnPackageOptionsUI(updatedOwnPackageOptions);
		} catch (error) {
			console.log(error);
		}
	};

	const deleteOwnPackageOption = async ({
		sequence,
		ownPackageOptionKey,
	}: IDeleteOwnPackageOption) => {
		const updatedOwnPackageOptions = ownPackageOptionsUI.filter(
			(ownPackageOption) => ownPackageOption.sequence !== sequence
		);

		setOwnPackageOptionsUI(updatedOwnPackageOptions);
		setPreviewImageFile(null);

		if (ownPackageOptionKey) {
			try {
				await OwnPackageOptionsService.deleteOwnPackageOption(
					ownPackageOptionKey
				);
			} catch (error) {
				console.log(error);
			}
		}
	};

	const clearOwnPackageOptionError = ({
		sequence,
		fieldKey,
	}: IClearOwnPackageOptionError) => {
		const updatedOwnPackageOptions = ownPackageOptionsUI.map(
			(ownPackageOptionUI) =>
				ownPackageOptionUI.sequence === sequence
					? {
							...ownPackageOptionUI,
							errors: {
								...ownPackageOptionUI.errors,
								[fieldKey]: '',
							},
					  }
					: ownPackageOptionUI
		);

		setOwnPackageOptionsUI(updatedOwnPackageOptions);
	};

	useEffect(() => {
		void reorderOwnPackageOptions();
	}, [reorderOwnPackageOptions]);

	const OwnPackageOptionsList = ownPackageOptionsUI.map(
		(ownPackageOptionUI) => (
			<OwnPackageOption
				uploadImage={uploadImage}
				deleteImage={deleteImage}
				previewImageFile={previewImageFile}
				ownPackageOptionUI={ownPackageOptionUI}
				deleteOwnPackageOption={deleteOwnPackageOption}
				updateOwnPackageOption={updateOwnPackageOption}
				clearOwnPackageOptionError={clearOwnPackageOptionError}
				changeOwnPackageOptionSequence={changeOwnPackageOptionSequence}
				key={
					ownPackageOptionUI.priceListStudioFulfilledOwnPackageOptionKey ||
					ownPackageOptionUI.sequence
				}
			/>
		)
	);

	const showSaveBtn =
		!!ownPackageOptionsUI.length &&
		!lastOwnPackageOptionUI?.priceListStudioFulfilledOwnPackageOptionKey;

	const { btnValue, btnClassName, btnClickHandler } =
		useCreatePriceListOptionBtn({
			showSaveBtn,
			optionName: 'BYO Product',
			handleAdd: handleAddOwnPackageOption,
			handleSave: handleSaveOwnPackageOption,
		});

	return (
		<>
			<PackageOptionsHeader />
			{ownPackageOptionsUI.length ? (
				<PriceTable
					headers={packageTableHeaders}
					className="price-table-backgrounds"
				>
					{OwnPackageOptionsList}
				</PriceTable>
			) : (
				<NoItemsFound title="own package options" />
			)}
			<div className="price-byo">
				<span className="price-byo-text">
					You will define your BYO, which contains these products, on Packages
					screen
				</span>
				<Button
					value={btnValue}
					onClick={btnClickHandler}
					className={`price-byo-btn ${btnClassName}`}
				/>
			</div>
		</>
	);
};
