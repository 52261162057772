import { Formik, Form, FormikErrors, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';

import { ICreateNewOrganizationBody } from 'api/models/requests/organizations/createNewOrganization';
import { IErrorResponse } from 'api/models/responses/general/errorResponse';
import OrganizationService from 'api/services/OrganizationService';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';

import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

import { setCreatedOrganization } from 'store/organizations';
import { studioSelector } from 'store/studio';

import { ROUTES } from 'constants/ROUTES';

import { createNewOrganizationScheme } from './validations';

interface IField {
	id: string;
	label: string;
	supLabel?: string;
	placeholder: string;
}

const getCreateOrganizationFields = (networkDomain?: string): IField[] => [
	{
		id: 'organizationName',
		label: 'Organization Name',
		placeholder: 'Organization Name',
	},
	{
		id: 'organizationUrl',
		label: 'Organization URL',
		placeholder: 'Organization URL',
		supLabel: (networkDomain && `${networkDomain}.accessmyevent.com/`) || '',
	},
];

export interface ICreateNewOrganizationValues
	extends ICreateNewOrganizationBody {
	[key: string]: string;
}

export const CreateNewOrganization: FC = () => {
	const studio = useAppSelector(studioSelector);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const initialValues: ICreateNewOrganizationValues = {
		organizationName: '',
		organizationUrl: '',
	};

	const onSubmit = async (
		values: ICreateNewOrganizationValues,
		{ setErrors }: FormikHelpers<ICreateNewOrganizationValues>
	) => {
		try {
			const data = await OrganizationService.createNewOrganization(values);

			dispatch(setCreatedOrganization(data));

			navigate(`${ROUTES.ORGANIZATIONS.ORGANIZATIONS}/${data.organizationKey}`);
		} catch (error) {
			const { errors } = error as IErrorResponse;

			const updatedErrorMessages = Object.entries(errors).reduce<
				FormikErrors<ICreateNewOrganizationValues>
			>((acc, [key, messages]) => {
				const splittedKey = key.split('.');

				const parsedKey = splittedKey[splittedKey.length - 1];

				const doesKeyExist = parsedKey in values;

				const parsedMessage = messages[0] || '';

				if (!doesKeyExist) return acc;

				return {
					...acc,
					[parsedKey]: parsedMessage,
				};
			}, {});

			setErrors(updatedErrorMessages);
		}
	};

	const createOrganizationFields = getCreateOrganizationFields(
		studio?.networkDomain
	);

	return (
		<Formik
			onSubmit={onSubmit}
			initialValues={initialValues}
			validationSchema={createNewOrganizationScheme}
		>
			{({ values, errors, touched, handleChange }) => (
				<Form className="modal-body">
					<div className="org-modal">
						{createOrganizationFields.map(
							({ id, label, supLabel, placeholder }) => (
								<InputGrid
									row
									id={id}
									key={id}
									required
									label={label}
									className="row"
									error={errors[id]}
									value={values[id]}
									supLabel={supLabel}
									touched={touched[id]}
									placeholder={placeholder}
									handleChange={handleChange}
								/>
							)
						)}
					</div>
					<Button
						type="submit"
						value="Create"
						className="org-modal-btn btn-primary"
					/>
				</Form>
			)}
		</Formik>
	);
};
