import { Formik, FormikHelpers } from 'formik';
import { FC } from 'react';

import { ICreateAlbumBody } from 'api/models/requests/albums/createAlbum';
import { IPatchBody } from 'api/models/requests/general/patchBody';
import AlbumService from 'api/services/AlbumService';
import {
	ErrorTypes,
	IErrorResponse,
} from 'api/models/responses/general/errorResponse';

import { InputGrid } from 'components/FormControls/InputGrid';
import { Button } from 'components/FormControls/Button';

import { getProcessedErrors } from 'utils/errors/getProcessedErrors';

import { IAlbumUI, IUpdateAlbumValues } from '../../types';
import { updateAlbumScheme } from './validation';

interface IUpdateAlbumParams {
	albumsUI: IAlbumUI[];
	projectKey: number;
	initialName: string;
	initialPassword: string;
	albumKey: string | null;
	hideUpdateAlbumModal: () => void;
	setAlbumsUI: (albums: IAlbumUI[]) => void;
}

interface IField {
	label: string;
	required: boolean;
	placeholder: string;
	id: keyof IUpdateAlbumValues;
}

const fields: IField[] = [
	{ id: 'name', label: 'Group', placeholder: 'Group', required: true },
	{
		id: 'password',
		label: 'Access Code',
		placeholder: 'Access Code',
		required: false,
	},
];

export const UpdateAlbum: FC<IUpdateAlbumParams> = ({
	albumsUI,
	albumKey,
	projectKey,
	initialName,
	setAlbumsUI,
	initialPassword,
	hideUpdateAlbumModal,
}) => {
	const initialValues: IUpdateAlbumValues = {
		name: initialName,
		password: initialPassword,
	};

	const onSubmit = async (
		values: IUpdateAlbumValues,
		{ setErrors }: FormikHelpers<IUpdateAlbumValues>
	) => {
		const trimmedValues: IUpdateAlbumValues = {
			name: values.name.trim(),
			password: values.password.trim(),
		};

		const handleErrors = (error: IErrorResponse) => {
			if (
				error.type === ErrorTypes.BusinessError ||
				error.type === ErrorTypes.ValidationError
			) {
				const processedErrors = getProcessedErrors(trimmedValues, error.errors);

				setErrors(processedErrors);
			}
		};

		try {
			if (albumKey) {
				const updatedAlbumName: IPatchBody = {
					path: 'name',
					op: 'replace',
					value: trimmedValues.name,
				};

				const updatedAlbumPassword: IPatchBody = {
					op: 'replace',
					path: 'password',
					value: trimmedValues.password,
				};

				const body: IPatchBody[] = [updatedAlbumName, updatedAlbumPassword];

				const data = await AlbumService.updateAlbum(albumKey, projectKey, body);

				const updatedAlbumsUI = albumsUI.map((album) =>
					album.id === albumKey
						? { ...album, ...data, imagesCount: album.imagesCount }
						: album
				);

				setAlbumsUI(updatedAlbumsUI);
				hideUpdateAlbumModal();
			}

			if (!albumKey) {
				const body: ICreateAlbumBody = {
					projectKey,
					...trimmedValues,
				};

				const data = await AlbumService.createAlbum(body);

				const updatedAlbumsUI: IAlbumUI[] = [
					...albumsUI.slice(0),
					{
						...data,
						firstSmallThumbnailUrl: '',
						secondSmallThumbnailUrl: '',
						thirdSmallThumbnailUrl: '',
					},
				];

				setAlbumsUI(updatedAlbumsUI);
				hideUpdateAlbumModal();
			}
		} catch (error) {
			handleErrors(error as IErrorResponse);
		}
	};

	return (
		<Formik
			onSubmit={onSubmit}
			initialValues={initialValues}
			validationSchema={updateAlbumScheme}
		>
			{({ values, errors, touched, handleChange, handleSubmit }) => (
				<div className="org-modal project-modal-group">
					{fields.map((field) => (
						<InputGrid
							id={field.id}
							key={field.id}
							className="row"
							label={field.label}
							value={values[field.id]}
							error={errors[field.id]}
							required={field.required}
							touched={touched[field.id]}
							handleChange={handleChange}
							placeholder={field.placeholder}
						/>
					))}
					<Button
						onClick={() => handleSubmit()}
						className="btn-primary org-modal-btn"
						value={albumKey ? 'Update' : 'Create'}
					/>
				</div>
			)}
		</Formik>
	);
};
