import { useLocation, useNavigate } from 'react-router-dom';
import { FC, useState, useEffect } from 'react';
import cn from 'classnames';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useDefineDevice } from 'hooks/useDefineDevice';

import { setIsOpen as setIsOpenSidebar } from 'store/sidebar';
import { isAssistantRoleSelector } from 'store/accounts';
import { breadcrumbsSelector } from 'store/breadcrumbs';

import { INavigation } from 'types/navigation';
import { ROUTES } from 'constants/ROUTES';

import { SubNavItem } from './SubNavItem';

export const SubNavItems: FC<INavigation> = ({
	path,
	title,
	subNavigationItems,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const isAssistantRole = useAppSelector(isAssistantRoleSelector);
	const [, subBreadcrumb] = useAppSelector(breadcrumbsSelector);

	const { pathname } = useLocation();
	const dispatch = useAppDispatch();
	const navigation = useNavigate();

	const { isDesktop } = useDefineDevice();

	const handleNavItemClick = () => {
		if (!path) {
			return setIsOpen(!isOpen);
		}

		navigation(path);

		if (isDesktop) return;

		dispatch(setIsOpenSidebar(false));
	};

	const handleSubNavItemClick = (subPath: string) => {
		navigation(subPath);

		if (isDesktop) return;

		dispatch(setIsOpenSidebar(false));
	};

	const SubNavigationList = subNavigationItems.map(
		({ title: subTitle, path: subPath }) => {
			const disabled = isAssistantRole && subPath === ROUTES.ACCOUNTING;

			return (
				<SubNavItem
					key={subTitle}
					subPath={subPath}
					subTitle={subTitle}
					disabled={disabled}
					breadcrumbPath={subBreadcrumb?.path}
					handleSubNavItemClick={handleSubNavItemClick}
				/>
			);
		}
	);

	useEffect(() => {
		const isSubBreadcrumbExist = subNavigationItems.some(
			(subNavItem) => subBreadcrumb?.path === subNavItem.path
		);

		if (!isSubBreadcrumbExist) {
			return setIsOpen(false);
		}

		setIsOpen(true);
	}, [subBreadcrumb, subNavigationItems]);

	return (
		<li
			className={cn('nav-item', {
				'is-open': isOpen,
				active: pathname === path,
			})}
			onClick={handleNavItemClick}
		>
			<span className="nav-item-title">{title}</span>
			<ul className="subnav">{SubNavigationList}</ul>
		</li>
	);
};
