import { utc } from 'moment';
import { FC } from 'react';

import { generalStatisticPriceKeys } from 'constants/general/statistic/generalStatisticPriceKeys';
import { statisticDateFormat } from 'constants/general/statistic/statisticDateFormat';
import { studioStatisticKeys } from 'constants/dashboard/studioStatisticKeys';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useDefineDevice } from 'hooks/useDefineDevice';

import { studioStatisticSelector } from 'store/dashboard';

import { useDashboardBreadcrumbs } from './hooks/useDashboardBreadcrumbs';
import { DateRangeSelect } from './components/DateRangeSelect';
import { LatestProjects } from './components/LatestProjects';
import { OrderWaiting } from './components/OrderWaiting';
import { PanelData } from '../../components/PanelData';
import { ComingSoon } from './components/ComingSoon';
import { DidYouKnow } from './components/DidYouKnow';
import { NewOrders } from './components/NewOrders';
import { CreateNew } from './components/CreateNew';
import { Schedule } from './components/Schedule';

export const Dashboard: FC = () => {
	const { timestamp, ...restStudioStatistic } = useAppSelector(
		studioStatisticSelector
	);

	useDashboardBreadcrumbs();

	const { isMobile } = useDefineDevice();

	const studioStatisticDate =
		timestamp && utc(timestamp).format(statisticDateFormat);

	return (
		<div className="dashboard">
			<PanelData
				statistic={restStudioStatistic}
				statisticKeys={studioStatisticKeys}
				statisticPriceKeys={generalStatisticPriceKeys()}
			>
				<div className="data-footer">
					<span className="data-date">as of {studioStatisticDate}</span>
					<DateRangeSelect />
				</div>
			</PanelData>
			<div className="dashboard-thumb">
				{!isMobile && <Schedule />}
				<ComingSoon />
				<OrderWaiting />
				<NewOrders />
				<LatestProjects />
				<CreateNew />
				<DidYouKnow />
			</div>
		</div>
	);
};
