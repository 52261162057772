import { useCallback, useEffect, useState } from 'react';
import { CellProps, Column } from 'react-table';
import { SingleValue } from 'react-select';

import { getUserRolesSelectOptions } from 'constants/account/selectOptions/getUserRolesSelectOptions';
import { userRolesAccess } from 'constants/account/userRolesAccess';

import { ConfirmDelete } from 'components/Modal/components/ConfirmDelete';
import { UpgradePlan } from 'components/Modal/components/UpgradePlan';
import { PriceDeleteBtn } from 'components/Controls/PriceDeleteBtn';
import { Agreement } from 'components/Modal/components/Agreement';
import { SelectComponent } from 'components/FormControls/Select';
import { ToggleSection } from 'components/ToggleSection';
import { Button } from 'components/FormControls/Button';
import { Loader } from 'components/Loader';
import { Table } from 'components/Table';
import { Modal } from 'components/Modal';

import { IPatchBody } from 'api/models/requests/general/patchBody';
import { UserRoles } from 'api/models/requests/account/userRoles';
import { IUser } from 'api/models/responses/account/user';
import AccountService from 'api/services/AccountService';

import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useToggleSection } from 'hooks/useToggleSection';
import { useModalControls } from 'hooks/useModalControls';

import { UserIcon } from 'pages/AccountUser/components/UserIcon';
import { getImageBlobUrl } from 'utils/images/getImageBlobUrl';
import { ISelectOption } from 'types/ui/select';
import {
	updateUser,
	getUsersAsync,
	usersSelector,
	deleteUserAsync,
	currentUserSelector,
	isUsersPendingSelector,
} from 'store/accounts';

import { CreateNewUser } from './components/CreateNewUser';

export const ManageTeam = () => {
	const [isSelectRolePending, setIsSelectRolePending] = useState(false);
	const [userFullName, setUserFullName] = useState<string | null>(null);
	const [inviteUserError, setInviteUserError] = useState('');
	const [userId, setUserId] = useState<string | null>(null);

	const isUsersPending = useAppSelector(isUsersPendingSelector);
	const currentUser = useAppSelector(currentUserSelector);
	const users = useAppSelector(usersSelector);

	const { isOpen, handleToggle } = useToggleSection(true);

	const dispatch = useAppDispatch();

	const {
		modalRef: createNewUserModalRef,
		showModal: showCreateNewUserModal,
		hideModal: hideCreateNewUserModal,
	} = useModalControls();

	const {
		modalRef: confirmDeleteUserModalRef,
		showModal: showConfirmDeleteUserModal,
		hideModal: hideConfirmDeleteUserModal,
	} = useModalControls();

	const {
		modalRef: rejectCreateUserModalRef,
		showModal: showRejectCreateUserModal,
		hideModal: hideRejectCreateUserModal,
	} = useModalControls();

	const {
		modalRef: upgradePlanModalRef,
		showModal: showUpgradePlanModal,
		hideModal: hideUpgradePlanModal,
	} = useModalControls();

	const handleAgreementRejectCreateUser = () => {
		hideRejectCreateUserModal();
		showUpgradePlanModal();
	};

	const updateUserAsync = async (id: string, role: UserRoles) => {
		setIsSelectRolePending(true);
		setUserId(id);

		try {
			const updatedRoleField: IPatchBody = {
				value: role,
				path: 'role',
				op: 'replace',
			};

			const body: IPatchBody[] = [updatedRoleField];

			const data = await AccountService.updateUser(id, body);

			dispatch(updateUser(data));
		} catch (error) {
			console.log(error);
		} finally {
			setIsSelectRolePending(false);
			setUserId(null);
		}
	};

	const deleteUser = useCallback(() => {
		if (!userId) return;

		void dispatch(deleteUserAsync(userId));

		setUserFullName(null);
		setUserId(null);

		hideConfirmDeleteUserModal();
	}, [userId]);

	useEffect(() => {
		void dispatch(getUsersAsync());
	}, []);

	const columns: Column<IUser>[] = [
		{
			accessor: 'photoUrl',
			Cell: ({ value }: CellProps<IUser, IUser['photoUrl']>) => {
				const url = getImageBlobUrl(value);

				return <img className="acc-user-image" src={url} alt="user-img" />;
			},
		},
		{
			id: 'fullName',
			Header: <div className="tr-left">Name</div>,
			Cell: ({ row }: CellProps<IUser>) => {
				const { firstName, lastName } = row.original;

				return (
					<div className="td-left">
						{firstName} {lastName}
					</div>
				);
			},
		},
		{
			Header: 'Role',
			accessor: 'role',
			Cell: ({ row }: CellProps<IUser>) => {
				const { id, role } = row.original;

				if (!currentUser) return null;

				const { id: currentUserId, role: currentUserRole } = currentUser;

				const selectOptions = getUserRolesSelectOptions(currentUserRole);

				const isPending = isSelectRolePending && userId === id;

				const userRoleAccess = userRolesAccess[currentUserRole];

				const disabled =
					!userRoleAccess.includes(role) || isPending || id === currentUserId;

				const handleChangeUserRole = (
					option: SingleValue<ISelectOption<UserRoles>>
				) => {
					if (!option) return;

					void updateUserAsync(id, option.value);
				};

				return (
					<SelectComponent
						value={role}
						disabled={disabled}
						readonly={disabled}
						selectOptions={selectOptions}
						onChange={handleChangeUserRole}
					/>
				);
			},
		},
		{
			accessor: 'email',
			Header: <div className="tr-left">Email</div>,
			Cell: ({ value }: CellProps<IUser, IUser['email']>) => (
				<div className="td-left acc-table-email">{value}</div>
			),
		},
		{
			id: 'delete',
			Cell: ({ row }: CellProps<IUser>) => {
				const { id, firstName, lastName } = row.original;

				if (currentUser?.id === id) return null;

				const handleClickDeleteBtn = () => {
					setUserId(id);
					setUserFullName(`${firstName} ${lastName}`);

					showConfirmDeleteUserModal();
				};

				return <PriceDeleteBtn handleClick={handleClickDeleteBtn} />;
			},
		},
	];

	const ManageTeamSectionHeader = (
		<UserIcon>
			<Button
				value="+ Add User"
				className="btn-primary"
				onClick={showCreateNewUserModal}
			/>
		</UserIcon>
	);

	const confirmUserDeleteSubtitle = userFullName
		? `Are you sure you would like to delete the user ${userFullName}?`
		: '';

	return (
		<>
			<ToggleSection
				isOpen={isOpen}
				title="Manage Team"
				handleToggle={handleToggle}
				className="acc-user-header"
				header={ManageTeamSectionHeader}
			>
				<div className="acc-settings">
					{isUsersPending ? (
						<Loader isFullHeight={false} />
					) : (
						<div className="order-table-container">
							<Table
								data={users}
								columns={columns}
								className="org-table org-table-image"
							/>
						</div>
					)}
				</div>
			</ToggleSection>
			<Modal ref={createNewUserModalRef} title="Create New User">
				<CreateNewUser
					setInviteUserError={setInviteUserError}
					hideCreateNewUserModal={hideCreateNewUserModal}
					showRejectCreateUserModal={showRejectCreateUserModal}
				/>
			</Modal>
			<Modal
				title="Delete User"
				ref={confirmDeleteUserModalRef}
				subtitle={confirmUserDeleteSubtitle}
			>
				<ConfirmDelete
					handlePermanentlyDelete={deleteUser}
					hideConfirmDeleteModal={hideConfirmDeleteUserModal}
				/>
			</Modal>
			<Modal
				title="Cannot invite user"
				subtitle={inviteUserError}
				ref={rejectCreateUserModalRef}
			>
				<Agreement handleAgreement={handleAgreementRejectCreateUser} />
			</Modal>
			<Modal
				ref={upgradePlanModalRef}
				title="Upgrade Your Plan"
				subtitle="This option is not available on your current plan. Upgrade your plan now to get access to this feature and more."
			>
				<UpgradePlan hideModal={hideUpgradePlanModal} />
			</Modal>
		</>
	);
};
