import { useNavigate } from 'react-router-dom';
import { FC, useEffect } from 'react';

import { Button } from 'components/FormControls/Button';
import { Modal } from 'components/Modal';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useModalControls } from 'hooks/useModalControls';

import { currentUserSelector, isAssistantRoleSelector } from 'store/accounts';
import { ROUTES } from 'constants/ROUTES';

import { useAccountingBreadcrumbs } from './hooks/useAccountingBreadcrumbs';
import { BillingInformation } from './components/BillingInformation';
import { BankInformation } from './components/BankInformation';
import { PlanInformation } from './components/PlanInformation';
import { WInformation } from './components/WInformation';
import { CloseAccount } from './components/CloseAccount';

export const Accounting: FC = () => {
	const isAssistantRole = useAppSelector(isAssistantRoleSelector);
	const currentUser = useAppSelector(currentUserSelector);

	const navigate = useNavigate();

	useAccountingBreadcrumbs();

	const {
		modalRef: closeAccountModalRef,
		showModal: showCloseAccountModal,
		hideModal: hideCloseAccountModal,
	} = useModalControls();

	useEffect(() => {
		if (!isAssistantRole) return;

		navigate(ROUTES.DASHBOARD);
	}, [isAssistantRole]);

	if (!currentUser) return null;

	return (
		<div className="order-container">
			<PlanInformation />
			<BillingInformation />
			<BankInformation />
			<WInformation />
			<Button
				value="Close Account"
				onClick={showCloseAccountModal}
				className="btn-secondary accing-footer-btn"
			/>
			<Modal
				title="You are about to close your imago account."
				subtitle="This will delete your images, order history, projects, price lists, marketing, etc. You will no longer have access to this information. This action cannot be undone."
				ref={closeAccountModalRef}
			>
				<CloseAccount hideCloseAccountModal={hideCloseAccountModal} />
			</Modal>
		</div>
	);
};
