import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { utc } from 'moment';

import { useOrganizationsBreadcrumbs } from 'pages/Organizations/hooks/useOrganizationsBreadcrumbs';
import { SuccessCreateProject } from 'pages/Projects/components/SuccessCreateProject';

import { generalStatisticPriceKeys } from 'constants/general/statistic/generalStatisticPriceKeys';
import { initialGeneralStatistic } from 'constants/general/statistic/initialGeneralStatistic';
import { generalStatisticKeys } from 'constants/general/statistic/generalStatisticKeys';
import { statisticDateFormat } from 'constants/general/statistic/statisticDateFormat';
import { ROUTES } from 'constants/ROUTES';

import { IGeneralStatistic } from 'api/models/responses/general/generalStatistic';
import { IProject } from 'api/models/responses/projects/projectDetails';
import OrganizationService from 'api/services/OrganizationService';
import ProjectsService from 'api/services/ProjectsService';

import { IBreadcrumbs } from 'store/breadcrumbs';
import {
	setCreatedOrganization,
	createdOrganizationSelector,
} from 'store/organizations';
import {
	createdProjectSelector,
	setShowSuccessCreateProject,
	showSuccessCreateProjectSelector,
} from 'store/projects';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useAppDispatch } from 'hooks/redux/useAppDispatch';
import { useModalControls } from 'hooks/useModalControls';
import { usePreviewUrl } from 'hooks/usePreviewUrl';

import { PanelData } from 'components/PanelData';
import { Modal } from 'components/Modal';

import { ProjectFormSectionContainer } from './components/ProjectFormSectionContainer';
import { ProjectJourneyContainer } from './components/ProjectJourneyContainer';
import { ProjectDetailsContainer } from './components/ProjectDetailsContainer';
import { useProjectTypeSubjects } from '../../hooks/useProjectTypeSubjects';
import { MarketingContainer } from './components/MarketingContainer';
import { TimelineContainer } from './components/TimelineContainer';
import { ProjectControls } from './components/ProjectControls';
import { ImagesContainer } from './components/ImagesContainer';

export interface IDetailsGeneralProps {
	isPending: boolean;
	projectDetails: IProject | null;
	setProjectDetails: (projectDetails: IProject) => void;
}

export const ProjectDetailsPage: FC = () => {
	const [projectDetails, setProjectDetails] = useState<IProject | null>(null);
	const [projectStatistic, setProjectStatistic] = useState<IGeneralStatistic>(
		initialGeneralStatistic
	);
	const [isUpdateProject, setIsUpdateProject] = useState(false);
	const [isUpdateTimeline, setIsUpdateTimeline] = useState(false);
	const [isPending, setIsPending] = useState(false);

	const createdOrganization = useAppSelector(createdOrganizationSelector);
	const createdProject = useAppSelector(createdProjectSelector);
	const showSuccessCreateProject = useAppSelector(
		showSuccessCreateProjectSelector
	);

	const dispatch = useAppDispatch();
	const { projectId } = useParams();
	const { pathname } = useLocation();

	const projectPreviewUrl = usePreviewUrl(projectDetails?.projectUrl);
	const { subject, subjects } = useProjectTypeSubjects(
		projectDetails?.projectType ?? null
	);

	const projectKeyParam = (projectId && +projectId) || 0;

	const organizationDetailsBreadcrumbs: IBreadcrumbs = {
		isActive: false,
		title: createdOrganization?.organizationName || '',
		path: projectDetails?.organizationKey
			? `${ROUTES.ORGANIZATIONS.ORGANIZATIONS}/${projectDetails.organizationKey}`
			: '',
	};

	const projectDetailsBreadcrumbs: IBreadcrumbs = {
		isActive: true,
		path: pathname,
		title: projectDetails?.projectName || '',
	};

	useOrganizationsBreadcrumbs(
		!!createdOrganization?.isActive,
		organizationDetailsBreadcrumbs,
		projectDetailsBreadcrumbs
	);

	const {
		modalRef: successCreateProjectModalRef,
		showModal: showSuccessCreateProjectModal,
		hideModal: hideSuccessCreateProjectModal,
	} = useModalControls();

	const getProjectDetails = useCallback(async () => {
		if (!projectKeyParam) return;

		if (
			createdProject &&
			projectKeyParam === createdProject.projectKey &&
			!isUpdateProject
		) {
			return setProjectDetails(createdProject);
		}

		if (projectDetails && !isUpdateProject) return;

		setIsPending(true);

		try {
			const data = await ProjectsService.getProjectDetails(projectKeyParam);

			setProjectDetails(data);
		} catch (error) {
			console.log(error);
		} finally {
			setIsPending(false);
			setIsUpdateProject(false);
		}
	}, [projectKeyParam, isUpdateProject, createdProject?.projectKey]);

	useEffect(() => {
		void getProjectDetails();
	}, [getProjectDetails]);

	const getProjectStatistic = useCallback(async () => {
		if (!projectKeyParam || !projectDetails?.estimatedNumberOfSubjects) return;

		try {
			const data = await ProjectsService.getProjectStatistic(projectKeyParam);

			setProjectStatistic(data);
		} catch (error) {
			console.log(error);
		}
	}, [projectKeyParam, projectDetails?.estimatedNumberOfSubjects]);

	useEffect(() => {
		void getProjectStatistic();
	}, [getProjectStatistic]);

	const getOrganizationDetails = useCallback(async () => {
		if (!projectDetails?.organizationKey) return;

		try {
			const data = await OrganizationService.getOrganizationDetails(
				projectDetails.organizationKey
			);

			if (data) {
				dispatch(setCreatedOrganization(data));
			}
		} catch (error) {
			console.log(error);
		}
	}, [projectDetails?.organizationKey]);

	useEffect(() => {
		void getOrganizationDetails();
	}, [getOrganizationDetails]);

	useEffect(() => {
		if (!showSuccessCreateProject) return;

		showSuccessCreateProjectModal();
		dispatch(setShowSuccessCreateProject(false));
	}, [showSuccessCreateProject]);

	const { timestamp, ...restProjectStatistic } = projectStatistic;

	const projectStatisticDate =
		timestamp && utc(timestamp).format(statisticDateFormat);

	return (
		<div className="preset-project-container">
			<PanelData
				statistic={restProjectStatistic}
				statisticKeys={generalStatisticKeys(subjects)}
				statisticPriceKeys={generalStatisticPriceKeys(subject)}
			>
				<span className="data-date">as of {projectStatisticDate}</span>
			</PanelData>
			<ProjectDetailsContainer
				isPending={isPending}
				projectDetails={projectDetails}
				setProjectDetails={setProjectDetails}
			/>
			<ProjectJourneyContainer
				isPending={isPending}
				projectDetails={projectDetails}
				setProjectDetails={setProjectDetails}
				setIsUpdateTimeline={setIsUpdateTimeline}
			/>
			<TimelineContainer
				projectKey={projectKeyParam}
				isUpdateTimeline={isUpdateTimeline}
				setIsUpdateProject={setIsUpdateProject}
				setIsUpdateTimeline={setIsUpdateTimeline}
			/>
			<MarketingContainer
				projectKey={projectKeyParam}
				journey={projectDetails?.journey}
				setProjectDetails={setProjectDetails}
				setIsUpdateTimeline={setIsUpdateTimeline}
				isMarketingEnabled={!!projectDetails?.isMarketingEnabled}
				hasAnySentBroadcast={!!projectDetails?.hasAnySentBroadcast}
				smsSpecialMessage={projectDetails?.smsSpecialMessage || ''}
				accessCodeMessage={projectDetails?.accessCodeMessage || ''}
				emailSpecialMessage={projectDetails?.emailSpecialMessage || ''}
			/>
			<ProjectFormSectionContainer projectKey={projectKeyParam} />
			<ImagesContainer
				projectKey={projectKeyParam}
				isPngUploadAllowed={projectDetails?.isPngUploadAllowed}
			/>
			<ProjectControls
				projectKey={projectDetails?.projectKey}
				projectName={projectDetails?.projectName}
			/>
			<Modal
				ref={successCreateProjectModalRef}
				title="Your project has been created!"
				subtitle="Congratulations! It’s time to start marketing to your prospects and generate sales. Promote your project by sharing the link below. "
			>
				<SuccessCreateProject
					url={projectPreviewUrl}
					hideSuccessCreateProjectModal={hideSuccessCreateProjectModal}
				/>
			</Modal>
		</div>
	);
};
