import { ChangeEvent, FC, useState } from 'react';
import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';

import { getProjectDateTypesSelectOptions } from 'constants/broadcasts/selectOptions/projectDateTypesSelectOptions';
import { saleConditionSelectOptions } from 'constants/projects/selectOptions/saleConditionSelectOptions';
import { specialDateHolidays } from 'constants/broadcasts/specialDateHolidays';
import { maxDaysNumber, minDaysNumber } from 'constants/broadcasts/validation';
import { maxTimeH, minTimeH } from 'constants/broadcasts/time';
import { TimeZonesUI } from 'constants/studio/timeZonesUI';
import {
	classicDatePickerConfig,
	getTimeDatePickerConfig,
} from 'constants/general/dates/datePickerConfigs';

import { SaleCondition } from 'api/models/requests/presets/createPresets';
import {
	IBroadcast,
	ProjectDateTypes,
} from 'api/models/responses/broadcasts/broadcast';

import { SelectComponent } from 'components/FormControls/Select';
import { InputGrid } from 'components/FormControls/InputGrid';
import { ToggleSection } from 'components/ToggleSection';

import { useAppSelector } from 'hooks/redux/useAppSelector';
import { useToggleSection } from 'hooks/useToggleSection';

import { ISelectOption } from 'types/ui/select';
import { studioSelector } from 'store/studio';

import { IBroadcastValues } from '../../..';

const onlyTimeDatePickerConfig = getTimeDatePickerConfig(minTimeH, maxTimeH);

interface IBroadcastSettingsProps
	extends Pick<
		IBroadcast,
		'isTemplate' | 'campaignTarget' | 'projectDateType'
	> {
	disabledEdit: boolean;
	parsedSpecialDate: Date | null;
}

export const BroadcastSettings: FC<IBroadcastSettingsProps> = ({
	isTemplate,
	disabledEdit,
	campaignTarget,
	projectDateType,
	parsedSpecialDate,
}) => {
	const [isInitialProjectDateType, setIsInitialProjectDateType] =
		useState(true);

	const { values, errors, touched, handleChange, setFieldValue } =
		useFormikContext<IBroadcastValues>();

	const studio = useAppSelector(studioSelector);

	const { isOpen, handleToggle } = useToggleSection(true);

	const isSpecialDateHoliday = specialDateHolidays.includes(projectDateType);

	const handleChangeDays = (e: ChangeEvent<HTMLInputElement>) => {
		const { id, value } = e.target;

		const valueNumber = +value;

		if (!value) {
			void setFieldValue(id, '');
			return;
		}

		if (isNaN(valueNumber)) return;

		if (valueNumber > maxDaysNumber) {
			void setFieldValue(id, maxDaysNumber);
			return;
		}

		if (valueNumber < minDaysNumber) {
			void setFieldValue(id, minDaysNumber);
			return;
		}

		void setFieldValue(id, valueNumber);
	};

	const handleChangeCondition = (
		option: SingleValue<ISelectOption<SaleCondition>>
	) => {
		if (!option) return;

		void setFieldValue('condition', option.value);
	};

	const handleChangeProjectDateType = (
		option: SingleValue<ISelectOption<ProjectDateTypes>>
	) => {
		if (!option) return;

		void setFieldValue('specialDate', null);

		const { value } = option;

		if (projectDateType === value) {
			setIsInitialProjectDateType(true);

			void setFieldValue('specialDate', parsedSpecialDate);
		} else {
			setIsInitialProjectDateType(false);
		}

		void setFieldValue('projectDateType', value);
	};

	const handleChangeTime = (date: Date) => {
		void setFieldValue('time', date);
	};

	const handleChangeSpecialDate = (date: Date) => {
		void setFieldValue('specialDate', date);
	};

	const projectDateTypesSelectOptions =
		getProjectDateTypesSelectOptions(campaignTarget);

	const isSpecialDateHolidayCondition =
		!isTemplate && isSpecialDateHoliday && isInitialProjectDateType;

	const showSpecialDate =
		isSpecialDateHolidayCondition ||
		values.projectDateType === ProjectDateTypes.SpecialDate;

	const disabledSpecialDate = disabledEdit || isSpecialDateHolidayCondition;

	const showSpecialDateError = !!(touched.specialDate && errors.specialDate);

	return (
		<div className="campaign-toggle-section">
			<ToggleSection
				isOpen={isOpen}
				title="Broadcast Settings"
				handleToggle={handleToggle}
				className="campaign-section-header"
			>
				<div className="campaign-section-wrapper campaign-section-fields">
					<label htmlFor="broadcastName" className="label">
						Broadcast Name
					</label>
					<InputGrid
						row
						required
						id="broadcastName"
						label="Broadcast Name"
						disabled={disabledEdit}
						handleChange={handleChange}
						error={errors.broadcastName}
						value={values.broadcastName}
						placeholder="Broadcast Name"
						touched={touched.broadcastName}
						className="autorow campaign-input"
					/>
					<label htmlFor="days" className="label">
						Trigger Broadcast
					</label>
					<div className="campaign-section-grid">
						<InputGrid
							row
							id="days"
							required={false}
							placeholder="Days"
							value={values.days}
							className="autorow"
							disabled={disabledEdit}
							label="Trigger Broadcast"
							handleChange={handleChangeDays}
						/>
						<span>Days</span>
						<SelectComponent
							disabled={disabledEdit}
							value={values.condition}
							onChange={handleChangeCondition}
							selectOptions={saleConditionSelectOptions}
						/>
						<SelectComponent
							disabled={disabledEdit}
							value={values.projectDateType}
							onChange={handleChangeProjectDateType}
							selectOptions={projectDateTypesSelectOptions}
						/>
					</div>
					{showSpecialDate && (
						<div className="campaign-section-form">
							<label htmlFor="specialDate" className="label">
								Special Date
							</label>
							<DatePicker
								{...classicDatePickerConfig}
								id="specialDate"
								selected={values.specialDate}
								disabled={disabledSpecialDate}
								onChange={handleChangeSpecialDate}
							/>
							{/* TODO: add styles for error */}
							{showSpecialDateError && <span>{errors.specialDate}</span>}
						</div>
					)}
					<div className="campaign-section-form">
						<label htmlFor="sendAfter" className="label">
							Send After
						</label>
						<div>
							<DatePicker
								id="sendAfter"
								{...onlyTimeDatePickerConfig}
								selected={values.time}
								disabled={disabledEdit}
								onChange={handleChangeTime}
							/>
						</div>
						<span>{studio && TimeZonesUI[studio.timeZone]}</span>
					</div>
				</div>
			</ToggleSection>
		</div>
	);
};
