import { generalStatisticKeys } from 'constants/general/statistic/generalStatisticKeys';
import { IStatisticRenderHelper } from 'types/statistic/statisticRenderHelper';

export const organizationStatisticKeys: IStatisticRenderHelper[] = [
	{
		key: 'projects',
		title: 'Active Projects',
	},
	...generalStatisticKeys(),
];
