import { IStatisticRenderHelper } from 'types/statistic/statisticRenderHelper';

export const generalStatisticPriceKeys = (
	subject?: string
): IStatisticRenderHelper[] => [
	{
		key: 'revenue',
		title: 'Revenue',
	},
	{
		key: 'averageOrder',
		title: 'Avg. Order',
	},
	{
		key: 'revenuePerPerson',
		title: `Rev. per ${subject || 'Person'}`,
	},
];
